const LOGIN = '/LoginPage'
const EXAMINATION = '/examination'
const EXAM_CREATOR = `${EXAMINATION}/exam-creator`
const EXAM_PUBLISHER = `${EXAMINATION}/exam-publisher`
const MANAGE = `/manage`
const MANAGELEVEL1 = `${MANAGE}/level1-subexam`
const MANAGELEVEL2 = `${MANAGE}/level2-subexam`
const MANAGELEVEL3 = `${MANAGE}/level3-subexam`
const APPROVE = `/approve`
const APPORVE_RECORD = `${APPROVE}/record`
const QUESTION_STORAGE = `/questions-storage`
const EXAM_REPORT = `/exam-report`

export {
  LOGIN,
  EXAM_CREATOR,
  EXAM_PUBLISHER,
  QUESTION_STORAGE,
  APPROVE,
  APPORVE_RECORD,
  MANAGELEVEL2,
  MANAGELEVEL1,
  MANAGELEVEL3,
  EXAM_REPORT
}

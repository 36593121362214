import { Form, InputNumber, Space, Typography } from 'antd'
const { Text } = Typography

/**
 * @param {object} props
 * @param {boolean} disabled
 */
export default function NumberOfSets({ disabled }) {
  return (
    <Space style={{ marginBottom: 20 }}>
      <Text>จำนวนชุดที่ต้องการ :</Text>
      <Form.Item
        name="numberOfSets"
        rules={[
          { pattern: /^[1-9]+/, message: 'กรอกจำนวนให้ถูกต้อง' },
          { required: true, message: 'กรอกจำนวนให้ถูกต้อง' }
        ]}
        style={{ marginBottom: 0 }}>
        <InputNumber size="small" disabled={disabled} />
      </Form.Item>
      <Text>ชุด</Text>
    </Space>
  )
}

import { React, useState } from 'react'
import { Button, Col, Form, Input, Row, Select, Space, Switch } from 'antd'
import { Content } from 'antd/es/layout/layout'
import env from '../lib/environments'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import { createlistExamLevel1, updateLevel1 } from '../data/level1.data'
import { listMainExam } from '../data/mainexam.data'
import { listSubExam } from '../data/subexam.data'
import useExam from '../lib/useExam'
import TextArea from 'antd/es/input/TextArea'

const layout = {
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 16
  }
}

export default function Level1_Form({
  view = false,
  record,
  handleCancel = () => {},
  mutateData = () => {}
}) {
  const [confirm, setConfirm] = useState(false)
  const [form] = useForm()

  const handleCreate = async (values = {}) => {
    try {
      await createlistExamLevel1(values)
      mutateData()
      handleCancel(false)
    } catch (error) {
      alert('Error : ชื่อ Part มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }

  const handleUpdate = async (values) => {
    try {
      if (
        values.subExamCategoryId !== record.subExamCategoryId ||
        values.active !== record.active ||
        values.description !== record.description ||
        values.subExamCategoryLevel1Name !== record.subExamCategoryLevel1Name
      ) {
        await updateLevel1(record.subExamCategoryLevel1Id, values)
        mutateData()
      }

      handleCancel()
    } catch (error) {
      console.error('Error updating data:', error)
      alert('Error : ชื่อ Part มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }

  const { MainExamCategorySelector, SubExamCategorySelector } = useExam({
    mainExamCateId: record?.mainExamCategoryId
  })

  const onFinish = () => {
    const values = form.getFieldsValue()
    if (!record) {
      handleCreate({
        subExamCategoryId: values.subExamCategoryId,
        subExamCategoryLevel1Name: values.subExamCategoryLevel1Name,
        description: values.description,
        active: Boolean(values.active)
      })
    } else {
      console.log('เช็คค')
      handleUpdate({
        subExamCategoryId: values.subExamCategoryId,
        subExamCategoryLevel1Name: values.subExamCategoryLevel1Name,
        description: values.description,
        active: Boolean(values.active)
      })
    }
  }

  return (
    <Form
      form={form}
      {...layout}
      onFinish={() => setConfirm(true)}
      initialValues={{
        mainExamCategoryId: record?.mainExamCategoryId,
        subExamCategoryId: record?.subExamCategoryId,
        subExamCategoryLevel1Name: record?.subExamCategoryLevel1Name,
        description: record?.description,
        active: record?.active
      }}>
      <Row>
        <Col span={24}>
          <MainExamCategorySelector disabled={view} />
          <SubExamCategorySelector />
        </Col>
        <Col span={24}>
          <Form.Item
            name="subExamCategoryLevel1Name"
            label="ชื่อ Parts:"
            requiredMark="optional"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกชื่อ Parts!'
              }
            ]}>
            <Input className="inputtxt2" placeholder="กรอก Parts" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="description" label="Description:">
            <TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="active" label="สถานะเปิด/ปิดใช้งาน" valuePropName="checked">
            <Switch onChange={handleChange} checkedChildren="on" unCheckedChildren="off"></Switch>
          </Form.Item>
        </Col>
      </Row>
      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        <Button shape="round" className="btnBGreen" onClick={handleCancel}>
          ยกเลิก
        </Button>
        <Button className="btnGreen" shape="round" type="primary" htmlType="submit">
          บันทึกข้อมูล
        </Button>
      </Space>
      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
        }}
        fallback={() => {
          setConfirm(false)
        }}
      />
    </Form>
  )
}

const handleChange = (value) => {
  console.log(`selected ${value}`)
}

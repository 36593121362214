import { Table, Typography } from 'antd'

const { Title } = Typography

/**
 * @param {Object} props
 * @param {import('./types').FieldPropsType} props.field
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {Boolean} props.disabled
 */
export default function ExamsList({ utils, disabled }) {
  const exams = utils.form.getFieldValue(utils.examUtils.fullPathOfExams)

  return (
    <>
      <Title level={5}>เลือกข้อสอบ</Title>

      <Table
        scroll={{ x: 'max-content' }}
        columns={columnsType}
        dataSource={utils.examUtils.getExams()}
        size="small"
        rowSelection={{
          ...(exams && { selectedRowKeys: exams.map((exam) => exam.id) }),
          ...(disabled && { getCheckboxProps: () => ({ disabled }) }),
          type: 'checkbox',
          onChange: (_, selectedRows) => {
            utils.form.setFieldValue([...utils.pathOfItem, 'exam'], selectedRows)
          }
        }}
      />
    </>
  )
}

const columnsType = [
  {
    title: 'รหัส',
    dataIndex: 'key',
    align: 'center'
  },
  {
    title: 'โจทย์',
    dataIndex: 'detailsExam',
    align: 'center',
    width: '400px',
    render: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />
  },
  {
    title: 'ระดับ',
    dataIndex: 'examLevelName',
    align: 'center'
  },
  {
    title: 'สร้างโดย',
    dataIndex: 'createdByUserName',
    align: 'center'
  }
]

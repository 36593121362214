import { Content } from 'antd/es/layout/layout'
import LayoutPage from '../../components/Layout'
import { Col, Typography } from 'antd'
import ExamPublisherForm from './Form'
import { useNavigate, useParams } from 'react-router-dom'
import { updateExaminer } from '../../data/examiner.data'
import portals from '../../lib/portals'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchRecord, resetRecord, selectRecord } from '../../features/examiners/examinersSlice'
import _ from 'lodash'

const { Text } = Typography

export default function ExamPublisherUpdate() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const record = useSelector(selectRecord)

  useEffect(() => {
    dispatch(fetchRecord(id))

    return () => {
      dispatch(resetRecord())
    }
  }, [id])

  const onFinish = async (values) => {
    try {
      await updateExaminer(record?.id, {
        ...values,
        active: true,
        ...(!values.isRandom && {
          numberOfSets: 1,
          section: values.section.map((st) => ({
            ...st,
            numberOfExamsRequired: st.exam.length,
            examExaminerLevel: _(st.exam)
              .groupBy((exam) => (exam.examLevelId ? exam.examLevelId : exam.examLevel.id))
              .reduce(
                (acc, val, key) => [
                  ...acc,
                  { examLevelId: parseInt(key), numberRequiredLevel: val.length }
                ],
                []
              )
          }))
        })
      })
      navigate(portals.examPublisher.list())
    } catch (error) {
      if (error.status === 409 || error.status === 400) {
        alert('ข้อสอบไม่เพียงพอต่อการสุ่ม กรุณากลับไปเพิ่มใหม่คะ')
      } else {
        alert(error.statusText)
      }
    }
  }

  return (
    <LayoutPage>
      <Content>
        <Col span={24}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px',
              marginBottom: 20,
              display: 'block'
            }}>
            แก้ไขชุดข้อสอบ (ผู้ออกข้อสอบ)
          </Text>
        </Col>
        <ExamPublisherForm onFinish={onFinish} />
      </Content>
    </LayoutPage>
  )
}

import { React, useEffect, useMemo, useState } from 'react'
import { Row, Col, Typography, Button, Table, Tabs } from 'antd'
import { Link } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons'
import LayoutPage from '../../components/Layout'
import VruModal from '../../components/Modal'
import useDataTable from '../../lib/useTable'
import MainExamForm from '../../components/MainExamForm'
import { ExaminerPreview, listExaminer } from '../../data/examiner.data'
import { createExamColumnsType } from '../../lib/columns-types/exam.columns'
import _ from 'lodash'
import { EXAM_CREATOR } from '../../constants/paths'
import { useAuth } from '../../AuthorizationProvider'
import { v4 as uuid } from 'uuid'
import { createDraftExamColumnsType } from './draftExamColumnsType'
import dayjs from 'dayjs'

const { Text } = Typography

const ExaminationsList = () => {
  const { usePageAuth, currentUserCan } = useAuth()
  usePageAuth(['list_exam', 'get_exam'])
  const [searchText] = useState('')
  var {
    data: exams,
    tableParams,
    loading,
    mutateData,
    handleTableChange
  } = useDataTable(listExaminer, {
    limit: 0,
    sort: 'updatedAt',
    order: 'desc'
  })
  const [examsDraft, setExamsDraft] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getDraftsExam = () => {
    let i = localStorage.length
    const drafts = []

    while (i--) {
      const key = localStorage.key(i)
      if (key.includes('draft-exam'))
        drafts.push(JSON.parse(localStorage.getItem(localStorage.key(i))))
    }

    setExamsDraft(
      drafts
        .sort((a, b) => dayjs(b.updatedAt).unix() - dayjs(a.updatedAt).unix())
        ?.map((exam, idx) => ({ ...exam, seq: idx + 1 }))
    )
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => getDraftsExam(), [])

  return (
    <>
      <LayoutPage>
        <Row justify="space-between" gutter={[0, 30]}>
          <Col span={12}>
            <Text
              style={{
                fontFamily: 'KanitM',
                color: '#0B6252',
                fontSize: '25px'
              }}>
              จัดการชุดข้อสอบ (ผู้จัดทำข้อสอบ)
            </Text>
          </Col>
          <Col>
            {currentUserCan(['create_exam']) && (
              <Link to={`${EXAM_CREATOR}/create`}>
                <Button className="btnGreen" shape="round" icon={<PlusCircleOutlined />}>
                  ชุดข้อสอบ
                </Button>
              </Link>
            )}
          </Col>
          <Col span={24}>
            <Table
              scroll={{ x: 'max-content' }}
              columns={createExamColumnsType(searchText, mutateData)}
              rowKey={(record) => record.seq}
              dataSource={exams}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
              size="small"
            />

            {/* ปิดไว้ก่อน */}
            {/* <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: '1',
                  label: 'เอกสาร',
                  children: (
                    <Table
                      scroll={{ x: 'max-content' }}
                      columns={createExamColumnsType(searchText, mutateData)}
                      rowKey={(record) => record.seq}
                      dataSource={exams}
                      pagination={tableParams.pagination}
                      loading={loading}
                      onChange={handleTableChange}
                      size="small"
                    />
                  )
                },
                {
                  key: '2',
                  label: 'ฉบับร่าง',
                  children: (
                    <Table
                      dataSource={examsDraft}
                      rowKey={(record) => record.draftId}
                      columns={createDraftExamColumnsType(getDraftsExam, currentUserCan)}
                      scroll={{ x: 'max-content' }}
                    />
                  )
                }
              ]}
            /> */}
          </Col>
        </Row>
      </LayoutPage>
      <VruModal title="เพิ่มข้อมูล" open={isModalOpen}>
        <MainExamForm mutateData={mutateData} handleCancel={handleCancel} />
      </VruModal>
    </>
  )
}

export default ExaminationsList

import { Checkbox, Col, Form, Row, Switch, Typography } from 'antd'
import { styles } from './styles'
import { useContext } from 'react'
import { PermissionContext } from './Index'
const { Text } = Typography

export default function FunctionsList({ disabled }) {
  const form = Form.useFormInstance()
  const { permissions } = useContext(PermissionContext)

  return (
    <div style={styles.functionsList}>
      {Object.entries(permissions).map(([key, perm]) => (
        <Form.Item
          key={key}
          shouldUpdate={(prev, cur) =>
            JSON.stringify(prev.resources[key]) !== JSON.stringify(cur.resources[key])
          }>
          {() => {
            const permName = ['resources', key, 'permissions']
            const activeName = ['resources', key, 'active']

            const resources = form.getFieldValue(permName)
            const checked = form.getFieldValue(activeName)

            const plainOptions = perm.values.map((value) => value.EN)
            const indeterminate = resources?.length > 0 && resources?.length < plainOptions.length
            const checkAll = plainOptions.length === resources?.length

            const onCheckAllChange = (e) => {
              form.setFieldValue(permName, e.target.checked ? plainOptions : [])
            }

            return (
              <Row key={`Function:${key}`} gutter={15} style={styles.permission}>
                <Col style={{ flex: '0 0 100px' }}>Permission :</Col>
                <Col
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    gap: 10,
                    flexShrink: 5
                  }}>
                  <Text style={styles.label}>Functions : </Text>
                  <Text>{perm.name}</Text>

                  <Text style={styles.label}>Action : </Text>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    disabled={!checked || disabled}>
                    ทั้งหมด
                  </Checkbox>

                  <Text style={styles.label}>Action : </Text>
                  <div>
                    <Form.Item name={permName} initialValue={[]} noStyle>
                      <Checkbox.Group
                        options={perm.values.map((value) => ({
                          label: value.TH,
                          value: value.EN
                        }))}
                        disabled={!checked || disabled}
                      />
                    </Form.Item>
                  </div>

                  <Text style={styles.label}>Action : </Text>
                  <div>
                    <Form.Item
                      name={activeName}
                      valuePropName="checked"
                      initialValue={false}
                      noStyle>
                      <Switch disabled={disabled} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ))}
    </div>
  )
}

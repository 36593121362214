import { Form, Select } from 'antd'

export default function SubExamLevel2Selector({
  examLevel2 = [],
  view = false,
  handleSubExamLevel2CatChange = () => {},
  ...props
}) {
  const form = Form.useFormInstance()

  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const subExamCategoryLevel1Id = form.getFieldValue('subExamCategoryLevel1Id')
        return (
          <Form.Item
            name="subExamCategoryLevel2Id"
            rules={[
              {
                required: true,
                message: 'กรุณากรอก Sections!'
              }
            ]}
            label="Sections :">
            <Select
              onChange={(id) => {
                handleSubExamLevel2CatChange(id)
              }}
              placeholder="เลือก Sections"
              options={examLevel2?.map((sexam) => ({
                label: sexam.subExamCategoryLevel2Name,
                value: sexam.subExamCategoryLevel2Id
              }))}
              disabled={!subExamCategoryLevel1Id || view}
              {...props}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

import jsPDF from 'jspdf'

export default function exportPDF(wrapper) {
  const examReport = wrapper.querySelector('canvas')
  const clientHeight = examReport.height
  const clientWidth = examReport.width
  const canvas = document.createElement('canvas')
  canvas.width = clientWidth
  canvas.height = clientHeight

  const context = canvas.getContext('2d')
  context.drawImage(examReport, 0, 0, canvas.width, canvas.height)

  var pdf = new jsPDF('landscape', 'pt', [clientWidth, clientHeight])
  pdf.addImage(canvas, 'PNG', 0, 0)
  pdf.save('report.pdf')
}

import { examinerApi } from '../lib/api'
import { fetchJsonWithToken } from '../lib/fethcer'
import _ from 'lodash'
import qs from 'qs'

export const listExaminer = (query = {}) =>
  fetchJsonWithToken(`${examinerApi.list()}?${qs.stringify(query)}`)

export const createExaminer = (values) => {
  if (typeof values.usersId === 'string') {
    values.usersId = [values.usersId]
  }
  if (typeof values.numberOfExamsRequired === 'string') {
    values.numberOfExamsRequired = Number(values.numberOfExamsRequired)
  }
  if (typeof values.numberOfSets === 'string') {
    values.numberOfSets = Number(values.numberOfSets)
  }
  return fetchJsonWithToken(examinerApi.base, {
    method: 'POST',
    body: JSON.stringify(values)
  })
}

export const updateExaminer = (id, values) =>
  fetchJsonWithToken(`${examinerApi.base}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })

export const getExaminer = (id) =>
  fetchJsonWithToken(`${examinerApi.base}/${id}`, {
    method: 'GET'
  })

export const getExaminerById = (id) =>
  fetchJsonWithToken(`${examinerApi.base}/${id}`, {
    method: 'GET'
  })

export const deleteExaminer = (id) =>
  fetchJsonWithToken(examinerApi.delete(id), {
    method: 'DELETE'
  })

/**
 * @returns {Promise<import('../types/response.types').TResponse<any>>}
 */
export const selectExaminersPreview = (query) =>
  fetchJsonWithToken(`${examinerApi.preview()}?${qs.stringify(query)}`)

export const ExaminerApprove = (where) => {
  let query = ''
  if (typeof where['storeExam'] !== 'undefined') {
    query = 'where[storeExam]=' + where['storeExam']
  }

  for (const key in where) {
    if (!query) {
      query += '&'
    }
    query = `where[${key}]=${where[key]}`
  }

  return fetchJsonWithToken(`${examinerApi.approveView()}?${query}`, {
    method: 'GET'
  })
}

export const getExaminerApprove = (query) =>
  fetchJsonWithToken(`${examinerApi.approveView()}?${qs.stringify(query)}`, {
    method: 'GET'
  })

export const ExaminerApproveID = (id, values) =>
  fetchJsonWithToken(examinerApi.approveById(id), {
    method: 'PUT',
    body: JSON.stringify(values)
  })
export const approveHistory = (query) =>
  fetchJsonWithToken(`${examinerApi.approve()}?${qs.stringify(query)}`, {
    method: 'GET'
  })

export const ExaminerStore = (id) =>
  fetchJsonWithToken(examinerApi.storeExamById(id), {
    method: 'PUT'
  })

export const ExaminerView = (query) =>
  fetchJsonWithToken(`${examinerApi.view()}?${qs.stringify(query)}`, {
    method: 'GET'
  })

export const deleteExamStorage = (id) =>
  fetchJsonWithToken(examinerApi.storeExamById(id), {
    method: 'DELETE'
  })

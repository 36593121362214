import { useEffect, useState } from 'react'
import _ from 'lodash'

/**
 * @typedef {Object} TableParams
 * @property {{
 *  current: Number,
 *  pageSize: Number
 * }} pagination
 */

/**
 * @param {() => Promise<import('../types/response').Response<any>>} request
 * @param {TableParams} initialParams
 * @param {Boolean} immediately
 * @return {{
 *  dataSource: any[],
 *  tableParams: TableParams,
 *  loading: boolean,
 *  setTableParams: Function,
 *  handleTableChange: Function,
 *  refetch: Function
 * }}
 */
export default function useDataTable(request, initialParams = {}) {
  const defaultParams = {
    pagination: {
      current: 1,
      pageSize: 10
    }
  }
  const [dataSource, setDataSource] = useState()
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState(Object.assign(defaultParams, initialParams))

  useEffect(() => {
    getData()
  }, [JSON.stringify(tableParams)])

  const getData = () => {
    setLoading(true)

    request(convertTableParamsToQuery(tableParams))
      .then(({ data }) => {
        setDataSource(
          data.items.map((item, key) => ({
            ...item,
            seq:
              data.page === '1'
                ? key + 1
                : parseInt(data.limit) * (parseInt(data.page) - 1) + (key + 1)
          }))
        )

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            pageSize: parseInt(data.limit),
            total: data.totalItems
          }
        })
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters: { ...(tableParams?.filters && tableParams.filters), ...filters },
      ...sorter
    })

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([])
    }
  }

  const handleOuterFiltersChange = (query) => {
    const compactQuery = _.omitBy(query, _.isUndefined)
    setTableParams((prev) => ({
      ...prev,
      filters: compactQuery
    }))
  }

  return {
    dataSource,
    loading,
    tableParams,
    setTableParams,
    handleTableChange,
    handleOuterFiltersChange,
    refetch: getData
  }
}

/**
 * @param {Object} tableParams
 */
const convertTableParamsToQuery = (tableParams) => {
  const newParams = _.reduce(
    tableParams,
    (acc, val, key) => {
      if (key === 'pagination') {
        acc = {
          ...acc,
          page: val.current,
          limit: val.pageSize,
          ...(val.total && { total: val.total })
        }
      } else if (key === 'filters') {
        acc = { ...acc, ...val }
      } else if (key === 'field') {
        if (tableParams?.order) acc = { ...acc, sort: val }
      } else if (key === 'order') {
        if (tableParams?.order) acc = { ...acc, order: val.slice(0, -3) }
      }

      return acc
    },
    {}
  )

  return newParams
}

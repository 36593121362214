import { Form, Select } from 'antd'

export default function SubExamLevel3Selector({
  examLevel2,
  examLevel3 = [],
  handleSubExamLevel3CatChange = () => {},
  view = false,
  ...props
}) {
  const form = Form.useFormInstance()

  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const subExamCategoryLevel2Id = form.getFieldValue('subExamCategoryLevel2Id')
        return (
          <Form.Item name="subExamCategoryLevel3Id" label="เลือก Sub Sections:">
            <Select
              placeholder="เลือก Sub Sections"
              options={examLevel3?.map((examl) => ({
                label: examl.subExamCategoryLevel3Name,
                value: examl.subExamCategoryLevel3Id
              }))}
              disabled={!subExamCategoryLevel2Id || view}
              {...props}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

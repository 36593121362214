import { Form, Select } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

export default function MainExamCategorySelector({
  mainCategory = [],
  handleMainExamCatChange = () => {},
  noRules,
  view,
  disabled,
  style = {}
}) {
  const form = useFormInstance()

  return (
    <Form.Item
      name="mainExamCategoryId"
      label="เลือกชื่อหมวดหมู่ข้อสอบหลัก :"
      style={style}
      {...(!noRules && {
        rules: [
          {
            required: true,
            message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบหลัก!'
          }
        ]
      })}>
      <Select
        onChange={(id) => {
          handleMainExamCatChange(id)
          form.resetFields([
            'subExamCategoryId',
            'subExamCategoryLevel1Id',
            'subExamCategoryLevel2Id',
            'subExamCategoryLevel3Id',
            'examType'
          ])
        }}
        placeholder="เลือกหมวดหมู่หลัก"
        disabled={view || disabled}
        options={mainCategory?.map((exam) => ({
          label: exam.mainExamCategoryName,
          value: exam.id
        }))}
      />
    </Form.Item>
  )
}

export const styles = {
  content: {
    backgroundColor: '#F4F4F4',
    with: '100%',
    alignItems: 'center',
    padding: '0 3%',
    height: '200vh'
  },
  functionsList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    padding: '0 7px'
  },
  permission: {
    border: '1px silver solid',
    borderRadius: 10,
    padding: 10,
    flexWrap: 'nowrap'
  },
  label: {
    textAlign: 'right'
  },
  collapsedFunctionsList: {
    marginTop: 20
  }
}

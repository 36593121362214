import withHook from '../withHook'
import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { DeleteFilled, EditFilled } from '@ant-design/icons'

import qs from 'qs'
import { EXAM_CREATOR } from '../../constants/paths'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
// import getColumnSearchProps from './columnSearch'
import { useAuth } from '../../AuthorizationProvider'
dayjs.extend(localizedFormat)

export const createExamColumnsType = (mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    responsive: ['sm'],
    align: 'center'
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'mainExamCategoryName',
    align: 'left',
    filterMode: 'menu',
    filterSearch: true,
    // ...getColumnSearchProps('mainExamCategoryName'),
    render: (mainExamCategoryName) => mainExamCategoryName
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบย่อย',
    dataIndex: 'subExamCategoryName',
    align: 'left',
    // ...getColumnSearchProps('subExamCategoryName'),
    render: (name) => name
  },

  {
    title: 'ชื่อ Parts',
    dataIndex: 'subExamCategoryLevel1Name',
    align: 'left',
    // ...getColumnSearchProps('subExamCategoryLevel1Name'),
    render: (subExamCategoryLevel1Name) => subExamCategoryLevel1Name
  },

  {
    title: 'ชื่อ Sections',
    dataIndex: 'subExamCategoryLevel2Name',
    align: 'left',
    // ...getColumnSearchProps('subExamCategoryLevel2Name'),
    render: (subExamCategoryLevel2Name) => subExamCategoryLevel2Name
  },

  {
    title: 'ชื่อ Sub Sections',
    dataIndex: 'subExamCategoryLevel3Name',
    align: 'left',
    // ...getColumnSearchProps('subExamCategoryLevel3Name'),
    render: (name) => name
  },

  {
    title: 'ประเภทชุดข้อสอบ',
    responsive: ['sm'],
    dataIndex: 'examTypes',
    className: 'tdWithChildren',
    // ...getColumnSearchProps('examTypes'),
    render: (examTypes) =>
      examTypes?.map((values, key) => (
        <p key={key} style={{ textAlign: 'left' }}>
          {values.examTypeName}
        </p>
      ))
  },

  {
    title: 'จำนวนข้อสอบ',
    responsive: ['sm'],
    align: 'center',
    dataIndex: 'examTypes',
    render: (examTypes) =>
      examTypes?.map((values, key) => <p key={key}>{values.numberOfExamsTotal}</p>)
  },

  {
    title: 'แก้ไขล่าสุด',
    dataIndex: 'examTypes',
    responsive: ['sm'],
    align: 'center',
    render: (examTypes) =>
      examTypes?.map((values, key) => (
        <p key={key}>{dayjs(values.updatedAt).locale('th').format('DD MMMM BBBB LT น.')}</p>
      ))
  },
  {
    title: 'จัดการ',
    align: 'center',
    dataIndex: 'examTypes',
    className: 'tdWithChildren',
    render: (examTypes, record) =>
      withHook(() => {
        const { currentUserCan } = useAuth()
        return examTypes?.map((type, key) => (
          <Row key={key} gutter={[10, 0]} justify="center">
            <Col>
              <Button
                disabled={!currentUserCan(['update_exam'])}
                href={`${EXAM_CREATOR}/update?${qs.stringify({
                  mainExamCategoryId: record.mainExamCategoryId,
                  subExamCategoryId: record.subExamCategoryId,
                  subExamCategoryLevel1Id: record.subExamCategoryLevel1Id,
                  ...(record?.subExamCategoryLevel2Id && {
                    subExamCategoryLevel2Id: record?.subExamCategoryLevel2Id
                  }),
                  ...(record?.subExamCategoryLevel3Id && {
                    subExamCategoryLevel3Id: record?.subExamCategoryLevel3Id
                  }),
                  examType: type.examType
                })}`}
                className="btnedit"
                icon={<EditFilled />}
              />
            </Col>{' '}
          </Row>
        ))
      })
  },

  {
    align: 'center',
    dataIndex: 'examTypes',
    className: 'tdWithChildren',
    render: (examTypes, record) =>
      withHook(() => {
        const { currentUserCan } = useAuth()
        return examTypes?.map((type, key) => (
          <Row key={key} justify="center">
            <Button
              disabled={!currentUserCan(['create_exam'])}
              className="btnchange"
              shape="round"
              href={`${EXAM_CREATOR}/add?${qs.stringify({
                mainExamCategoryId: record.mainExamCategoryId,
                subExamCategoryId: record.subExamCategoryId,
                subExamCategoryLevel1Id: record.subExamCategoryLevel1Id,
                ...(record?.subExamCategoryLevel2Id && {
                  subExamCategoryLevel2Id: record?.subExamCategoryLevel2Id
                }),
                ...(record?.subExamCategoryLevel3Id && {
                  subExamCategoryLevel3Id: record?.subExamCategoryLevel3Id
                }),
                examType: type.examType
              })}`}>
              เพิ่มข้อสอบ
            </Button>
          </Row>
        ))
      })
  },

  {
    dataIndex: 'examTypes',
    className: 'tdWithChildren',
    align: 'center',
    render: (examTypes, record) =>
      withHook(() => {
        const { currentUserCan } = useAuth()
        return examTypes?.map((type, key) => (
          <Row key={key} justify="center">
            <Button
              disabled={!currentUserCan(['get_exam'])}
              className="btnGreen"
              shape="round"
              href={`${EXAM_CREATOR}/view?${qs.stringify({
                mainExamCategoryId: record.mainExamCategoryId,
                subExamCategoryId: record.subExamCategoryId,
                subExamCategoryLevel1Id: record.subExamCategoryLevel1Id,
                ...(record?.subExamCategoryLevel2Id && {
                  subExamCategoryLevel2Id: record?.subExamCategoryLevel2Id
                }),
                ...(record?.subExamCategoryLevel3Id && {
                  subExamCategoryLevel3Id: record?.subExamCategoryLevel3Id
                }),
                examType: type.examType
              })}`}>
              ดูข้อสอบ
            </Button>
          </Row>
        ))
      })
  }
]

import { React, useState, useEffect } from 'react'
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import {
  createUser,
  updateUser,
  listRoles,
  checkEmailUniqueness,
  checkIdCardUniqueness,
  listPrefix,
  updatePassUser
} from '../data/user.data'

export default function UserForm({
  view = false,
  record,
  handleCancel = () => {},
  mutateData = () => {}
}) {
  const [confirm, setConfirm] = useState(false)
  const [roles, setRoles] = useState([])
  const [form] = useForm()
  const [idCardNumber, setIdCardNumber] = useState(record?.idCardNumber || '')
  const [checkIdError, setCheckIdError] = useState()
  const [phoneNumber, setPhoneNumber] = useState(record?.phoneNumber || '')
  const [mobile, setMobile] = useState(record?.mobile || '')
  const [prefix, setPrefix] = useState([])
  const [show, setShow] = useState()

  const handleCreate = async (values = {}) => {
    try {
      await createUser(values)
      mutateData()
      handleCancel(false)
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const handleChange = (value) => {
    console.log(`selected ${value.length}`)
    if (value !== '0') {
      setShow(false)
    } else {
      setShow(true)
    }
    //setShow(value !== "อื่น");
  }

  const handleUpdate = async (values) => {
    try {
      if (
        values.email !== record.email ||
        values.fname !== record.fname ||
        values.lname !== record.lname ||
        values.idCardNumber !== record.idCardNumber ||
        values.mobile !== record.mobile ||
        values.password !== record.password ||
        values.phoneNumber !== record.phoneNumber ||
        values.prefix !== record.prefix ||
        values.roles !== record.roles
      ) {
        await updateUser(record.id, values)
        mutateData()
      } else {
        console.log('No Update')
      }
      // await updateUser(record.id, values)
      // mutateData()
    } catch (error) {
      console.error('Error updating data:', error)
    }
    if (values.password && values.password.length > 0) {
      try {
        await updatePassUser(record.id, values)
        mutateData()
      } catch (error) {
        console.error('Error updating data:', error)
      }
    }
  }

  const onFinish = () => {
    const values = form.getFieldsValue()

    if (idCardNumber?.length !== 13) return

    if (!(phoneNumber?.length === 10 || phoneNumber?.length === 9 || phoneNumber?.length === 0))
      return

    if (!record) {
      handleCreate({
        ...values,
        idCardNumber,
        phoneNumber,
        mobile,
        prefix: values.prefix && values.prefix !== '0' ? values.prefix : values.prefixes,
        password: values.password
      })
    } else {
      handleUpdate({
        ...values,
        prefix: values.prefix && values.prefix !== '0' ? values.prefix : values.prefixes
      })
    }
  }

  useEffect(() => {
    listRoles()
      .then((res) => setRoles(res.data.items))
      .catch((error) => console.log(error))
    listPrefix()
      .then((res) => setPrefix(res.data))
      .catch((error) => console.log(error))
  }, [])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => setConfirm(true)}
      initialValues={{
        id: record?.id,
        fname: record?.fname,
        lname: record?.lname,
        email: record?.email,
        idCardNumber: record?.idCardNumber,
        prefix: record?.prefix,
        phoneNumber: record?.phoneNumber,
        mobile: record?.mobile,
        roles: record?.roles.map((role) => role.id) || []
      }}
      onSubmitCapture={() => {
        // setIdCardNumber("")
      }}>
      {/* <Content style={{ padding: '0px 20px 10px 20px' }}> */}
      <Row gutter={[10, 0]}>
        <Col span={24}>
          <Form.Item
            name="idCardNumber"
            label="เลขบัตรประชาชน :"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกเลขบัตรประชาชน'
              }
            ]}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            {...(idCardNumber?.length < 13 && {
              validateStatus: 'error',
              help: 'เลขบัตรประชาชนต้องมี 13 หลัก'
            })}
            {...(!checkIdError || {
              validateStatus: 'error',
              help: checkIdError
            })}>
            <Input
              className="inputtxt"
              value={idCardNumber}
              placeholder="กรอกเลขบัตรประชาชน"
              // disabled={record}
              disabled={view}
              maxLength={13}
              style={{ width: '100%' }}
              onChangeCapture={async (e) => {
                const value = e.target.value
                if (!isNaN(value)) {
                  setIdCardNumber(value)
                }
                if (validNationalID(value)) {
                  if (record) {
                    setCheckIdError(false)
                    return
                  }
                  const isCardUnique = await checkIdCardUniqueness(value)
                  if (!isCardUnique.data.available) {
                    setCheckIdError('เลขบัตรประชาชนถูกใช้ไปแล้ว')
                  } else {
                    setCheckIdError(false)
                  }
                } else {
                  if (value.length === 13) {
                    setCheckIdError('เลขบัตรประชาชนไม่ถูกต้อง')
                  } else {
                    setCheckIdError(false)
                  }
                }
              }}
            />
          </Form.Item>
        </Col>

        {!show && (
          <>
            <Col span={6}>
              <Form.Item
                label="คำนำหน้า :"
                name="prefix"
                rules={[
                  {
                    required: true,
                    message: 'กรุณาเลือกคำนำหน้า!'
                  }
                ]}>
                <Select
                  disabled={view}
                  placeholder="คำนำหน้า"
                  onChange={handleChange}
                  options={prefix.map((P) => ({ label: P.prefix, value: P.id }))}
                />
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item
                label="ชื่อ:"
                name="fname"
                requiredMark="optional"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกชื่อ!'
                  }
                ]}>
                <Input placeholder="กรอกชื่อ" disabled={view} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="นามสกุล :"
                requiredMark="optional"
                name="lname"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกนามสกุล!'
                  }
                ]}>
                <Input placeholder="กรอกนามสกุล" disabled={view} />
              </Form.Item>
            </Col>
          </>
        )}

        {show && (
          <>
            <Col span={3}>
              <Form.Item
                label="คำนำหน้า :"
                name="prefix"
                rules={[
                  {
                    required: true,
                    message: 'กรุณาเลือกคำนำหน้า!'
                  }
                ]}>
                <Select
                  disabled={view}
                  placeholder="คำนำหน้า"
                  onChange={handleChange}
                  options={prefix.map((P) => ({ label: P.prefix, value: P.id }))}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="กรุณากรอกคำนำหน้า :"
                name="prefixes"
                onKeyPress={(event) => {
                  if (/^[a-zA-Z0-9]*(\.[a-zA-Z0-9]*)*$/i.test(event.key)) {
                    event.preventDefault()
                  }
                }}>
                <Input placeholder="กรอกคำนำหน้า"></Input>
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                label="ชื่อ :"
                name="fname"
                requiredMark="optional"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกชื่อ!'
                  }
                ]}>
                <Input placeholder="กรอกชื่อ" disabled={view} />
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                label="นามสกุล :"
                requiredMark="optional"
                name="lname"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกนามสกุล!'
                  }
                ]}>
                <Input placeholder="กรอกนามสกุล" disabled={view} />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={12}>
          <Form.Item
            name="mobile"
            label="เบอร์โทรศัพท์มือถือ :"
            onKeyPress={(event) => {
              if (!/^[0-9]*$/i.test(event.key)) {
                event.preventDefault()
              }
            }}
            {...(mobile.length < 10 ||
              (mobile.length < 9 &&
                {
                  // validateStatus: "error",
                  // help: "เบอร์โทรศัพท์ต้องมี 10 หลัก",
                }))}>
            <Input
              className="inputtxt"
              value={mobile}
              placeholder="080-999-9999"
              // disabled={record}
              disabled={view}
              maxLength={10}
              style={{ width: '100%' }}
              onChangeCapture={(e) => {
                const value = e.target.value
                if (!isNaN(value)) {
                  console.log(value)
                  setMobile(value)
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label="เบอร์โทรศัพท์บ้าน :"
            onKeyPress={(event) => {
              if (!/^[0-9]*$/i.test(event.key)) {
                event.preventDefault()
              }
            }}
            {...(phoneNumber.length < 9 ||
              (phoneNumber.length < 9 &&
                {
                  // validateStatus: "error",
                  // help: "เบอร์โทรศัพท์ต้องมี 10 หลัก",
                }))}>
            <Input
              className="inputtxt"
              value={phoneNumber}
              placeholder="02-9999-999"
              // disabled={record}
              disabled={view}
              maxLength={9}
              style={{ width: '100%' }}
              onChangeCapture={(e) => {
                const value = e.target.value
                if (!isNaN(value)) {
                  console.log(value)
                  setPhoneNumber(value)
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="อีเมล :"
            name="email"
            onKeyPress={(event) => {
              if (!/^[a-zA-Z0-9]*[@]?[a-zA-Z0-9]*(\.[a-zA-Z0-9]*)*$/i.test(event.key)) {
                event.preventDefault()
              }
            }}
            rules={[
              {
                required: true,
                message: 'กรุณากรอกอีเมล!'
              },
              {
                type: 'email',
                message: 'รูปแบบอีเมลไม่ถูกต้อง!'
              },
              {
                async validator(_, value) {
                  if (record) {
                    return Promise.resolve()
                  }
                  if (value.length) {
                    const isEmailUnique = await checkEmailUniqueness(value)
                    if (!isEmailUnique.data.available) {
                      return Promise.reject('อีเมลนี้ถูกใช้งานแล้ว!')
                    }
                    return Promise.resolve()
                  }
                }
              }
            ]}>
            <Input disabled={view} placeholder="vru@gmail.com" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="สิทธิ์การใช้งาน :"
            name="roles"
            rules={[
              {
                required: true,
                message: 'กรุณาเลือกสิทธิ์การใช้งาน'
              }
            ]}>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%'
              }}
              disabled={view}
              placeholder="เลือกสิทธิ์การใช้งาน"
              options={roles.map((role) => ({
                label: role.name,
                value: role.id
              }))}
            />
          </Form.Item>
        </Col>
        {!view && record && (
          <Col span={24}>
            <Form.Item
              label="รหัสผ่านใหม่ :"
              name="password"
              rules={[
                {
                  required: !record,
                  message: 'กรุณากรอกรหัสผ่าน!'
                }
                // {
                //   min: 6,
                //   message: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร!'
                // }
              ]}>
              <Input.Password placeholder="กรอกรหัสผ่าน" disabled={view} />
            </Form.Item>
          </Col>
        )}
      </Row>
      {!view && (
        <Space style={{ display: 'flex', justifyContent: 'end' }}>
          <Button shape="round" className="btnBGreen" onClick={handleCancel}>
            ยกเลิก
          </Button>
          <Button className="btnGreen" shape="round" type="primary" htmlType="sumit">
            บันทึกข้อมูล
          </Button>
        </Space>
      )}
      {/* </Content> */}
      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
          setConfirm(false)
        }}
        fallback={() => {
          setConfirm(false)
        }}></Confirmation>
    </Form>
  )
}
function validNationalID(idCardNumber) {
  if (!idCardNumber || idCardNumber.length !== 13) return false

  for (var i = 0, sum = 0; i < 12; i++) {
    sum += parseInt(idCardNumber.charAt(i)) * (13 - i)
  }

  let mod = sum % 11

  let check = (11 - mod) % 10

  if (check === parseInt(idCardNumber.charAt(12))) {
    return true
  }
  return false
}

import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Col, Form, Row, Select, Space, Typography } from 'antd'
const { Text } = Typography

/**
 * @param {object} props
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {import("./types").ActionPropsType} props.actions
 * @param {boolean} props.disabled
 */
export default function SubSections({ actions, utils, disabled }) {
  const form = Form.useFormInstance()

  return (
    <>
      <Row justify="start">
        <Col>
          <Text>Sub Sections : </Text>
        </Col>
      </Row>

      <Row>
        <Col
          span={8}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <Space>
            <Form.Item
              name={utils.subSectionUtils.pathOfSubSection}
              style={{
                margin: '10px 0px',
                minWidth: 300
              }}>
              <Select
                placeholder="เลือก Sub Sections"
                options={utils.subSectionUtils.getSubSectionOptions()}
                disabled={disabled || !utils.subSectionUtils.getSubSectionOptions().length}
                onChange={() => {
                  form.resetFields([
                    utils.examTypeUtils.fullPathOfExamType,
                    utils.examinerUtils.fullPathOfUsersId,
                    utils.examUtils.fullPathOfNumberOfExamsRequired,
                    utils.examLevelUtils.fullPathOfExamLevel
                  ])
                }}
                allowClear={true}
              />
            </Form.Item>

            {!!utils.subSectionUtils.getAvailableSubSectionsValue().length &&
              !!utils.subSectionUtils.getSubSectionValue() && (
                <Button
                  disabled={disabled}
                  className="btnBorderG"
                  shape="round"
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    actions.add({
                      subExamCategoryLevel1Id: utils.partUtils.getPartValue(),
                      subExamCategoryLevel2Id: utils.sectionUtils.getSectionValue(),
                      subExamCategoryLevel3Id:
                        utils.subSectionUtils.getAvailableSubSectionsValue()[0],
                      examExaminerLevel: [{}]
                    })
                  }}>
                  เพิ่ม Sub Parts
                </Button>
              )}
          </Space>
        </Col>
      </Row>
    </>
  )
}

import { React, useEffect, useState } from 'react'
import { Layout, Row, Col, Input, Form, Button, Typography, Space, Card } from 'antd'
import LayoutPage from '../components/Layout'
import logo from '../Image/logo.png'
import logoForget from '../Image/logoforget.png'
import { Header } from 'antd/es/layout/layout'
import { useLocation } from 'react-router-dom'
import { fetchJson, fetchJsonWithToken } from '../lib/fethcer'
import env from '../lib/environments'
import VruModal from '../components/Modal'
import PasswordUpdated from '../components/PasswordUpdated'

const { Text } = Typography

const { Content } = Layout

const ResetPassWordPage = () => {
  const [email, setEmail] = useState()
  const [token, setToken] = useState()
  const [roleId, setRoleId] = useState()
  const [pwd1, setPwd1] = useState('')
  const [pwd2, setPwd2] = useState('')
  const [updated, setUpdated] = useState(false)

  const contentStyle = {
    backgroundColor: '#F1FFF5',
    height: '100vh',
    with: '100%'
  }

  const loginPage = () => {
    window.location.href = '/'
  }

  const location = useLocation()

  useEffect(() => {
    const paramsToken = new URLSearchParams(location.search)
    setToken(paramsToken.get('token'))
    setEmail(paramsToken.get('email'))
    resetPassapi(paramsToken.get('email'), paramsToken.get('token'))
  }, [])

  const resetPassapi = async (email, token) => {
    const paramsToken = new URLSearchParams(location.search)
    setToken(token)
    setEmail(email)
    const res = await fetchJson(env.resetPassapi, {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        email: email
      })
    }).then((data) => {
      if (data.success == false && !updated) loginPage()
    })
  }

  const funResetPass = async () => {
    const paramsToken = new URLSearchParams(location.search)
    setToken(token)
    setEmail(email)
    if (pwd1 !== '' && pwd1 === pwd2) {
      try {
        console.log(token)
        const res = await fetchJson(env.resetPassapi, {
          method: 'PATCH',
          body: JSON.stringify({
            token: token,
            email: email,
            password: pwd1
          })
        })
        if (res.data.action === 'update' && res.data.status == 'success') setUpdated(true)
        else {
          console.log(res)
          setUpdated(false)
          alert('เกิดข้อผิดพลาดในการอัปเดตข้อมูล')
        }
      } catch (error) {
        console.error('Error updating data:', error)
      }
    } else {
      return false
    }
  }

  const checkEqual = (pwd1, pwd2) => {
    if (pwd1 === pwd2 && pwd1.length >= 8 && pwd1.length < 16) return true
    else return false
  }

  return (
    <>
      <Header
        style={{
          height: '80px',
          padding: '0px 0px 0px 0px',
          background:
            'linear-gradient(to right, rgba(50,157,156),rgba(86,197,150),rgba(123,228,150))'
        }}>
        <Row justify="space-between" style={{ lineHeight: 0, padding: '0 20px' }}>
          <Col>
            <Space style={{ alignItems: 'center', lineHeight: 0 }}>
              <img src={logo} style={{ maxWidth: '45px', padding: '10px 0px 0px' }} />
              <div>
                <Text style={{ display: 'block', fontFamily: 'KanitM', color: '#fff' }}>
                  ระบบคลังข้อสอบ
                </Text>
                <Text style={{ fontFamily: 'Kanit', color: '#fff' }}>
                  มหาวิทยาลัยราชภัฏวไลยอลงกรณ์
                </Text>
              </div>
            </Space>
          </Col>
          <Col>
            <Space align="center" style={{ height: '100%', alignItems: 'center' }}>
              <Typography.Link style={{ color: '#fff', fontFamily: 'KanitM' }} onClick={loginPage}>
                เข้าสู่ระบบ
              </Typography.Link>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content style={contentStyle}>
        <Card style={{ height: '90vh' }}>
          <Row justify="center">
            <Col>
              <img src={logoForget} style={{ maxWidth: '300px', padding: '10px 0px 0px' }}></img>
            </Col>
          </Row>
          <Row justify="center">
            <Text
              style={{
                fontFamily: 'KanitM',
                color: '#0B6252',
                fontSize: '25px'
              }}>
              เปลี่ยนรหัสผ่าน
            </Text>
          </Row>
          <Row justify="center">
            <Col style={{ paddingTop: '20px' }}>
              <Text>กรอกรหัสผ่านใหม่ที่คุณต้องการ</Text>
            </Col>
          </Row>
          <Form onFinish={funResetPass} layout="vertical">
            <Row justify="center">
              <Col style={{ paddingTop: '20px' }} span={9}>
                <Form.Item name="newpass" label="รหัสผ่านใหม่:">
                  <Input
                    type="password"
                    onChange={(el) => {
                      setPwd1(el.target.value)
                    }}
                    shape="round"
                    style={{
                      height: '45px',
                      borderRadius: '40px',
                      fontFamily: 'Kanit'
                    }}
                    placeholder="กรอกรหัสผ่าน"
                    disabled={updated}></Input>
                </Form.Item>
                <Form.Item name="newpass2" label="รหัสผ่านอีกครั้ง:">
                  <Input
                    type="password"
                    onChange={(el) => {
                      setPwd2(el.target.value)
                    }}
                    shape="round"
                    style={{
                      height: '45px',
                      borderRadius: '40px',
                      fontFamily: 'Kanit'
                    }}
                    disabled={!(pwd1.length >= 8 && pwd1.length < 16) || updated}
                    placeholder="กรอกรหัสผ่าน"></Input>
                </Form.Item>
                <Text
                  style={
                    !checkEqual(pwd1, pwd2) && pwd1
                      ? { display: 'flex', fontSize: '12px', color: 'red' }
                      : { display: 'none' }
                  }>
                  รหัสผ่านจะต้องเท่ากันและมีความยาว 8 &gt; 15 ตัวอักษร{' '}
                </Text>
                <Text
                  style={
                    updated
                      ? {
                          display: 'flex',
                          color: 'green',
                          fontWeight: 'bold',
                          justifyContent: 'center'
                        }
                      : { display: 'none' }
                  }>
                  {' '}
                  อัปเดตรหัสผ่านเรียบร้อยแล้ว{' '}
                </Text>
                <Text
                  style={
                    updated
                      ? {
                          display: 'flex',
                          color: 'green',
                          fontWeight: 'bold',
                          justifyContent: 'center'
                        }
                      : { display: 'none' }
                  }>
                  {' '}
                  ไปที่ <a href="/LoginPage"> &nbsp;หน้าเข้าสู่ระบบ</a>
                </Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ paddingTop: '20px' }}>
                <Button
                  htmlType="submit"
                  style={{
                    width: '300px',
                    height: '45px',
                    background:
                      'linear-gradient(to right, rgba(50,157,156),rgba(86,197,150),rgba(123,228,150)',
                    color: 'white'
                  }}
                  disabled={!checkEqual(pwd1, pwd2)}
                  shape="round"
                  onSubmit={funResetPass}>
                  เปลี่ยนรหัสผ่าน
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Content>
      <VruModal open={updated} title="Password updated" closeIcon={false}>
        <PasswordUpdated
          title="Password updated successfully"
          handleOk={useEffect}
          closeIcon={false}
          cancelButtonProps={{ style: { display: 'none' } }}>
          {' '}
        </PasswordUpdated>
      </VruModal>
    </>
  )
}

export default ResetPassWordPage

export default class CategoryUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfSubCatFieldValue = 'subExamCategoryId'
  }

  getSubCatValue = () => this.parent.form.getFieldValue(this.nameOfSubCatFieldValue)
}

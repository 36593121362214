import { Button, Col, Form, Row, Space, Typography } from 'antd'
import useExamSelectors from './useSelectors'
const { Text } = Typography

export default function Filters({ form, onFinish }) {
  const { createMainCatSelector, createSubCatSelector } = useExamSelectors()

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={[0, 24]} justify="space-between">
        <Col span={12}>
          <Text style={{ fontFamily: 'KanitM', color: '#0B6252', fontSize: '25px' }}>
            จัดเก็บชุดข้อสอบ
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ padding: '30px 0px 0px 0px' }}>
        <Col span={7}>{createMainCatSelector()}</Col>
        <Col span={7}>{createSubCatSelector()}</Col>
        <Row justify="end">
          <Space align="center" style={{ padding: '5px 0px 0px 0px' }}>
            <Button
              htmlType="sumit"
              style={{ width: '100px', height: '35px' }}
              className="btnGreen"
              shape="round">
              ค้นหา
            </Button>
          </Space>
        </Row>
      </Row>
    </Form>
  )
}

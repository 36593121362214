import withHook from '../withHook'
import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import ConfirmationDelete from '../../components/ConfirmDelete'
import { CheckOutlined, DeleteFilled, EditFilled } from '@ant-design/icons'
import Level1_Form from '../../components/Level1_Form'
import VruModal from '../../components/Modal'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
import { deleteLevel1 } from '../../data/level1.data'
// import getColumnSearchProps from './columnSearch'
import { useAuth } from '../../AuthorizationProvider'

dayjs.extend(localizedFormat)

export const createColumnsLevel1 = (mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    responsive: ['sm'],
    align: 'center'
  },
  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'mainExamCategoryName',
    responsive: ['sm'],
    filterMode: 'menu',
    filterSearch: true,
    // ...getColumnSearchProps('mainExamCategoryName'),
    align: 'center'
  },
  {
    title: 'ชื่อหมวดหมู่ข้อสอบย่อย',
    dataIndex: 'subExamCategoryName',
    responsive: ['sm'],
    // ...getColumnSearchProps('subExamCategoryName'),
    align: 'center'
  },
  {
    title: 'ชื่อ Parts',
    dataIndex: 'subExamCategoryLevel1Name',
    // ...getColumnSearchProps('subExamCategoryLevel1Name'),
    responsive: ['sm'],
    align: 'center'
  },
  {
    title: 'สถานะ',
    dataIndex: 'active',
    align: 'center',
    render(active) {
      return {
        props: {
          style: {
            color: !active ? 'red' : 'green'
          }
        },
        children: <div>{active ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</div>
      }
    }
  },
  {
    title: 'แก้ไขล่าสุด',
    dataIndex: 'updatedAt',
    align: 'center',
    render: (_, record) =>
      record.updatedAt ? dayjs(record.updatedAt).locale('th').format('DD MMMM BBBB LT น.') : '-'
  },
  {
    title: 'จัดการ',
    dataIndex: 'manage',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) => <Actions record={record} mutateData={mutateData} />
  }
]

const Actions = ({ record, mutateData }) => {
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const { currentUserCan } = useAuth()
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Row gutter={[10, 0]} justify="center" align="center" style={{ height: '100%' }}>
        <Col>
          <VruModal title="แก้ไข" open={editModal} handleCancel={() => setEditModal(false)}>
            <Level1_Form
              view={true}
              record={record}
              mutateData={mutateData}
              handleCancel={() => setEditModal(false)}
            />
          </VruModal>
          <Button
            disabled={!currentUserCan(['update_sub_exam_category_level1'])}
            className="btnedit"
            icon={<EditFilled />}
            onClick={() => setEditModal(true)}
          />
        </Col>
        <Col>
          {record?.subExamCategoryLevel2Id.length === 0 ? (
            <Button
              disabled={!currentUserCan(['delete_sub_exam_category_level1'])}
              className="btndelete"
              icon={<DeleteFilled />}
              onClick={() => setDeleteModal(true)}
            />
          ) : (
            <Button disabled className="btndelete" icon={<DeleteFilled />} />
          )}
          <ConfirmationDelete
            open={deleteModal}
            callback={async () => {
              await deleteLevel1(record.subExamCategoryLevel1Id)
              mutateData()
              setDeleteModal(false)
            }}
            fallback={() => {
              setDeleteModal(false)
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

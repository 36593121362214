import { React } from 'react'
import { Row, Col, Typography, Form, Button, Layout, message } from 'antd'
import LayoutPage from '../../components/Layout'
import { createExam, mediaExam } from '../../data/exam.data'
import { useNavigate } from 'react-router-dom'
import img from '../../Image/img_select_data.png'
import SingleChoiceExamForm from '../../components/SingleChoiceExamForm'
import { EXAM_CREATOR } from '../../constants/paths'

import { removePTag, removeClozeTag, setTheDefaultDimensionsForImage } from '../../lib/helpers'
import LectureChoiceExamForm from '../../components/LectureChoiceExamForm'
import MatchingChoiceExamForm from '../../components/MatchingChoiceExamForm'
import ClozePassageExamForm from '../../components/ClozePassageExamForm'
import { useAuth } from '../../AuthorizationProvider'
import SituationChoiceExamForm from '../../components/SituationChoiceExamForm'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'

const { Title } = Typography
const { Content } = Layout
const CreateExaminations = () => {
  const { usePageAuth } = useAuth()
  usePageAuth(['create_exam'])

  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [messageApi, contextHolder] = message.useMessage()

  const {
    examLevels,
    MainExamCategorySelector,
    SubExamCategorySelector,
    SectionSelector,
    PartSelector,
    SubSectionSelector,
    ExamTypeSelector
  } = useExamSelectors()

  const checkPreviewExamLevel = async (examCreator) => {
    for (let i = 0; i < examCreator.length; i++) {
      if (!examCreator[i].examLevelId) {
        messageApi.open({
          type: 'error',
          content: 'กรุณาเลือกระดับข้อสอบ'
        })
        return true
      }
    }
  }

  const onFinish = async (values) => {
    const { examCreator, ...others } = values
    const notHaveExamLevelId = await checkPreviewExamLevel(examCreator)

    if (!notHaveExamLevelId) {
      if (values.examType === 2) {
        examCreator.forEach((exam) => {
          const { detailsExam } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
        })
      } else if (values.examType === 3) {
        examCreator.forEach((exam) => {
          const { detailsExam, examMatch } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
          examMatch.forEach((match) => {
            const { questionText, answerText } = match
            match.questionText = setTheDefaultDimensionsForImage(questionText)
            match.answerText = setTheDefaultDimensionsForImage(answerText)
          })
        })
      } else if (values.examType === 4 || values.examType === 5) {
        examCreator.forEach((exam) => {
          const { detailsExam, childExam } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
          childExam.forEach((child) => {
            const { detailsExam, examChoice } = child
            child.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
            examChoice.forEach((choice) => {
              const { choiceName } = choice
              choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
            })
          })
        })
      } else {
        examCreator.forEach((exam) => {
          const { detailsExam, examChoice } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)

          examChoice.forEach((choice) => {
            const { choiceName } = choice
            choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
          })
        })
      }

      if (
        values.examType === 1 ||
        values.examType === 2 ||
        values.examType === 3 ||
        values.examType === 4
      ) {
        const body = examCreator.map((exam) => ({
          ...exam,
          ...others,
          detailsExam: exam.detailsExam
            ? values.examType === 4
              ? removeClozeTag(exam.detailsExam)
              : exam.detailsExam
            : '',
          examChoice: exam.examChoice?.map((choice) => ({
            ...choice,
            choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
          })),
          examMatch: exam.examMatch?.map((match) => ({
            ...match,
            questionText: match.questionText ? removePTag(match.questionText) : '',
            answerText: match.answerText ? removePTag(match.answerText) : ''
          })),
          childExam:
            values.examType === 4
              ? exam.childExam?.map((child) => ({
                  ...child,
                  examChoice: child.examChoice?.map((choice) => ({
                    ...choice,
                    choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
                  }))
                }))
              : [],
          active: exam.active
        }))

        Promise.all(
          body.map((b) => {
            createExam(b)
          })
        )
          .then(() => {
            navigate(`${EXAM_CREATOR}/list`)
          })
          .catch((error) => console.log(error))
      } else if (values.examType === 5) {
        if (values.examCreator[0].file !== undefined) {
          const bodyForm = new FormData()
          bodyForm.append('file', values.examCreator[0].file?.fileList[0].originFileObj)
          mediaExam(bodyForm).then((resp) => {
            const bodyy = examCreator.map((exam) => ({
              ...exam,
              ...others,
              detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
              childExam: exam.childExam?.map((child) => ({
                ...child,
                detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
                examChoice: child.examChoice?.map((choice) => ({
                  ...choice,
                  choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
                }))
              })),
              mediaId: resp.data.id,
              active: exam.active
            }))
            Promise.all(bodyy.map((b) => createExam(b)))
              .then(() => {
                navigate(`${EXAM_CREATOR}/list`)
              })
              .catch((error) => console.log(error))
          })
        } else {
          const bodyy = examCreator.map((exam) => ({
            ...exam,
            ...others,
            detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
            childExam: exam.childExam?.map((child) => ({
              ...child,
              detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
              examChoice: child.examChoice?.map((choice) => ({
                ...choice,
                choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
              }))
            })),
            active: exam.active
          }))

          Promise.all(bodyy.map((b) => createExam(b)))
            .then(() => {
              navigate(`${EXAM_CREATOR}/list`)
            })
            .catch((error) => console.log(error))
        }
      }
    }
  }

  return (
    <>
      {contextHolder}
      <LayoutPage>
        <Content>
          <Title
            level={2}
            style={{
              color: '#0B6252',
              marginBottom: 20
            }}>
            เพิ่มชุดข้อสอบ
          </Title>

          <Form
            form={form}
            layout="vertical"
            initialValues={{
              examCreator: [
                {
                  examChoice: [{ isCorrect: false }],
                  examMatch: [{}],
                  childExam: [{ examChoice: [{ isCorrect: false }] }]
                }
              ]
            }}
            onFinish={onFinish}>
            <Row gutter={10} align="middle" style={{ marginBottom: 15 }}>
              <Col span={7}>
                <MainExamCategorySelector />
              </Col>

              <Col span={7}>
                <SubExamCategorySelector />
              </Col>

              <Col span={7}>
                <PartSelector />
              </Col>
            </Row>

            <Row gutter={10} align="middle">
              <Col span={7}>
                <SectionSelector />
              </Col>

              <Col span={7}>
                <SubSectionSelector />
              </Col>

              <Col span={7}>
                <Form.Item
                  shouldUpdate={(prev, cur) =>
                    prev.subExamCategoryLevel1Id !== cur.subExamCategoryLevel1Id
                  }
                  noStyle>
                  {() => {
                    const sectionId = form.getFieldValue('subExamCategoryLevel1Id')
                    return <ExamTypeSelector disabled={!sectionId} />
                  }}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.examType !== curValues.examType}>
              {() => {
                const typeValue = form.getFieldValue('examType')

                form.setFieldsValue({
                  examCreator: [
                    {
                      examChoice: [{ isCorrect: false }],
                      examMatch: [{}],
                      childExam: [{ examChoice: [{ isCorrect: false }] }]
                    }
                  ]
                })

                return (
                  <>
                    {/* <SingleChoiceExamForm examLevels={examLevels} /> */}
                    {typeValue === 1 && <SingleChoiceExamForm examLevels={examLevels} />}
                    {typeValue === 2 && <LectureChoiceExamForm examLevels={examLevels} />}
                    {typeValue === 3 && <MatchingChoiceExamForm examLevels={examLevels} />}
                    {typeValue === 4 && <ClozePassageExamForm examLevels={examLevels} />}
                    {typeValue === 5 && <SituationChoiceExamForm examLevels={examLevels} />}
                    {!typeValue && <img src={img} style={{ display: 'block', margin: '0 auto' }} />}

                    {(typeValue === 1 ||
                      typeValue === 2 ||
                      typeValue === 3 ||
                      typeValue === 4 ||
                      typeValue === 5) && (
                      <Row justify="end">
                        <Col style={{ padding: '25px 70px 0px 0px' }}>
                          <Button className="btnGreen" shape="round" htmlType="sumit">
                            บันทึกข้อมูล
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                )
              }}
            </Form.Item>
          </Form>
        </Content>
      </LayoutPage>
    </>
  )
}

const contentStyle = {
  backgroundColor: '#F4F4F4',
  height: 'auto',
  alignItems: 'center',
  padding: '30px 50px 0px 30px'
}

export default CreateExaminations

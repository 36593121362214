import { Col, Form, Row, Switch, Table } from 'antd'
import LayoutPage from '../../components/Layout'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'
import useDataTable from '../../lib/useDataTable'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import _ from 'lodash'
import { activateExam, deactivateExam, listExam } from '../../data/exam.data'

dayjs.extend(buddhistEra)

export default function ExamsManagement() {
  const [form] = Form.useForm()
  const {
    MainExamCategorySelector,
    SubExamCategorySelector,
    PartSelector,
    SectionSelector,
    SubSectionSelector,
    ExamTypeSelector
  } = useExamSelectors()
  const { dataSource, tableParams, handleTableChange, handleOuterFiltersChange } =
    useDataTable(listExam)

  return (
    <LayoutPage>
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={(_, allValues) => {
          handleOuterFiltersChange(
            allValues.reduce((acc, value) => ({ ...acc, [value.name[0]]: value.value }), {})
          )
        }}>
        <Row gutter={20}>
          <Col>
            <MainExamCategorySelector />
          </Col>

          <Col>
            <SubExamCategorySelector />
          </Col>

          <Col>
            <PartSelector />
          </Col>

          <Col>
            <SectionSelector />
          </Col>

          <Col>
            <SubSectionSelector />
          </Col>

          <Col>
            <ExamTypeSelector />
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey={(row) => row.id}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          size="small"
        />
      </Form>
    </LayoutPage>
  )
}

const columns = [
  {
    title: '',
    dataIndex: 'seq'
  },
  {
    title: 'หมวดหมู่หลัก',
    dataIndex: ['mainExamCategory', 'mainExamCategoryName']
  },
  {
    title: 'หมวดหมู่ย่อย',
    dataIndex: ['subExamCategory', 'subExamCategoryName']
  },
  {
    title: 'รายละเอียด',
    dataIndex: 'detailsExam',
    width: '450px',
    render: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />
  },
  {
    title: 'ประเภท',
    dataIndex: 'examTypeName'
  },
  {
    title: 'ผู้ออก',
    render: (_, record) => `${record.createdBy.fname} - ${record.createdBy.lname}`
  },
  {
    title: 'ใช้ไปทั้งหมด (ครั้ง)',
    align: 'center',
    dataIndex: 'useCount',
    sorter: true
  },
  {
    title: 'สร้างเมื่อ',
    align: 'center',
    render: (_, record) => dayjs(record.createdAt).locale('th').format('DD MMMM BBBB')
  },
  {
    title: 'จัดการ',
    dataIndex: 'active',
    align: 'center',
    render: (value, record) => (
      <Switch
        defaultChecked={value}
        onChange={async (value) => {
          try {
            if (value) activateExam(record.id)
            else deactivateExam(record.id)
          } catch (error) {
            console.log(error)
          }
        }}
      />
    )
  }
]

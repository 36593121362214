import React, { useRef, useState, useEffect } from 'react'
import { Form } from 'antd'
import defer from 'lodash/defer'
import 'quill/dist/quill.snow.css'
import ReactQuill, { Quill } from 'react-quill'
import BlotFormatter from 'quill-blot-formatter'

Quill.register('modules/blotFormatter', BlotFormatter)

const Delta = Quill.import('delta')
// import ImageResize from 'quill-image-resize-module-react'

// Quill.register('modules/imageResize', ImageResize)

const Embed = Quill.import('blots/embed')

class ClozeExamBlot extends Embed {
  static create(data) {
    if (data) {
      const node = super.create(data.value)
      node.setAttribute('data-value', data.value)
      node.setAttribute('id', data.index)
      node.setAttribute('contenteditable', false)

      node.innerHTML += data.value
      return node
    }
  }

  static value(domNode) {
    return domNode.getAttribute('data-value')
  }

  remove() {
    this.domNode.parentNode.dispatchEvent(
      new CustomEvent('blot-removed', {
        bubbles: true,
        detail: this
      })
    )
    super.remove()
  }

  deleteAt() {
    return false
  }

  // attach() {
  //   super.attach()
  //   if (!this.mounted) {
  //     this.mounted = true
  //     this.scroll.domNode.dispatchEvent(
  //       new CustomEvent('blot-mounted', {
  //         bubbles: true,
  //         detail: this
  //       })
  //     )
  //   }
  // }

  // detach() {
  //   console.log('detach')
  //   this.mounted = false
  //   this.scroll.domNode.dispatchEvent(
  //     new CustomEvent('blot-unmounted', {
  //       bubbles: true,
  //       detail: this
  //     })
  //   )
  //   super.detach()
  // }
}

ClozeExamBlot.blotName = 'clozeExam'
ClozeExamBlot.tagName = 'span'
ClozeExamBlot.className = 'cloze-exam'

Quill.register(ClozeExamBlot, true)

const ClozeEditor = ({ name, quan, isRemove, isAddCloze, index, removeExamIndex, record }) => {
  const [parentIndex] = name
  const form = Form.useFormInstance()
  const quillRef = useRef(null)

  const getInsertFromDelta = ({ ops }) => {
    const insertOp = ops.find((op) => op.insert && op.insert !== '')
    if (insertOp) return insertOp.insert
    return null
  }

  const getRetainFromDelta = ({ ops }) => {
    const insertOp = ops.find((op) => op.retain)
    if (insertOp) return insertOp.retain
    return 0
  }

  const getDeleteFromDelta = ({ ops }) => {
    const insertOp = ops.find((op) => op.retain)

    if (insertOp) return insertOp.retain
    return 0
  }

  useEffect(() => {
    // console.log('removeCloze', quan, isRemove, isRecord)
    if (quan > 0 && isAddCloze) {
      insertCloze()
    } else if (isRemove) {
      removeCloze()
    } else {
      if (record) {
        insertClozeUpdate()
      }
    }
  }, [quan, index])

  const onTextChange = (delta, oldDelta) => {
    // console.log('delta', delta)
    // console.log('oldDelta', oldDelta)

    const quill = quillRef.current

    // console.log('quill', delta, oldDelta, form.getFieldsValue())
    // if (quill) {
    //   console.log('quill', quill.getEditor().getContents().ops, oldDelta.ops[0].retain)
    //   // if(quill.getEditor().getContents().ops)
    //   quill
    //     .getEditor()
    //     .getContents()
    //     .ops.map((a) => a.insert.clozeExam && setBlotDeleted([...blotDeleted, a.insert.clozeExam]))
    // }

    // quill.keyboard.addBinding(
    //   {
    //     key: 'backspace'
    //   },
    //   function (range, context) {
    //     console.log('backspace')
    //   }
    // )
    // if (quill) {
    //   console.log(quill.getContents().ops)
    //   console.log(quill.getLength())
    // }
  }

  const removeCloze = () => {
    const editorId = quillRef.current.props.id
    const regex = /_(.*?)_/

    const match = editorId.match(regex)
    const editorIndex = parseInt(match[1])

    if (editorIndex === index) {
      const quill = quillRef.current.getEditor()
      const cloze = `____(${removeExamIndex})____`
      const clozeLists = quill
        .getContents()
        .ops.filter((op) => op.insert.clozeExam !== cloze && op.insert !== '\n')
      quill.setContents(new Delta())
      let replaceCount = 1
      let clozeReplace = `____${replaceCount}____`

      for (let i = 0; i < clozeLists.length; i++) {
        quill.setSelection(quill.getLength() - 1, 0)
        const selection = quill.getSelection()

        if (clozeLists[i].insert.clozeExam) {
          clozeReplace = `____(${replaceCount})____`
          replaceCount += 1
          quill.insertEmbed(
            selection.index,
            'clozeExam',
            {
              index: parentIndex,
              id: quill.getLength(),
              value: clozeReplace
            },
            'user'
          )
          quill.setSelection(quill.getLength() + 1, 0)
        } else if (clozeLists[i].insert.image) {
          quill.clipboard.dangerouslyPasteHTML(
            selection.index,
            `<img class="image" src="${clozeLists[i].insert.image}" height="${clozeLists[i].attributes.height}" width="${clozeLists[i].attributes.width}">`
          )
        } else {
          clozeReplace = clozeLists[i].insert.replace(/(\r\n|\n|\r)/gm, '')
          quill.insertText(selection.index, clozeReplace)
          quill.setSelection(quill.getLength() + 1, 0)
        }
      }
    }
  }

  const insertCloze = () => {
    const quill = quillRef.current.getEditor()
    const regex = /_(.*?)_/
    const editorId = quillRef.current.props.id
    const match = editorId.match(regex)
    const editorIndex = parseInt(match[1])

    let selection = quill.getSelection()
    if (!selection) {
      quill.setSelection(quill.getLength(), 0)
      selection = quill.getSelection()
    }

    if (selection) {
      const selection = quill.getSelection()

      if (editorIndex === index) {
        quill.insertEmbed(
          selection.index,
          'clozeExam',
          {
            index: parentIndex,
            id: quill.getLength(),
            value: `____(${quan})____`
          },
          'user'
        )
      }
      quill.setSelection(quill.getLength() + 1, 0)
    }
  }
  const insertClozeUpdate = () => {
    const editorIdRegex = /_(.*?)_/
    const detailRegex = /([^_]+|_+[^_]+_+)/g
    const clozeRegex = /^____\(\d\)____$/
    const imgRegex = /<img.*?>/

    let detailMatches = []
    let detailMatch

    if (record.length) {
      for (let i = 0; i < record.length; i++) {
        const detailsExam = record[i].detailsExam

        while ((detailMatch = detailRegex.exec(detailsExam)) !== null) {
          if (detailMatch[0]) {
            detailMatches.push(detailMatch[0])
          }
        }

        const editorId = quillRef.current.props.id
        const match = editorId.match(editorIdRegex)
        const editorIndex = parseInt(match[1])

        if (editorIndex === i) {
          const quill = quillRef.current.getEditor()
          quill.setContents(new Delta())
          for (let j = 0; j < detailMatches.length; j++) {
            let selection = quill.getSelection()
            if (!selection) {
              quill.setSelection(quill.getLength(), 0)
              selection = quill.getSelection()
            }

            if (selection) {
              const selection = quill.getSelection()
              if (clozeRegex.test(detailMatches[j])) {
                quill.insertEmbed(
                  selection.index,
                  'clozeExam',
                  {
                    index: parentIndex,
                    id: i,
                    value: detailMatches[j]
                  },
                  'user'
                )

                quill.setSelection(quill.getLength() + 1, 0)
              } else if (imgRegex.test(detailMatches[j])) {
                quill.clipboard.dangerouslyPasteHTML(selection.index, detailMatches[j])
                quill.setSelection(quill.getLength() + 1, 0)
              } else {
                quill.insertText(selection.index, detailMatches[j])
                quill.setSelection(quill.getLength() + 1, 0)
              }

              quill.setSelection(quill.getLength() + 1, 0)
            }
          }
        }

        detailMatches = []
      }
    } else {
      const detailsExam = record.detailsExam

      while ((detailMatch = detailRegex.exec(detailsExam)) !== null) {
        if (detailMatch[0]) {
          detailMatches.push(detailMatch[0])
        }
      }

      const quill = quillRef.current.getEditor()
      quill.setContents(new Delta())
      for (let j = 0; j < detailMatches.length; j++) {
        let selection = quill.getSelection()
        if (!selection) {
          quill.setSelection(quill.getLength(), 0)
          selection = quill.getSelection()
        }

        if (selection) {
          const selection = quill.getSelection()

          if (clozeRegex.test(detailMatches[j])) {
            quill.insertEmbed(
              selection.index,
              'clozeExam',
              {
                index: parentIndex,
                id: 0,
                value: detailMatches[j]
              },
              'user'
            )

            quill.setSelection(quill.getLength() + 1, 0)
          } else if (imgRegex.test(detailMatches[j])) {
            quill.clipboard.dangerouslyPasteHTML(selection.index, detailMatches[j])
            quill.setSelection(quill.getLength() + 1, 0)
          } else {
            quill.insertText(selection.index, detailMatches[j])
            quill.setSelection(quill.getLength() + 1, 0)
          }

          quill.setSelection(quill.getLength() + 1, 0)
        }
      }

      detailMatches = []
    }
  }

  const validateQuillPattern = (_, value) => {
    const pattern = /____\(\d\)____/

    if (!pattern.test(value)) {
      return Promise.reject('The content must have Cloze.')
    }

    return Promise.resolve()
  }

  return (
    <>
      {/* <Button
        onClick={() => {
          insertMention()
        }}
        className="btnBorderG"
        shape="round"
        icon={<PlusCircleFilled />}
        style={{ marginBottom: 10 }}>
        Add Cloze
      </Button> */}

      <Form.Item name={name} rules={[{ validator: validateQuillPattern }]}>
        <ReactQuill
          theme="snow"
          modules={modules}
          // formats={formats}
          placeholder="write your content ...."
          className="question-editor"
          ref={quillRef}
          // onChange={onTextChange}
        />
      </Form.Item>
    </>
  )
}

export default ClozeEditor

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    [
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          'custom-color'
        ]
      }
    ],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  },
  // imageResize: {
  //   parchment: Quill.import('parchment'),
  //   modules: ['Resize', 'DisplaySize']
  // }
  blotFormatter: {}
}
const formats = [
  'header',
  'height',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'color',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
  'size'
]

import React from 'react'
import { Col, Row, Typography } from 'antd'
import qs from 'qs'
import { stripHTMLTags } from '../helpers'
import parser from 'html-react-parser'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)
const { Text } = Typography
export const ViewQuestionsColumnsType3 = () => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    align: 'center'
  },
  {
    title: 'คำชี้แจง',
    dataIndex: 'exam',
    align: 'center',
    width: '30%',
    render: (exam, record) => (
      <div>
        <Row justify="start">
          <Col>
            <Text>{stripHTMLTags(exam?.detailsExam)}</Text>
          </Col>
        </Row>
      </div>
    )
  },
  {
    title: 'คำถาม',
    dataIndex: 'examMatch',
    align: 'center'
    // render: (examMatch) => (
    //   <div
    //     style={{
    //       display: 'grid',
    //       gridTemplateColumns: '30px 1fr',
    //       gap: 8
    //     }}>
    //     {examMatch.map((match, index) => (
    //       <React.Fragment key={index}>
    //         <div style={{ gridColumnStart: 2, textAlign: 'left' }}>
    //           {stripHTMLTags(match.questionText)}
    //         </div>
    //       </React.Fragment>
    //     ))}
    //   </div>
    // )
  },
  {
    title: 'คำตอบ',
    dataIndex: 'examMatch',
    align: 'center'
    // render: (examMatch) => (
    //   <div
    //     style={{
    //       display: 'grid',
    //       gridTemplateColumns: '30px 1fr',
    //       gap: 8
    //     }}>
    //     {examMatch.map((match, index) => (
    //       <React.Fragment key={index}>
    //         <div style={{ gridColumnStart: 2, textAlign: 'left' }}>
    //           {stripHTMLTags(match.answerText)}
    //         </div>
    //       </React.Fragment>
    //     ))}
    //   </div>
    // )
  }
]

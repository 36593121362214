import { useState, useEffect } from 'react'
import vru from '../Image/vru.json'
import Lottie from 'lottie-react'
import shape from '../Image/shape.png'
import { Button, Form, Input, Col, Row, Layout, Typography, Checkbox, notification } from 'antd'

import { UserOutlined, UnlockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import '../style/main.css'
import env from '../lib/environments'
import { fetchJson } from '../lib/fethcer'
import { setTokens, setUsersData } from '../lib/token'
import portals from '../lib/portals'
import logo from '../Image/logo_vru.png'

const { Content } = Layout
const { Text } = Typography

const Login = () => {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [checked, setChecked] = useState(false)
  const contentStyle = {
    background:
      'radial-gradient(circle at center, #73DF97B5 3%,#21AC71A3 ,#18AC7BDE ,#04A985 100%)',
    with: '100%',
    height: '80vh',
    align: 'center'
  }

  useEffect(() => {
    if (localStorage.getItem('remembered') === 'true') {
      setEmail(localStorage.getItem('email'))
      setPassword(localStorage.getItem('password'))
    } else {
      setEmail('')
      setPassword('')
    }
  }, [])
  const [noti, contextHolder] = notification.useNotification()

  const openNotification = (placement) => {
    noti.error({
      // message: `ไม่สามารถเข้าสู่ระบบได้`,
      description: 'ไม่สามารถเข้าสู่ระบบได้',
      placement,
      type: 'error'
    })
  }
  const handleLogin = async () => {
    if (checked === true) {
      localStorage.setItem('remembered', 'true')

      localStorage.setItem('email', email)
      localStorage.setItem('password', password)
    }
    try {
      const res = await fetchJson(env.authApi, {
        method: 'POST',
        body: JSON.stringify({
          email,
          password
        })
      })

      if (res.success) {
        if (checked === true) {
          localStorage.setItem('remembered', 'true')
          localStorage.setItem('email', email)
          localStorage.setItem('password', password)
        } else {
          localStorage.setItem('remembered', 'false')
        }

        localStorage.setItem('checkstatus', res.statusCode)
        localStorage.setItem('isLoggedIn', 'true')

        setTokens(res.data)
        setMessage('Login successful!')
        setUsersData(res.data)

        window.location.href = portals.main
      } else {
        localStorage.setItem('remembered', 'false')
        openNotification('top')
      }
    } catch (error) {
      if (email != '' && password != '') {
        openNotification('top')
      }
    }
  }

  const onChange = (e) => {
    console.log('checked = ', e.target.checked)
    setChecked(e.target.checked)
  }
  const forgetPage = () => {
    window.location.href = '/ForgotPassword'
  }
  const [form] = Form.useForm()

  return (
    <>
      {contextHolder}
      <Content style={{ padding: '0px' }}>
        <Row align="middle">
          <Col style={contentStyle} lg={13}>
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-around',
                height: '100%'
              }}>
              <Lottie
                animationData={vru}
                loop={true}
                style={{
                  overflow: 'auto',
                  // maxHeight: '200' ,
                  padding: '50px 90px 0px 0px',
                  width: '130%',
                  position: 'absolute'
                }}
              />
            </div>

            <div
              style={{
                justifyContent: 'end',
                background: '#33B991',
                with: '100%',
                height: '20vh'
              }}></div>
          </Col>
          <Col span={1} pull={1}>
            <div
              style={{
                justifyContent: 'end',
                paddingTop: '170px'
              }}>
              <img src={shape} style={{ with: '100%' }}></img>
            </div>
          </Col>
          <Col lg={10} md={25} sm={23} xs={22}>
            <Content style={{ padding: '100px 0px 0px 0px', alignItems: 'center' }}>
              <Col lg={22} md={25} sm={23} xs={22}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <img style={{ width: '55%', maxWidth: '300px' }} src={logo} />
                </div>
              </Col>
              <Row justify="center">
                <Col lg={19} md={16} sm={16} xs={16}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%'
                    }}>
                    <Text
                      style={{
                        fontFamily: 'Kanit',
                        fontSize: '30px',
                        color: '#0B6252',
                        padding: '10px'
                      }}>
                      เข้าสู่ระบบ
                    </Text>
                  </div>
                </Col>
                <Col lg={19} md={16} sm={16} xs={16}>
                  <Form form={form}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณากรอกอีเมล!'
                        },
                        { type: 'email' }
                      ]}>
                      <Input
                        style={{
                          borderRadius: '40px',
                          borderColor: 'transparent',
                          fontFamily: 'Kanit',
                          backgroundColor: '#F3FBF6'
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        shape="round"
                        placeholder="อีเมล"
                        prefix={<UserOutlined />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}>
                      <Input.Password
                        className="inputtxt"
                        style={{
                          borderRadius: '40px',
                          borderColor: 'transparent',
                          fontFamily: 'Kanit',
                          backgroundColor: '#F3FBF6'
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        shape="round"
                        placeholder="******"
                        suffix={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        prefix={<UnlockOutlined />}
                      />
                    </Form.Item>
                    <Row justify="space-around">
                      <Col xxl={16} xl={15} lg={14} md={13} sm={12} xs={12}>
                        <Form.Item>
                          <Checkbox
                            style={{ fontFamily: 'Kanit' }}
                            checked={checked}
                            onChange={onChange}>
                            จดจำรหัสผ่าน
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Typography.Link
                            onClick={forgetPage}
                            style={{ color: '#6BB673', fontFamily: 'Kanit' }}>
                            ลืมรหัสผ่าน
                          </Typography.Link>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row justify="space-around">
                      <Col lg={19} md={16} sm={16} xs={16}>
                        <Form.Item>
                          <Button
                            onClick={handleLogin}
                            type="submit"
                            htmlType="submit"
                            style={{
                              fontFamily: 'kanit',
                              width: '100%',
                              background:
                                'linear-gradient(to right, rgba(50,157,156),rgba(86,197,150),rgba(123,228,150)',
                              color: 'white'
                            }}
                            size="large"
                            shape="round"
                            placeholder="******"
                            prefix={<UnlockOutlined />}
                            // onClick={() => {
                            //   window.location.href = "/MainPage"
                            // }}
                          >
                            ลงชื่อเข้าใช้
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default Login

import { Alert, Form, Select } from 'antd'
import { useAuth } from '../../AuthorizationProvider'

export default function SubExamCategorySelectorComp({
  subCategory = [],
  handleSubExamCatChange = () => {},
  noRules,
  view,
  disabled,
  style = {}
}) {
  const form = Form.useFormInstance()
  const { currentUserCan } = useAuth()

  return currentUserCan(['list_sub_exam_category']) ? (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const mainExamValue = form.getFieldValue('mainExamCategoryId')
        return (
          <Form.Item
            name="subExamCategoryId"
            {...(!noRules && {
              rules: [
                {
                  required: true,
                  message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบย่อย!'
                }
              ]
            })}
            label="เลือกชื่อหมวดหมู่ข้อสอบย่อย :"
            style={style}>
            <Select
              onChange={(id) => {
                handleSubExamCatChange(id)
                form.resetFields(['subExamCategoryLevel1Id'])
              }}
              placeholder="เลือกหมวดหมู่ย่อย"
              options={subCategory?.map((sexam) => ({
                label: sexam.subExamCategoryName,
                value: sexam.id
              }))}
              disabled={!mainExamValue || view || disabled}
              allowClear={true}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  ) : (
    <Alert message="กรุณาติดต่อแอดมิน" type="warning" />
  )
}

import { React, useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import { Layout, Row, Col, Input, Form, Button, Typography, Space, message, Card } from 'antd'
import LayoutPage from '../components/Layout'
import logo from '../Image/logo.png'
import logoForget from '../Image/logoforget.png'
import { Header } from 'antd/es/layout/layout'
import env from '../lib/environments'
import { fetchJson } from '../lib/fethcer'

const { Text } = Typography

const { Content } = Layout

const ForgotPassPage = () => {
  const contentStyle = {
    backgroundColor: '#F1FFF5',
    height: '100vh',
    with: '100%'
  }
  const [messageApi, contextHolder] = message.useMessage()
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'ยืนยันการส่งคำร้องขอการเปลี่ยนรหัสผ่านแล้วที่อีเมลของคุณ',
      duration: 5
    })
  }
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'อีเมลไม่ถูกต้อง',
      duration: 5
    })
  }
  const loginPage = () => {
    window.location.href = '/'
  }
  const [email, setEmail] = useState()
  const onFinish = async () => {
    try {
      const res = await fetchJson(env.forgotApi, {
        method: 'POST',
        body: JSON.stringify({
          email
        })
      })
      if (res.success) {
        success()
      } else {
        error()
      }
    } catch {
      error()
    }
  }
  const [form] = useForm()
  //const [form] = Form.useForm();
  return (
    <>
      {contextHolder}
      <Header
        style={{
          height: '80px',
          padding: '0px 0px 0px 0px',
          alignItems: 'center',
          background:
            'linear-gradient(to right, rgba(50,157,156),rgba(86,197,150),rgba(123,228,150))'
        }}>
        <Row justify="space-between" style={{ lineHeight: 0, padding: '0 20px' }}>
          <Col>
            <Space style={{ alignItems: 'center', lineHeight: 0 }}>
              <img src={logo} style={{ maxWidth: '45px', padding: '10px 0px 0px' }} />
              <div>
                <Text style={{ display: 'block', fontFamily: 'KanitM', color: '#fff' }}>
                  ระบบคลังข้อสอบ
                </Text>
                <Text style={{ fontFamily: 'Kanit', color: '#fff' }}>
                  มหาวิทยาลัยราชภัฏวไลยอลงกรณ์
                </Text>
              </div>
            </Space>
          </Col>
          <Col>
            <Space align="center" style={{ height: '100%', alignItems: 'center' }}>
              <Typography.Link style={{ color: '#fff', fontFamily: 'KanitM' }} onClick={loginPage}>
                เข้าสู่ระบบ
              </Typography.Link>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content style={contentStyle}>
        <Card style={{ height: '90vh' }}>
          <Row justify="center">
            <Col>
              <img src={logoForget} style={{ maxWidth: '300px', padding: '10px 0px 0px' }}></img>
            </Col>
          </Row>
          <Row justify="center">
            <Text
              style={{
                fontFamily: 'KanitM',
                color: '#0B6252',
                fontSize: '25px'
              }}>
              ลืมรหัสผ่าน
            </Text>
          </Row>
          <Row justify="center">
            <Text>รีเซ็ตรหัสผ่านด้วยอีเมลที่ใช้งาน</Text>
          </Row>
          <Row justify="center">
            <Col style={{ paddingTop: '20px' }}>
              <Text>ระบบจะทำการส่งคำร้องขอในการเปลี่ยนรหัสผ่านเข้าอีเมลของคุณ</Text>
            </Col>
          </Row>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row justify="center">
              <Col style={{ paddingTop: '20px' }} span={9}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกอีเมล!'
                    }
                  ]}>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    shape="round"
                    style={{
                      height: '45px',
                      borderRadius: '40px',
                      fontFamily: 'Kanit'
                    }}
                    placeholder="กรอกอีเมล"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ paddingTop: '20px' }}>
                <Button
                  htmlType="submit"
                  style={{
                    width: '300px',
                    height: '45px',
                    background:
                      'linear-gradient(to right, rgba(50,157,156),rgba(86,197,150),rgba(123,228,150)',
                    color: 'white'
                  }}
                  shape="round">
                  เปลี่ยนรหัสผ่าน
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Content>
    </>
  )
}

export default ForgotPassPage

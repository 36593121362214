import { useEffect, useState } from 'react'

export default function useDataTable(request, query = {}) {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10
    }
  })

  const onFilter = (values) => {
    setTableParams((prev) => ({ ...prev, where: values }))
  }

  // const onFilter = ({ filters }) => {
  //   if (filters !== undefined || !filters?.trim())
  //     setTableParams({ ...tableParams, filters });
  // };

  const mutateData = async (queryByMutation = {}) => {
    // const qs = convertTableParamsToQS(tableParams);

    setLoading(true)
    try {
      let res = await request({ ...query, ...queryByMutation, ...tableParams })

      setData(
        res.data.items.map((item, key) => ({
          ...item,
          seq: key + 1
        }))
      )

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.totalItems
        }
      })
    } catch (error) {
      // console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter
    })

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }

  useEffect(() => {
    mutateData()
  }, [JSON.stringify(tableParams)])

  return {
    data,
    loading,
    tableParams,
    handleTableChange,
    mutateData,
    onFilter,
    setTableParams
  }
}

import React from 'react'
import { Form, Radio } from 'antd'
/**
 * @param {object} props
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {boolean} props.disabled
 */
const Random = ({ disabled }) => {
  return (
    <Form.Item name="isRandom" initialValue={true}>
      <Radio.Group disabled={disabled}>
        <Radio value={true}>สุ่มข้อสอบ</Radio>
        <Radio value={false}>เลือกข้อสอบ</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

export default Random

import { Alert, Form, Select } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useAuth } from '../../AuthorizationProvider'

export default function MainExamCategorySelector({
  mainCategory = [],
  handleMainExamCatChange = () => {},
  noRules,
  view,
  disabled,
  style = {}
}) {
  const form = useFormInstance()
  const { currentUserCan } = useAuth()

  return currentUserCan(['list_main_exam_category']) ? (
    <Form.Item
      name="mainExamCategoryId"
      label="เลือกชื่อหมวดหมู่ข้อสอบหลัก :"
      style={style}
      {...(!noRules && {
        rules: [
          {
            required: true,
            message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบหลัก!'
          }
        ]
      })}>
      <Select
        onChange={(id) => {
          handleMainExamCatChange(id)
          form.resetFields([
            'subExamCategoryId',
            'subExamCategoryLevel1Id',
            'subExamCategoryLevel2Id',
            'subExamCategoryLevel3Id',
            'examType'
          ])
        }}
        placeholder="เลือกหมวดหมู่หลัก"
        disabled={view || disabled}
        options={mainCategory?.map((exam) => ({
          label: exam.mainExamCategoryName,
          value: exam.id
        }))}
        allowClear={true}
      />
    </Form.Item>
  ) : (
    <Alert message="กรุณาติดต่อแอดมิน" type="warning" />
  )
}

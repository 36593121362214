/**
 *
 * @param {string} str
 */
export const stripHTMLTags = (str) => str.replace(/(<([^>]+)>)/gi, '')

export const removePTag = (str) => str.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '')

export const removeClozeTag = (str) => str.replace(/<\/?(p|span|div)\b[^>]*>/g, '')

export function debounce(func, timeout = 500) {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date()
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export const getDraftExam = (draftId) => JSON.parse(localStorage.getItem(`draft-exam-${draftId}`))

export const setDraftExam = (draftId, values) => {
  if (draftId && values) localStorage.setItem(`draft-exam-${draftId}`, JSON.stringify(values))
}

export const deleteDraftExam = (draftId) => {
  if (draftId) localStorage.removeItem(`draft-exam-${draftId}`)
}

export const setTheDefaultDimensionsForImage = (content) => {
  const div = document.createElement('div')
  div.innerHTML = content
  const elements = Array.from(div.getElementsByTagName('img'))

  if (elements.length) {
    elements.forEach((ele) => {
      if (!ele.hasAttribute('width')) {
        const width = ele.naturalWidth
        const height = ele.naturalHeight

        if (width > height) {
          ele.setAttribute('width', Math.floor((width / height) * 200))
          ele.setAttribute('height', 200)
        } else if (height > width) {
          ele.setAttribute('width', 200)
          ele.setAttribute('height', Math.floor((height / width) * 200))
        } else {
          ele.setAttribute('width', 200)
          ele.setAttribute('height', 200)
        }
      }
    })

    return div.outerHTML
  } else {
    return content
  }
}

export const compareDelete = (body, record, type) => {
  if (type === 4 || type === 5) {
    const bodyArr = []
    const recordArr = []
    body.map((values) => values.childExam.map((value) => bodyArr.push(value)))
    record.map((values) => values.childExam.map((value) => recordArr.push(value)))
    return recordArr.filter((value1) => !bodyArr.some((value2) => value1.id === value2.id))
  } else {
    return record.filter((value1) => !body.some((value2) => value1.id === value2.id))
  }
}

import { Typography, theme } from 'antd'
import LayoutPage from '../../components/Layout'
import ReportOnNumberOfExams from './ReportOnNumberOfExams'
import ReportOnReuseOfExams from './ReportOnReuseOfExams'
const { Title } = Typography
const { useToken } = theme

export default function ReportPage() {
  const { token } = useToken()
  return (
    <LayoutPage>
      <Title
        level={2}
        style={{
          color: token.colorPrimary
        }}>
        รายงาน
      </Title>
      <ReportOnNumberOfExams />
      <ReportOnReuseOfExams />
    </LayoutPage>
  )
}

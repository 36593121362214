import { Form, Select } from 'antd'

export default function ExamLevelSelectorComp({ disabled, examLevels = [], style = {} }) {
  return (
    <Form.Item name="examLevelId" label="ระดับข้อสอบ:" style={style}>
      <Select
        placeholder="เลือกระดับข้อสอบ"
        options={examLevels?.map((examl) => ({
          label: examl.examLevelName,
          value: examl.id
        }))}
        disabled={disabled}
        allowClear={true}
      />
    </Form.Item>
  )
}

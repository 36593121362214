import _ from 'lodash'

export default class ExamTypeUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfExamType = 'examType'
    this.pathOfExamType = [this.parent.indexOfItem, this.nameOfExamType]
    this.fullPathOfExamType = [...this.parent.pathOfItem, this.nameOfExamType]
  }

  getExamTypeValue = () => this.parent.form.getFieldValue(this.fullPathOfExamType)

  getExamTypesValue = () => {
    return this.parent.form
      .getFieldValue(this.parent.nameOfList)
      .filter((item) => item?.examType)
      .map((item) => item?.examType)
  }

  getAvailableExamTypesValue = () => {
    return _(this.parent.examinerUtils.filterExaminersByCatsId())
      .map((exam) => exam.examType)
      .compact()
      .uniq()
      .difference(this.getExamTypesValue())
      .value()
  }

  getExamTypeOptions = () => {
    return _(this.parent.examinerUtils.filterExaminersByCatsId())
      .uniqBy(this.nameOfExamType)
      .map((exam) => ({
        label: exam.examTypeName,
        value: exam.examType
      }))
      .value()
  }
}

import { apiBaseUrl, examApi } from '../lib/api'
import env from '../lib/environments'
import { fetchJsonWithToken, fetchWithToken } from '../lib/fethcer'
import qs from 'qs'

/**
 * @param {*} query
 * @returns {Promise<import('../types/response.types').TResponse<any>>}
 */
export const listExam = (query) => fetchJsonWithToken(`${env.examApi}?${qs.stringify(query)}`)

export const createExam = (values) =>
  fetchJsonWithToken(env.examApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })

export const updateExam = (id, values) =>
  fetchJsonWithToken(`${env.examApi}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })

export const activateExam = (id) =>
  fetchJsonWithToken(examApi.activate(id), {
    method: 'PUT'
  })

export const deactivateExam = (id) =>
  fetchJsonWithToken(examApi.deactivate(id), {
    method: 'PUT'
  })

export const deleteExam = (id) =>
  fetchJsonWithToken(`${env.examApi}/${id}/delete`, {
    method: 'DELETE'
  })
export const mediaExam = (values) =>
  fetchWithToken(`${apiBaseUrl}/media/file/upload`, {
    method: 'POST',
    body: values
  })
export const getmediaExam = (id) =>
  fetchJsonWithToken(`${apiBaseUrl}/media/file/${id}/view`, {
    method: 'GET'
  })

export const getExamById = (id) => fetchJsonWithToken(`${env.examApi}/${id}`)

/**
 * @returns {Promise<import('../types/response').TResponse<any>>}
 */
export const listMonths = () => fetchJsonWithToken(examApi.listMonths())

/**
 * @returns {Promise<import('../types/response').TResponse<any>>}
 */
export const getNumberOfExams = (query) =>
  fetchJsonWithToken(`${examApi.count()}?${qs.stringify(query)}`)

/**
 * @returns {Promise<import('../types/response.types').TResponse<any>>}
 */
export const getReuseOfExams = (query) =>
  fetchJsonWithToken(`${examApi.reuse()}?${qs.stringify(query)}`)

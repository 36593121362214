import { useState } from 'react'
import withHook from '../../lib/withHook'
import { Button, Col, Row, Typography } from 'antd'
import { EyeFilled } from '@ant-design/icons'
import { ExaminerApproveID } from '../../data/examiner.data'
import ConfirmationApprove from '../../components/ConfirmationApprove'
import ConfirmationSuccess from '../../components/ConfirmationSuccess'
import env from '../../lib/environments'

const { Text } = Typography

export const createColumnsType = (mutateData, currentUserCan) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    align: 'center'
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'mainExamCategoryName',
    sort: 'asc'
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบย่อย',
    dataIndex: 'subExamCategoryName',
    sort: 'asc'
  },

  {
    title: 'ผู้ออกข้อสอบ',
    dataIndex: 'createdBy'
  },

  {
    title: 'ชื่อชุด',
    dataIndex: 'label'
  },

  {
    title: 'ประเภทชุดข้อสอบ',
    dataIndex: ['examTypes'],
    render: (examTypes) => (
      <>
        <Text type="secondary">รวมประเภททั้งหมด</Text>
        {examTypes.map((et, key) => (
          <Text key={key} style={{ display: 'block' }}>
            {et.examTypeName}
          </Text>
        ))}
      </>
    )
  },

  {
    title: 'จำนวนข้อสอบ',
    children: [
      {
        title: 'จำนวนข้อที่ต้องการ',
        dataIndex: 'examTypes',
        align: 'center',
        render: (examTypes, record) => (
          <>
            <Text type="success">{record.numberOfExamsRequired}</Text>
            {examTypes.map((et, key) => (
              <Text key={key} style={{ display: 'block' }}>
                {et.numberOfExamsRequired}
              </Text>
            ))}
          </>
        )
      },
      {
        title: 'จำนวนข้อทั้งหมด',
        dataIndex: 'examTypes',
        align: 'center',
        render: (examTypes, record) => (
          <>
            <Text type="secondary">{record.numberOfExamsTotal}</Text>
            {examTypes.map((et, key) => (
              <Text key={key} style={{ display: 'block' }}>
                {et.numberOfExamsTotal}
              </Text>
            ))}
          </>
        )
      }
    ]
  },

  {
    title: 'จำนวนชุดข้อสอบ',
    dataIndex: 'numberOfSets',
    align: 'center'
  },

  {
    title: 'ดูรายละเอียด',
    dataIndex: 'storeQuestionId',
    responsive: ['sm'],
    align: 'center',
    render: (storeQuestionId) => (
      <Button
        href={`${env.questionStorageApi}/${storeQuestionId}/pdf/view`}
        className="btnview"
        icon={<EyeFilled />}
      />
    )
  },

  {
    title: 'สถานะ',
    dataIndex: 'id',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) =>
      withHook(() => {
        const [approveModal, setApproveModal] = useState(false)
        const [deleteModal, setDeleteModal] = useState(false)

        return (
          <Row gutter={[10, 0]} justify="center">
            <>
              <Col>
                <Button
                  className="btnedit"
                  onClick={() => setApproveModal(true)}
                  {...((record.approve || !currentUserCan(['update_approve_exam'])) && {
                    disabled: true
                  })}>
                  อนุมัติ
                </Button>
                <ConfirmationSuccess
                  open={approveModal}
                  callback={async () => {
                    await ExaminerApproveID(record.id, { approve: true, remark: record.remark })
                    mutateData()
                  }}
                  fallback={() => {
                    setApproveModal(false)
                  }}
                />
              </Col>
              <Col>
                <Button
                  className="btndelete"
                  onClick={() => setDeleteModal(true)}
                  {...((record.approve || !currentUserCan(['update_approve_exam'])) && {
                    disabled: true
                  })}>
                  ไม่อนุมัติ
                </Button>
                <ConfirmationApprove
                  open={deleteModal}
                  useRemark
                  callback={async (remark) => {
                    await ExaminerApproveID(record.id, {
                      approve: false,
                      remark: remark
                    })
                    mutateData()
                  }}
                  fallback={() => {
                    setDeleteModal(false)
                  }}
                />
              </Col>
            </>
          </Row>
        )
      })
  }
]

import { useState } from 'react'
import withHook from '../withHook'
import { Button, Col, Dropdown, Row, Space, Typography } from 'antd'
import { ExaminerStore, deleteExamStorage, deleteExaminer } from '../../data/examiner.data'
import { CheckCircleFilled, DeleteFilled, EditFilled, EyeFilled } from '@ant-design/icons'
import ConfirmationDelete from '../../components/ConfirmDelete'
import ConfirmationApprove from '../../components/Confirmation'
// import getColumnSearchProps from './columnSearch'
import portals from '../portals'
import { useAuth } from '../../AuthorizationProvider'
import { Link } from 'react-router-dom'
const { Text } = Typography
const { examPublisher } = portals

export const createColumnsTypeInExaminerList = (mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    align: 'center'
  },
  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'mainExamCategoryName',
    filterMode: 'menu',
    filterSearch: true,
    // ...getColumnSearchProps('mainExamCategoryName'),
    render: (mainExamCategoryName) => mainExamCategoryName
  },

  {
    // ...getColumnSearchProps('subExamCategoryName'),
    title: 'ชื่อหมวดหมู่ข้อสอบย่อย',
    dataIndex: 'subExamCategoryName',
    render: (subExamCategoryName) => subExamCategoryName
  },

  {
    title: 'ชื่อชุด',
    dataIndex: 'label'
  },

  {
    title: 'ประเภทชุดข้อสอบ',
    dataIndex: ['examTypes'],
    render: (examTypes) => (
      <>
        <Text type="secondary">รวมประเภททั้งหมด</Text>
        {examTypes.map((et, key) => (
          <Text key={key} style={{ display: 'block' }}>
            {et.examTypeName}
          </Text>
        ))}
      </>
    )
  },

  {
    title: 'จำนวนข้อสอบ',
    children: [
      {
        title: 'จำนวนข้อที่ต้องการ',
        dataIndex: 'examTypes',
        align: 'center',
        render: (examTypes, record) => (
          <>
            <Text type="success">{record.numberOfExamsRequired}</Text>
            {examTypes.map((et, key) => (
              <Text key={key} style={{ display: 'block' }}>
                {et.numberOfExamsRequired}
              </Text>
            ))}
          </>
        )
      },
      {
        title: 'จำนวนข้อทั้งหมด',
        dataIndex: 'examTypes',
        align: 'center',
        render: (examTypes, record) => (
          <>
            <Text type="secondary">{record.numberOfExamsTotal}</Text>
            {examTypes.map((et, key) => (
              <Text key={key} style={{ display: 'block' }}>
                {et.numberOfExamsTotal}
              </Text>
            ))}
          </>
        )
      }
    ]
  },

  {
    title: 'จำนวนชุดข้อสอบ',
    dataIndex: 'numberOfSets',
    align: 'center',
    render: (numberOfSets) => numberOfSets
  },
  {
    title: 'ส่งเข้าคลังข้อสอบ',
    dataIndex: 'storeExam',
    align: 'center',
    render: (_, record) => <ExamCreateButton record={record} mutateData={mutateData} />
  },
  {
    title: 'จัดการ',
    dataIndex: 'manage',
    align: 'center',
    render: (_, record) =>
      withHook(() => {
        const [deleteModal, setDeleteModal] = useState(false)
        const { currentUserCan } = useAuth()
        return (
          <Row gutter={[10, 0]} justify="center">
            <Col>
              <Button
                disabled={!currentUserCan(['get_exam_examiner'])}
                className="btnview"
                href={examPublisher.view(record.id)}
                icon={<EyeFilled />}
              />
            </Col>
            <Col>
              <Link to={examPublisher.update(record.id)}>
                <Button type="default" icon={<EditFilled />} />
              </Link>
            </Col>

            <Col>
              {!record.storeExam ? (
                <Button
                  disabled={!currentUserCan(['delete_exam_examiner'])}
                  icon={<DeleteFilled />}
                  onClick={() => setDeleteModal(true)}
                  danger
                />
              ) : (
                <Button icon={<DeleteFilled />} disabled danger />
              )}
              <ConfirmationDelete
                open={deleteModal}
                callback={async () => {
                  try {
                    await deleteExaminer(record.id)
                    mutateData()
                  } catch (error) {
                    console.log(error)
                  }
                }}
                fallback={() => {
                  setDeleteModal(false)
                }}
              />
            </Col>
          </Row>
        )
      })
  }
]

const ExamCreateButton = ({ record, mutateData }) => {
  const [rejectModal, setRejectModal] = useState(false)
  if (!record) return
  if (!record.storeExam) {
    return (
      <>
        <Button
          shape="round"
          record={record}
          className="btnBGreen"
          onClick={() => {
            ExaminerStore(record.id)
              .then(() => {
                mutateData()
              })
              .catch((error) => console.log(error))
          }}>
          ส่งเข้าคลังข้อสอบ
        </Button>
      </>
    )
  } else {
    if (typeof record.approve === 'boolean') {
      if (record.approve) {
        return (
          <Space>
            <CheckCircleFilled
              style={{
                color: '#52C41A'
              }}
            />
            <Text style={{ color: '#52C41A' }}> ส่งเรียบร้อย</Text>
          </Space>
        )
      } else {
        return (
          <Row justify="center">
            <Button
              record={record}
              onClick={() => setRejectModal(true)}
              style={{ color: 'red', borderColor: 'red' }}
              shape="round">
              ไม่ผ่านการอนุมัติ
            </Button>
            <ConfirmationApprove
              open={rejectModal}
              useRemark
              record={record}
              callback={async () => {
                await deleteExamStorage(record.id)
                mutateData()
                setRejectModal(false)
              }}
              fallback={() => {
                setRejectModal(false)
              }}
            />
          </Row>
        )
      }
    } else {
      return <Text style={{ color: '#FA9E15' }}> รอการอนุมัติข้อสอบ</Text>
    }
  }
}

import { React, useEffect, useState } from 'react'
import { Button, Form, Input, Select, Space, Switch } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import { updateSubExam, createSubExam } from '../data/subexam.data'
import { listMainExam } from '../data/mainexam.data'

const layout = {
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 16
  }
}
export default function SubExamForm({
  view = false,
  record,
  handleCancel = () => {},
  mutateData = () => {}
}) {
  const [confirm, setConfirm] = useState(false)
  const [mainCate, setMaincat] = useState([])
  const [form] = useForm()

  const handleCreate = async (values = {}) => {
    try {
      await createSubExam(values)
      mutateData()
      handleCancel(false)
    } catch (error) {
      alert('Error : ชื่อหมวดหมู่มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }

  const handleUpdate = async (values) => {
    try {
      if (
        record?.subExamCategoryLevel1Id.length === 0 ||
        values.subExamCategoryName !== record.subExamCategoryName ||
        values.active !== record.active
      ) {
        await updateSubExam(record.id, values)
        mutateData()
      } else {
        console.log('No Update')
      }
    } catch (error) {
      console.error('Error updating data:', error)
      alert('Error : ชื่อหมวดหมู่มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }

  const onFinish = () => {
    const values = form.getFieldsValue()
    if (!record) handleCreate({ ...values, active: Boolean(values.active) })
    else handleUpdate({ ...values, active: Boolean(values.active) })
  }

  useEffect(() => {
    listMainExam()
      .then((res) => {
        setMaincat(res.data.items.filter((item) => item.active))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <Form
      form={form}
      onFinish={() => setConfirm(true)}
      {...layout}
      initialValues={{
        mainExamCategoryId: record?.mainExamCategory.id,
        subExamCategoryName: record?.subExamCategoryName,
        active: record?.active
      }}>
      <Form.Item
        name="mainExamCategoryId"
        label="ชื่อหมวดหมู่ข้อสอบหลัก:"
        rules={[
          {
            required: true,
            message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบหลัก!'
          }
        ]}>
        <Select
          className="inputtxt2"
          placeholder="กรอกชื่อหมวดหมู่ข้อสอบหลัก"
          disabled={view}
          options={mainCate.map((cate) => ({ label: cate.mainExamCategoryName, value: cate.id }))}
        />
      </Form.Item>
      <Form.Item
        name="subExamCategoryName"
        label="ชื่อหมวดหมู่ข้อสอบย่อย:"
        rules={[
          {
            required: true,
            message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบย่อย!'
          }
        ]}>
        <Input className="inputtxt2" placeholder="กรอกชื่อหมวดหมู่ข้อสอบย่อย" disabled={view} />
      </Form.Item>
      <Form.Item name="active" label="สถานะเปิด/ปิดใช้งาน" valuePropName="checked">
        <Switch onChange={handleChange} checkedChildren="on" unCheckedChildren="off"></Switch>
      </Form.Item>

      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          shape="round"
          className="btnBGreen"
          onClick={() => {
            form.resetFields()
            handleCancel()
          }}>
          ยกเลิก
        </Button>

        <Button className="btnGreen" shape="round" type="primary" htmlType="submit">
          บันทึกข้อมูล
        </Button>
      </Space>
      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
        }}
        fallback={() => {
          setConfirm(false)
        }}
      />
    </Form>
  )
}

const options = []
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i
  })
}
const handleChange = (value) => {
  console.log(`selected ${value}`)
}

import useSWR, { SWRConfig } from 'swr'
import { meApi } from './lib/api'
import portals from './lib/portals'
import { createContext, useContext } from 'react'
import withHook from './lib/withHook'
import _ from 'lodash'
import { fetchJsonWithToken } from './lib/fethcer'

/**
 * @typedef ResourcesType
 * @type {(
 * 'list_log'|
 * 'get_log' |
 * 'list_exam'|
 * 'get_exam'|
 * 'update_exam'|
 * 'delete_exam'|
 * 'create_roles'|
 * 'list_roles'|
 *  'get_roles'|
 * 'update_roles'|
 * 'delete_roles'|
 * 'list_users' |
 * 'get_users'|
 * 'create_users'|
 * 'update_users'|
 * 'update_users_password'|
 * 'delete_users'|
 * 'create_approve_exam'|
 * 'list_approve_exam'|
 * 'get_approve_exam'|
 * 'update_approve_exam'|
 * 'delete_approve_exam'|
 * 'create_branches'|
 * 'list_branches'|
 * 'get_branches'|
 * 'update_branches'|
 * 'delete_branches'|
 * 'create_faculties'|
 * 'list_faculties'|
 * 'get_faculties'|
 * 'update_faculties'|
 * 'delete_faculties'|
 * 'create_exam_level'|
 * 'list_exam_level'|
 * 'get_exam_level'|
 * 'update_exam_level'|
 * 'delete_exam_level'|
 * 'create_exam_choice'|
 * 'list_exam_choice'|
 * 'get_exam_choice'|
 * 'update_exam_choice'|
 * 'delete_exam_choice'|
 * 'create_exam_lecture'|
 * 'list_exam_lecture'|
 * 'get_exam_lecture'|
 * 'update_exam_lecture'|
 * 'delete_exam_lecture'|
 * 'create_sub_exam_category'|
 * 'list_sub_exam_category'|
 * 'get_sub_exam_category'|
 * 'update_sub_exam_category'|
 * 'delete_sub_exam_category'|
 * 'create_main_exam_category'|
 * 'list_main_exam_category'|
 * 'get_main_exam_category'|
 * 'update_main_exam_category'|
 * 'delete_main_exam_category'|
 * 'create_sub_exam_category_level1'|
 * 'create_sub_exam_category_level2'|
 * 'create_sub_exam_category_level3'|
 * 'list_sub_exam_category_level1'|
 * 'list_sub_exam_category_level2'|
 * 'list_sub_exam_category_level3'|
 * 'get_sub_exam_category_level1'|
 * 'get_sub_exam_category_level2'|
 * 'get_sub_exam_category_level3'|
 * 'update_sub_exam_category_level1'|
 * 'update_sub_exam_category_level2'|
 * 'update_sub_exam_category_level3'|
 * 'delete_sub_exam_category_level1'|
 * 'delete_sub_exam_category_level2'|
 * 'delete_sub_exam_category_level3'|
 * 'create_exam_examiner'|
 * 'list_exam_examiner'|
 * 'get_exam_examiner'|
 * 'update_exam_examiner'|
 * 'delete_exam_examiner'|
 * 'list_approve_view'|
 * 'list_preview_exam_examiner'|
 * 'list_view_exam_examiner' |
 * 'get_exam_report'
 * )[]}
 */

export const AuthContext = createContext(null)

/**
 * @returns {{
 *  usePageAuth: (srcs: ResourcesType) => {},
 *  currentUserCan: (srcs: ResourcesType) => {}
 * }}
 */
export const useAuth = () => useContext(AuthContext)

export const publicPage = ['/', '/ForgotPassword', '/ResetPassWord']

export const getPathname = () => window.location.pathname

export const AuthorizationProvider = ({ children }) => {
  const pathname = getPathname()

  const onError = () => {
    if (!(publicPage.indexOf(pathname) + 1)) window.location.href = portals.base
  }

  const onSuccess = () => {
    if (pathname === '/') window.location.href = portals.main
  }

  return (
    <SWRConfig value={{ fetcher: fetchJsonWithToken, onError, onSuccess }}>
      {withHook(() => {
        const { data, isLoading } = useSWR(meApi)
        const me = data?.data

        /**
         * @param {ResourcesType} srcs
         */
        const currentUserCan = (srcs = []) => {
          if (!me?.resources?.length) return false
          if (!srcs.length) return true

          return !!_.intersection(me.resources, srcs).length
        }

        /**
         * @param {ResourcesType} srcs
         */
        const usePageAuth = (srcs = []) => {
          if (me && !currentUserCan(srcs)) window.location.href = portals.main
        }

        if (publicPage.indexOf(pathname) + 1) return children
        else
          return (
            !isLoading && (
              <AuthContext.Provider value={{ me, currentUserCan, usePageAuth }}>
                {children}
              </AuthContext.Provider>
            )
          )
      })}
    </SWRConfig>
  )
}

import { Form, Select } from 'antd'

export default function ExamTypeSelector({
  handleSubExamLevel2CatChange = () => {},
  view,
  disabled,
  style = {}
}) {
  const form = Form.useFormInstance()

  return (
    <Form.Item shouldUpdate noStyle>
      {() => {
        const subExamCategoryLevel1Id = form.getFieldValue('subExamCategoryLevel1Id')

        return (
          <Form.Item
            name="examType"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบย่อย!'
              }
            ]}
            label="เลือกประเภทชุดข้อสอบ :"
            style={style}>
            <Select
              onChange={(id) => {
                handleSubExamLevel2CatChange(id)
                // form.resetFields(['subExamCategoryLevel2Id'])
              }}
              placeholder="เลือกประเภทชุดข้อสอบ "
              options={EXAM_TYPES.map((type) => ({ ...type, label: type.name }))}
              disabled={!subExamCategoryLevel1Id || view || disabled}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

export const EXAM_TYPES = [
  { value: 1, name: 'ประเภทชุดข้อสอบคำตอบเดียว (ปรนัย)' },
  { value: 2, name: 'ประเภทชุดข้อสอบบรรยาย (อัตนัย)' },
  { value: 3, name: 'ประเภทชุดข้อสอบจับคู่' },
  { value: 4, name: 'ประเภทชุดข้อสอบ Cloze passage' },
  { value: 5, name: 'ประเภทชุดข้อสอบคำตอบเดียว (มีข้อย่อย)' }
]

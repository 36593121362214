export class RandomUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    /**
     * @type {this & Utils}
     */
    this.parent = parent
    this.name = 'isRandom'
    this.namePath = [this.parent.indexOfItem, this.nameOfIsRandom]
    this.fullNamePath = [...this.parent.pathOfItem, this.nameOfIsRandom]
  }

  getIsRandomValue = () => this.parent.form.getFieldValue(this.fullNamePath)
}

import React from 'react'
import { Layout } from 'antd'
import HeaderLayout from './Header'
import SiderLayout from './Sider'
import { Content } from 'antd/es/layout/layout'

const LayoutPage = ({ children }) => {
  return (
    <>
      <HeaderLayout />
      <Layout hasSider>
        <SiderLayout />
        <Content style={{ minHeight: '100vh', padding: '30px 50px 0px 30px' }}>{children}</Content>
      </Layout>
    </>
  )
}
export default LayoutPage

import { useEffect, useState } from 'react'
import { listMainExamCat, listUsedMainExamCategory } from '../../data/category'
import MainExamCategorySelectorComp from './MainExamCategorySelectorComp'
import SubExamCategorySelectorComp from './SubExamCategorySelectorComp'
import SectionSelectorComp from './SectionSelectorComp'
import PartSelectorComp from './PartSelectorComp'
import ExamTypeSelectorComp from './ExamTypeSelectorComp'
import { listExamlevel } from '../../data/examlevel.data'
import SubSectionSelectorComp from './SubSectionSelectorComp'
import ExamLevelSelectorComp from './ExamLevelSelectorComp'

/**
 * @typedef SelectorProps
 * @type {object}
 * @property {boolean} noRules
 * @property {boolean} disabled
 * @property {React.CSSProperties} style
 * @property {() => void} [handleChange]
 */

/**
 * @param {object} params
 * @param {number} params.mainExamCategoryId
 * @param {number} params.subExamCategoryId
 * @param {number} params.subExamCategoryLevel1Id
 * @param {number} params.subExamCategoryLevel2Id
 * @param {number} params.subExamCategoryLevel3Id
 * @param {boolean} [params.used]
 * @returns {{
 *  MainExamCategorySelector: (props: SelectorProps) => React.JSX.Element
 *  SubExamCategorySelector: (props: SelectorProps) => React.JSX.Element
 *  SectionSelector: (props: SelectorProps) => React.JSX.Element
 *  PartSelector: (props: SelectorProps) => React.JSX.Element
 *  SubSectionSelector: (props: SelectorProps) => React.JSX.Element
 *  ExamTypeSelector: (props: SelectorProps) => React.JSX.Element
 *  ExamLevelSelector: (props: SelectorProps) => React.JSX.Element
 *  examLevels: object[]
 * }}
 */
export default function useExamSelectors({
  mainExamCategoryId,
  subExamCategoryId,
  subExamCategoryLevel1Id,
  subExamCategoryLevel2Id,
  subExamCategoryLevel3Id,
  used = false
} = {}) {
  const [mainExamCategory, setMainExamCategory] = useState()
  const [subExamCategory, setSubExamCategory] = useState()
  const [parts, setParts] = useState()
  const [sections, setSections] = useState()
  const [subSections, setSubSections] = useState()
  const [examLevels, setExamLevels] = useState([])

  // Main Exam Category
  /**
   * @param {SelectorProps} props
   */
  const MainExamCategorySelector = (props) => (
    <MainExamCategorySelectorComp
      {...props}
      mainCategory={mainExamCategory}
      handleMainExamCatChange={handleMainExamCatChange}
    />
  )
  const handleMainExamCatChange = (id) => {
    if (mainExamCategory) {
      var [cat] = mainExamCategory.filter((cat) => cat.id === id)
      setSubExamCategory(cat?.subExamCategory)
    }
  }

  // Sub Exam Category
  /**
   * @param {SelectorProps} props
   */
  const SubExamCategorySelector = (props) => (
    <SubExamCategorySelectorComp
      {...props}
      subCategory={subExamCategory}
      handleSubExamCatChange={(id) => {
        if (props?.handleChange) props.handleChange()
        handleSubExamCatChange(id)
      }}
    />
  )
  const handleSubExamCatChange = (id) => {
    var cat = subExamCategory?.filter((cat) => cat.id === id)
    setParts(cat?.[0]?.subExamCategoryLevel1Id)
  }

  // part
  /**
   * @param {SelectorProps} props
   */
  const PartSelector = (props) => (
    <PartSelectorComp {...props} handlePartChange={handlePartChange} parts={parts} />
  )
  const handlePartChange = (id) => {
    var part = parts.find((part) => part.subExamCategoryLevel1Id === id)
    setSections(part?.subExamCategoryLevel2Id)
  }

  // section
  /**
   * @param {SelectorProps} props
   */
  const SectionSelector = (props) => (
    <SectionSelectorComp {...props} handleSectionChange={handleSectionChange} sections={sections} />
  )
  const handleSectionChange = (id) => {
    const section = sections.find((section) => section.subExamCategoryLevel2Id === id)
    setSubSections(section?.subExamCategoryLevel3Id)
  }

  // Sub section
  /**
   * @param {SelectorProps} props
   */
  const SubSectionSelector = (props) => (
    <SubSectionSelectorComp {...props} subSections={subSections} />
  )

  // Exam type
  /**
   * @param {SelectorProps} props
   */
  const ExamTypeSelector = (props) => <ExamTypeSelectorComp {...props} />

  // Exam Level
  /**
   * @param {SelectorProps} props
   */
  const ExamLevelSelector = (props) => <ExamLevelSelectorComp {...{ ...props, examLevels }} />

  useEffect(() => {
    if (used) {
      listUsedMainExamCategory()
        .then((resp) => setMainExamCategory(resp.data))
        .catch((error) => console.log(error))
    } else {
      listMainExamCat()
        .then((res) => setMainExamCategory(res.data.items.filter((item) => item.active)))
        .catch((error) => console.log(error))
    }

    listExamlevel()
      .then((res) => setExamLevels(res.data.items.filter((item) => item.active)))
      .catch((error) => console.log(error))
  }, [used])

  useEffect(() => {
    if (mainExamCategory && mainExamCategoryId) handleMainExamCatChange(mainExamCategoryId)

    if (subExamCategory && subExamCategoryId) handleSubExamCatChange(subExamCategoryId)

    if (parts && subExamCategoryLevel1Id) handlePartChange(subExamCategoryLevel1Id)

    if (sections && subExamCategoryLevel2Id) handleSectionChange(subExamCategoryLevel2Id)
  }, [
    mainExamCategoryId,
    subExamCategoryId,
    subExamCategoryLevel1Id,
    subExamCategoryLevel2Id,
    subExamCategoryLevel3Id,
    JSON.stringify(mainExamCategory),
    JSON.stringify(subExamCategory),
    JSON.stringify(parts),
    JSON.stringify(sections),
    JSON.stringify(subSections)
  ])

  return {
    MainExamCategorySelector,
    SubExamCategorySelector,
    PartSelector,
    SectionSelector,
    SubSectionSelector,
    ExamTypeSelector,
    ExamLevelSelector,
    sections,
    examLevels
  }
}

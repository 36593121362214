import { Button, Modal, Row, Col, Typography } from 'antd'
import errorRed from '../Image/errorRed.png'
const { Text } = Typography
export default function ConfirmDelete({ open = false, callback = () => {}, fallback = () => {} }) {
  return (
    <Modal
      destroyOnClose
      open={open}
      onCancel={fallback}
      footer={
        <Row justify={'center'}>
          <Button shape="round" className="btnBGreen" onClick={fallback}>
            ยกเลิก
          </Button>
          <Button className="btnGreen" shape="round" type="primary" onClick={callback}>
            ยืนยัน
          </Button>
        </Row>
      }>
      <Row justify={'center'}>
        <Col span={24}>
          <img style={{ margin: '10% 0% 0% 40%' }} src={errorRed} />
        </Col>
        <Col span={24}>
          <p
            style={{
              textAlign: 'center',
              fontFamily: 'KanitM',
              fontSize: '20px',
              margin: '3% 0% 0% 0%'
            }}>
            <Text>คุณต้องการลบข้อมูลใช่หรือไม่?</Text>
            <br />
            <Row>
              <Col span={24} style={{ padding: '10px' }}></Col>
            </Row>
          </p>
        </Col>
      </Row>
    </Modal>
  )
}

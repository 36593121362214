import _ from 'lodash'

export default class PartUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfPart = 'subExamCategoryLevel1Id'
    this.pathOfPart = [this.parent.indexOfItem, this.nameOfPart]
    this.fullPathOfPart = [...this.parent.pathOfItem, this.nameOfPart]
  }

  getPartValue = () => this.parent.form.getFieldValue(this.fullPathOfPart)

  getPartsValue = () =>
    this.parent.form
      .getFieldValue(this.parent.nameOfList)
      .filter((item) => item?.subExamCategoryLevel1Id)
      .map((item) => item?.subExamCategoryLevel1Id)

  getAvailablePartsValue = () =>
    _(this.parent.examinerUtils.filterExaminersBySubCatId())
      .map((exam) => exam.subExamCategoryLevel1Id)
      .compact()
      .uniq()
      .difference(this.getPartsValue())
      .value()

  getPartOptions = () => {
    if (!this.parent.categoryUtils.getSubCatValue()) return []

    return _(this.parent.examinerUtils.filterExaminersBySubCatId())
      .uniqBy(this.nameOfPart)
      .map((exam) => ({
        label: exam.subExamCategoryLevel1Name,
        value: exam.subExamCategoryLevel1Id,
        ...(this.getPartsValue().indexOf(exam.subExamCategoryLevel1Id) + 1 && { disabled: true })
      }))
      .value()
  }
}

import { Button, Card, Col, Form, Row, Table } from 'antd'
import LayoutPage from '../../components/Layout'
import { DownloadOutlined } from '@ant-design/icons'
import useDataTable from '../../lib/useTable'
import { getQuestionStorage } from '../../data/quest-storage.data'
import { createQuestionStorageColumnsType } from '../../lib/columns-types/question-storage.columns'
import { useState } from 'react'
import Filters from './Filters'
import { useAuth } from '../../AuthorizationProvider'
import { questionsStorageApi } from '../../lib/api'

export default function QuestionStorageList() {
  const { usePageAuth } = useAuth()
  usePageAuth(['list_exam_choice', 'get_exam_choice'])
  const [form] = Form.useForm()
  const { data, tableParams, loading, mutateData, handleTableChange, setTableParams } =
    useDataTable(getQuestionStorage)
  const onFinish = (value) => {
    setTableParams({
      mainExamCategoryId: value.mainExamCategoryId,
      subExamCategoryId: value.subExamCategoryId
    })
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const onSelectChange = (newSelectedRowKeys) => {
    console.log(newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  const download = async () => {
    window.location = questionsStorageApi.base + `/zip/` + selectedRowKeys
  }

  return (
    <LayoutPage>
      <Filters form={form} onFinish={onFinish} />
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          {selectedRowKeys.length === 0 ? (
            <>
              <Button
                disabled={true}
                className="btnGreen"
                shape="round"
                icon={<DownloadOutlined />}>
                ดาวน์โหลดไฟล์ชุดข้อสอบ
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={download}
                className="btnGreen"
                shape="round"
                icon={<DownloadOutlined />}>
                ดาวน์โหลดไฟล์ชุดข้อสอบ
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Card style={{ height: 'auto', width: '100%' }}>
          <Table
            scroll={{ x: 'max-content' }}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            rowKey={(record) => record.id}
            onChange={handleTableChange}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            columns={createQuestionStorageColumnsType(mutateData)}
          />
        </Card>
      </Row>
    </LayoutPage>
  )
}

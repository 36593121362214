import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { AuthorizationProvider } from './AuthorizationProvider'
import { Provider } from 'react-redux'
import { store } from './lib/store'

const root = ReactDOM.createRoot(document.getElementById('root'))

const colorPrimary = '#40ab9a'

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary,
            fontFamily: 'Kanit'
          },
          components: {
            Menu: {
              itemColor: '#fff'
            },
            Button: {
              defaultColor: colorPrimary,
              defaultBorderColor: colorPrimary
            }
          }
        }}>
        <AuthorizationProvider>
          <RouterProvider router={router} />
        </AuthorizationProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

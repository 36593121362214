import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Space,
  Typography,
  Upload,
  Card
} from 'antd'
import TextEditor from './TextEdit'
import { DeleteFilled, PlusCircleFilled, UploadOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import ExamChoicetype5 from './ExamChoiceType5Form'
import ExamChoiceType5Preview from './ExamChoiceType5Preview'
import { apiBaseUrl } from '../lib/api'
const { Text, Title, Link } = Typography

export default function SituationChoiceExamForm({
  record,
  examLevels,
  examQuan = record?.length || 1
}) {
  const [quan, setQuan] = useState(examQuan)
  const form = Form.useFormInstance()
  const [childQuan, setChildQuan] = useState(0)
  const [choiceQuan, setChoiceQuan] = useState([[]])
  const [isParentFocus, setIsParentFocus] = useState(true)
  const [multiParentFocus, setMultiParentFocus] = useState(0)
  const [isFocus, setIsFocus] = useState({ parent: 0, child: 0 })
  const [isInitial, setIsInitial] = useState(true)
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (record) {
      form.setFieldValue(
        'examCreator',
        record.map(({ id, active, examLevel, notRandomChoice, detailsExam, childExam, media }) => ({
          id,
          active,
          examLevelId: examLevel.id,
          notRandomChoice,
          detailsExam,
          childExam,
          mediaId: media?.id
        }))
      )
    }
  }, [JSON.stringify(record)])

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList)
  }

  return (
    <>
      <Form.List name="examCreator" className="questions-list">
        {(questions) => {
          return questions.map((pro) => (
            <>
              <Card
                key={pro.key}
                style={{
                  height: 'auto',
                  width: '95%',
                  marginTop: '12px',
                  position: 'relative'
                }}>
                {isParentFocus && multiParentFocus === pro.name ? (
                  <>
                    <Row justify="space-between" style={{ paddingTop: 20 }}>
                      <Col span={24} xl={12}>
                        <Form.Item
                          className="inline-form-item"
                          name={[pro.name, 'active']}
                          label="สถานะเปิด/ปิดใช้งาน"
                          valuePropName="checked">
                          <Switch
                            checkedChildren="on"
                            unCheckedChildren="off"
                            defaultChecked={true}></Switch>
                        </Form.Item>
                        <Space style={{ marginBottom: 20 }}>
                          <Form.Item
                            name={[pro.name, 'examLevelId']}
                            label="ระดับข้อสอบ"
                            rules={[{ required: true, message: 'เลือกระดับข้อสอบด้วยค่ะ' }]}
                            style={{ marginBottom: 0, minWidth: 300 }}
                            className="inline-form-item">
                            <Select
                              options={examLevels?.map((examl) => ({
                                label: examl.examLevelName,
                                value: examl.id
                              }))}
                            />
                          </Form.Item>
                        </Space>
                      </Col>
                      <Col>
                        <Space style={{ display: 'flex', justifyContent: 'end' }}>
                          <Text>จำนวนข้อที่สร้าง</Text>
                          <Text style={{ color: '#40AB9A', fontSize: 20 }}>
                            {/* {choiceQuan[pro.name]?.length} */}
                            {form.getFieldsValue().examCreator
                              ? form.getFieldsValue().examCreator[pro.name].childExam.length
                              : `${pro.name + 1}`}
                          </Text>
                          <Text>ข้อ</Text>
                        </Space>
                      </Col>
                    </Row>
                    <Title underline level={4}>
                      {`สถานการณ์`}
                    </Title>
                    <Col span={24} lg={18}>
                      <TextEditor record={record} name={[pro.name, 'detailsExam']} />
                    </Col>
                    <Row gutter={[10, 10]}>
                      <Col span={5}>
                        <Form.Item name={[pro.name, 'file']} label="อัพโหลดไฟล์ :">
                          <Upload
                            accept=".mp3"
                            beforeUpload={() => false}
                            fileList={fileList}
                            onChange={handleFileChange}>
                            <Button icon={<UploadOutlined />}>อัพโหลดไฟล์</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      {form.getFieldsValue().examCreator && (
                        <>
                          <Col span={5}>
                            {form.getFieldsValue().examCreator[pro.name].mediaId ? (
                              <Form.Item name={[pro.name, 'mediaId']} label=" ">
                                <Link
                                  href={`${apiBaseUrl}/media/file/${
                                    form.getFieldsValue().examCreator[pro.name].mediaId
                                  }/view`}
                                  target="_blank">
                                  กดคลิกเพื่อดูไฟล์ (Link)
                                </Link>
                              </Form.Item>
                            ) : (
                              !form.getFieldsValue().examCreator[pro.name].file && 'ไม่มีไฟล์'
                            )}
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                ) : (
                  <>
                    <div className="parent-preview-style" />
                    <Form.Item
                      className="preview-hover"
                      name={[pro.name, 'detailsExam']}
                      rules={[{ required: true, message: 'กรอกคำถามด้วยค่ะ' }]}
                      style={{ marginBottom: '0' }}
                      onClick={() => {
                        setIsParentFocus(true),
                          setMultiParentFocus(pro.name),
                          isInitial && setIsInitial(false)
                      }}>
                      {form.getFieldValue(['examCreator', pro.name, 'detailsExam']) ? (
                        <Col>
                          <p>สถานการณ์</p>
                          <div
                            className="preview-editor-datail"
                            dangerouslySetInnerHTML={{
                              __html: `<p>${form.getFieldValue([
                                'examCreator',
                                pro.name,
                                'detailsExam'
                              ])}</p>`
                            }}
                          />
                        </Col>
                      ) : (
                        <p className="preview-editor-datail">สถานการณ์</p>
                      )}
                    </Form.Item>
                  </>
                )}
              </Card>
              <Form.List name={[pro.name, 'childExam']}>
                {(subChildExams, { add, remove }) => {
                  return subChildExams.map((subChildExam) =>
                    (pro.name === isFocus.parent &&
                      subChildExam.name === isFocus.child &&
                      !isParentFocus) ||
                    (isInitial &&
                      pro.name === isFocus.parent &&
                      subChildExam.name === isFocus.child) ? (
                      <Card
                        key={subChildExam.key}
                        style={{
                          height: 'auto',
                          width: '95%',
                          marginTop: '12px'
                        }}>
                        <Title level={4}>{`โจทย์ข้อที่ (${subChildExam.name + 1})`}</Title>
                        <Space>
                          <Form.Item>
                            <Text>โจทย์</Text>
                          </Form.Item>
                          <Form.Item
                            valuePropName="checked"
                            name={[subChildExam.name, 'notRandomChoice']}
                            initialValue={false}>
                            <Checkbox> ไม่สุ่มคำตอบ</Checkbox>
                          </Form.Item>
                        </Space>
                        <Button
                          onClick={() => {
                            add({ examChoice: [{ isCorrect: false }] }),
                              setQuan(form.getFieldsValue().examCreator[pro.name].childExam.length),
                              setChildQuan(childQuan + 1),
                              setChoiceQuan(
                                choiceQuan.map((arr, index) =>
                                  index === pro.name ? [...arr, subChildExam.name + 1] : [...arr]
                                )
                              ),
                              form.getFieldsValue().examCreator[pro.name].childExam
                                ? setIsFocus({
                                    parent: form.getFieldsValue().examCreator.length - 1,
                                    child:
                                      form.getFieldsValue().examCreator[pro.name].childExam.length -
                                      1
                                  })
                                : setIsFocus({
                                    parent: 0,
                                    child: 1
                                  }),
                              isInitial && setIsInitial(false),
                              setIsParentFocus(false)
                          }}
                          className="btnBorderG"
                          shape="round"
                          icon={<PlusCircleFilled />}>
                          เพิ่มข้อสอบ
                        </Button>
                        <Row align="middle" gutter={10}>
                          <Col span={24} lg={18}>
                            <TextEditor name={[subChildExam.name, 'detailsExam']} />
                          </Col>
                          {!!subChildExam.name && quan > 0 && (
                            <Col style={{ marginBottom: 10, marginLeft: 10 }}>
                              <Button
                                type="primary"
                                icon={<DeleteFilled />}
                                onClick={() => {
                                  remove(subChildExam.name)
                                  setQuan(quan - 1)
                                  setChoiceQuan(
                                    choiceQuan.map((a, b) =>
                                      b === pro.name
                                        ? a.filter((c, d) => d !== a.length - 1)
                                        : [...a]
                                    )
                                  )
                                }}
                                className="deleteBtn"
                              />
                            </Col>
                          )}
                        </Row>
                        <ExamChoicetype5
                          record={record}
                          name={[pro.name, 'childExam', subChildExam.name, 'examChoice']}
                        />
                      </Card>
                    ) : (
                      <Card
                        key={subChildExam.name}
                        style={{
                          height: 'auto',
                          width: '95%',
                          marginTop: '12px'
                        }}>
                        <Col
                          className="preview-hover"
                          onClick={() => {
                            setIsFocus({
                              parent: pro.name,
                              child: subChildExam.name
                            }),
                              setIsParentFocus(false)
                          }}>
                          <Form.Item
                            name={[subChildExam.name, 'detailsExam']}
                            rules={[{ required: true, message: 'กรอกคำถามด้วยค่ะ' }]}
                            style={{ marginBottom: '0' }}>
                            {form.getFieldValue([
                              'examCreator',
                              pro.name,
                              'childExam',
                              subChildExam.name,
                              'detailsExam'
                            ]) ? (
                              <Row>
                                <p>{subChildExam.name + 1}.&nbsp;</p>
                                <div
                                  className="preview-editor-datail"
                                  dangerouslySetInnerHTML={{
                                    __html: `<p>${form.getFieldValue([
                                      'examCreator',
                                      pro.name,
                                      'childExam',
                                      subChildExam.name,
                                      'detailsExam'
                                    ])}</p>`
                                  }}
                                />
                              </Row>
                            ) : (
                              <p className="preview-editor-datail">
                                โจทย์ข้อที่ {subChildExam.name + 1}
                              </p>
                            )}
                          </Form.Item>

                          <ExamChoiceType5Preview
                            name={[pro.name, 'childExam', subChildExam.name, 'examChoice']}
                          />
                        </Col>
                      </Card>
                    )
                  )
                }}
              </Form.List>
            </>
          ))
        }}
      </Form.List>
    </>
  )
}

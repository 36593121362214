import { React } from 'react'
import { Row, Col, Typography, Table, Form, Card, Space } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import LayoutPage from '../../components/Layout'
import { approveHistory } from '../../data/examiner.data'
import withHook from '../../lib/withHook'
import FiltersHistory from './FiltersHistory'
import { useAuth } from '../../AuthorizationProvider'
import useDataTable from '../../lib/useDataTable'
const { Text } = Typography
const ListHistory = () => {
  const [form] = Form.useForm()
  const { usePageAuth } = useAuth()
  usePageAuth(['list_approve_exam', 'get_approve_exam'])

  const { dataSource, tableParams, loading, mutateData, handleTableChange, setTableParams } =
    useDataTable(approveHistory)

  const onFinish = (value) => {
    setTableParams(value)
  }

  return (
    <LayoutPage>
      <FiltersHistory form={form} onFinish={onFinish} />
      <Card>
        <Table
          scroll={{ x: 'max-content' }}
          columns={createColumnsType(mutateData)}
          rowKey={(record) => record.id}
          dataSource={dataSource}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>
    </LayoutPage>
  )
}

export default ListHistory
const createColumnsType = () => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    align: 'center'
  },
  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'examExaminer',
    align: 'center',
    render: (examExaminer) => (
      <div>
        <Row justify="start">
          <Col>
            <Text>{examExaminer?.mainExamCategory?.mainExamCategoryName}</Text>
          </Col>
        </Row>
      </div>
    )
  },
  {
    title: 'ชื่อหมวดหมู่ข้อสอบย่อย',
    dataIndex: 'examExaminer',
    align: 'center',
    render: (examExaminer) => (
      <div>
        <Row justify="start">
          <Col>
            <Text>{examExaminer?.subExamCategory?.subExamCategoryName}</Text>
          </Col>
        </Row>
      </div>
    )
  },
  {
    title: 'ผู้ออกข้อสอบ',
    dataIndex: 'createdBy',
    align: 'center',
    render: (createdBy) => (
      <div>
        <Row justify="center">
          <Col>
            <Text>{`${createdBy?.fname} ${createdBy?.lname}`}</Text>
          </Col>
        </Row>
      </div>
    )
  },
  {
    title: 'จำนวนข้อสอบ',
    children: [
      {
        title: 'จำนวนข้อทั้งหมด',
        dataIndex: 'numberOfExamsTotal',
        width: 150,
        align: 'center'
      },
      {
        title: 'จำนวนข้อที่ต้องการ',
        dataIndex: 'numberOfExamsRequired',
        width: 150,
        align: 'center'
      }
    ]
  },
  {
    title: 'จำนวนชุดข้อสอบ',
    dataIndex: 'numberOfSets',
    align: 'center'
  },
  {
    title: 'ผู้อนุมัติ',
    dataIndex: 'approvedBy',
    align: 'center'
  },
  {
    title: 'สถานะ',
    dataIndex: 'approved',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) =>
      withHook(() => {
        if (record.approve) {
          return (
            <Space align="start">
              <CheckCircleFilled
                // bordered={false}
                style={{
                  color: '#52C41A'
                }}
              />
              <Text style={{ color: '#52C41A' }}>อนุมัติ</Text>
            </Space>
          )
        } else {
          return (
            <Space align="start">
              <CloseCircleFilled
                style={{
                  color: 'red'
                }}
              />
              <Text style={{ color: 'red', align: 'start' }}>ไม่อนุมัติ</Text>
            </Space>
          )
        }
      })
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'remark',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) =>
      withHook(() => {
        return (
          <Row gutter={[10, 0]} justify="center">
            <Text>{record.remark}</Text>
          </Row>
        )
      })
  }
]

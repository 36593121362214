import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons'
import { Button, Col, Form, Row, Select, Space, Typography } from 'antd'
const { Text } = Typography

/**
 * @param {object} props
 * @param {import("./types").ActionPropsType} props.actions
 * @param {import("./types").FieldPropsType} props.field
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {boolean} props.disabled
 */
export default function Parts({ actions, utils, disabled }) {
  const form = Form.useFormInstance()

  return (
    !!utils.partUtils.getPartOptions().length && (
      <>
        <Row justify="space-between" align="middle">
          <Col>
            <Text>Parts : </Text>
          </Col>

          <Col>
            {!!utils.indexOfItem && (
              <Button
                icon={<DeleteFilled />}
                onClick={() => {
                  actions.remove(utils.indexOfItem)
                }}
                danger
                disabled={disabled}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <Form.Item
                name={utils.partUtils.pathOfPart}
                rules={[{ required: true, message: 'กรุณาเลือก Section' }]}
                style={{ margin: '10px 0', minWidth: 300 }}>
                <Select
                  onChange={() => {
                    form.resetFields([
                      utils.sectionUtils.fullPathOfSection,
                      utils.subSectionUtils.fullPathOfSubSection,
                      utils.examTypeUtils.fullPathOfExamType,
                      utils.examinerUtils.fullPathOfUsersId,
                      utils.examUtils.fullPathOfNumberOfExamsRequired,
                      utils.examLevelUtils.fullPathOfExamLevel
                    ])
                  }}
                  placeholder="เลือก Part"
                  options={utils.partUtils.getPartOptions()}
                  disabled={disabled}
                  allowClear={true}
                />
              </Form.Item>

              {!!utils.partUtils.getAvailablePartsValue().length &&
                !!utils.partUtils.getPartValue() && (
                  <Button
                    className="btnBorderG"
                    shape="round"
                    disabled={disabled}
                    icon={<PlusCircleFilled />}
                    onClick={() => {
                      actions.add({
                        subExamCategoryLevel1Id: utils.partUtils.getAvailablePartsValue()[0],
                        examExaminerLevel: [{}]
                      })
                    }}>
                    เพิ่ม Part
                  </Button>
                )}
            </Space>
          </Col>
        </Row>
      </>
    )
  )
}

import { Form, Select } from 'antd'

export default function SubExamLevelSelector({
  examLevel1 = [],
  handleSubExamLevel1CatChange = () => {},
  view,
  disabled,
  style = {}
}) {
  const form = Form.useFormInstance()
  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const subExamValue = form.getFieldValue('subExamCategoryId')
        return (
          <Form.Item
            name="subExamCategoryLevel1Id"
            rules={[
              {
                required: true,
                message: 'Parts'
              }
            ]}
            label="Parts :"
            style={style}>
            <Select
              onChange={(id) => {
                handleSubExamLevel1CatChange(id)
                form.resetFields(['subExamCategoryLevel2Id'])
              }}
              placeholder="เลือก Parts"
              options={examLevel1?.map((sexam) => ({
                label: sexam.subExamCategoryLevel1Name,
                value: sexam.subExamCategoryLevel1Id
              }))}
              disabled={!subExamValue || view || disabled}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

import { useEffect, useState } from 'react'
import { listMainExamCat } from '../../data/category'
import MainCatSelector from '../QuestionStorage/MainCat'
import SubCatSelector from '../QuestionStorage/SubCat'
import ExamTypeSelector from '../exam-approval/ExamTypes'
import ApporveRadioForm from '../../components/ApproveRadioForm'

export default function useExamSelectors() {
  /**
   * @type {[import("../../types/categories").MainExamCategoryPropsType[]]}
   */
  const [mainCat, setMainCat] = useState([])
  /**
   * @type {[import("../../types/categories").SubExamCategoryPropsType[]]}
   */
  const [subCat, setSubCat] = useState([])

  const [sections, setSections] = useState([])

  useEffect(() => {
    listMainExamCat()
      .then((res) => setMainCat(res.data.items.filter((item) => item.active)))
      .catch((error) => console.log(error))
  }, [])

  const handleMainCatChange = (id) => {
    if (mainCat) {
      var [cat] = mainCat.filter((cat) => cat.id === id)
      setSubCat(cat?.subExamCategory)
    }
  }

  const handleSubCatChange = (id) =>
    setSections(subCat.find((cat) => cat.id === id).subExamCategoryLevel1Id)

  const createMainCatSelector = (props) => (
    <MainCatSelector mainCat={mainCat} handleMainCatChange={handleMainCatChange} {...props} />
  )

  const createSubCatSelector = (props) => (
    <SubCatSelector subCat={subCat} handleSubCatChange={handleSubCatChange} {...props} />
  )

  const createExamTypesSelector = (props) => <ExamTypeSelector {...props} />

  const ApporveSelector = () => <ApporveRadioForm />

  return {
    sections,
    createMainCatSelector,
    createSubCatSelector,
    createExamTypesSelector,
    ApporveSelector
  }
}

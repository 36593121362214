import { Typography } from 'antd'

const { Text } = Typography

export const createFileImportColumnsType = () => [
  {
    title: 'เลขบัตรประชาชน',
    dataIndex: 'idCardNumber'
  },
  {
    title: 'คำนำหน้า',
    dataIndex: 'prefix'
  },
  {
    title: 'ชื่อ',
    dataIndex: 'fname'
  },
  {
    title: 'นามสกุล',
    dataIndex: 'lname'
  },
  {
    title: 'เบอร์โทรศัพท์มือถือ',
    dataIndex: 'mobile'
  },
  {
    title: 'อีเมล',
    dataIndex: 'email'
  },
  {
    title: 'สิทธิ์การใช้งาน',
    dataIndex: 'rolesName'
  },
  {
    title: 'สถานะ',
    dataIndex: 'importStatus',
    align: 'center',
    render: (status) => {
      if (status !== undefined)
        return status ? <Text type="success">สำเร็จ</Text> : <Text type="danger">ไม่สำเร็จ</Text>
      else return '-'
    }
  },
  {
    title: 'สาเหตุ',
    dataIndex: 'cause',
    align: 'center'
  }
]

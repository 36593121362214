import { React, useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Typography, Space, Switch } from 'antd'
import { Content } from 'antd/es/layout/layout'
import env from '../lib/environments'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import { createExamLevel, updateExamLevel } from '../data/examlevel.data'
const layout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 16
  }
}
const { Text } = Typography

export default function ExamLevelForm({
  view = false,
  record,
  handleCancel = () => {},
  mutateData = () => {}
}) {
  const [confirm, setConfirm] = useState(false)
  const [form] = useForm()
  const handleCreate = async (values = {}) => {
    try {
      await createExamLevel(values)
      mutateData()
      handleCancel(false)
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleUpdate = async (values) => {
    try {
      if (values.examLevelName !== record.examLevelName || values.active !== record.active) {
        await updateExamLevel(record.id, values)
        mutateData()
      }
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const onFinish = () => {
    const values = form.getFieldsValue()

    if (!record) {
      handleCreate({ ...values, active: Boolean(values.active) })
    } else {
      handleUpdate({ ...values, active: Boolean(values.active) })
    }
  }

  return (
    <Form
      form={form}
      // layout="vertical"
      onFinish={() => setConfirm(true)}
      {...layout}
      initialValues={{
        examLevelName: record?.examLevelName,
        active: record?.active
      }}>
      <Form.Item
        label="ชื่อระดับข้อสอบ"
        name="examLevelName"
        rules={[
          {
            required: true,
            message: 'กรุณากรอกชื่อระดับข้อสอบ!'
          }
        ]}>
        <Input className="inputtxt2" placeholder="กรอกชื่อระดับข้อสอบ" disabled={view} />
      </Form.Item>

      <Form.Item name="active" label="สถานะเปิด/ปิดใช้งาน" valuePropName="checked">
        <Switch onChange={handleChange} checkedChildren="on" unCheckedChildren="off"></Switch>
      </Form.Item>

      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        <Button shape="round" className="btnBGreen" onClick={handleCancel}>
          ยกเลิก
        </Button>
        <Button className="btnGreen" shape="round" type="primary" htmlType="submit">
          บันทึกข้อมูล
        </Button>
      </Space>
      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
          handleCancel()
          setConfirm(false)
        }}
        fallback={() => {
          setConfirm(false)
        }}></Confirmation>
    </Form>
  )
}

const options = []
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i
  })
}
const handleChange = (value) => {
  console.log(`selected ${value}`)
}

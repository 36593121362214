import { React, useContext, useEffect, useState } from 'react'
import 'dayjs/locale/th'
import dayjs from 'dayjs'
import { useForm } from 'antd/es/form/Form'
import { Form, Row, Col, Typography, Button, Table, Switch, Space } from 'antd'
import { Link } from 'react-router-dom'
import {
  PlusCircleOutlined,
  ImportOutlined,
  EditFilled,
  DeleteFilled,
  EyeFilled
} from '@ant-design/icons'
import '../../style/main.css'
import LayoutPage from '../../components/Layout'
import VruModal from '../../components/Modal'
import UserForm from '../../components/UserForm'
import { activeUser, deleteUser, listUser } from '../../data/user.data'
import withHook from '../../lib/withHook'
import ConfirmationDelete from '../../components/ConfirmDelete'
// import getColumnSearchProps from '../../lib/columns-types/columnSearch'
import { AuthContext } from '../../AuthorizationProvider'
import useDataTable from '../../lib/useDataTable'
import ConfirmationApprove from '../../components/ConfirmationSuccess'
import ActiveSwitch from '../../components/ActiveSwitch'

const { Text } = Typography

const ManageUserPage = ({ gregorianDate }) => {
  const { usePageAuth, currentUserCan } = useContext(AuthContext)
  usePageAuth(['list_users', 'get_users'])

  const { dataSource, tableParams, loading, refetch, handleTableChange } = useDataTable(listUser)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const thaiBuddhistDate = dayjs(gregorianDate).add(543, 'year')
  return (
    <LayoutPage>
      <Row gutter={[0, 20]} justify="space-between">
        <Col span={12}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px'
            }}>
            จัดการข้อมูลสมาชิก
          </Text>
        </Col>
        <Col span={12}>
          <Space style={{ display: 'flex', justifyContent: 'end' }}>
            {currentUserCan(['create_users']) && (
              <>
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusCircleOutlined />}
                  onClick={showModal}>
                  เพิ่มข้อมูลสมาชิก
                </Button>
                <Link to="/ManageUserPage/import">
                  <Button className="btnBGreen" shape="round" icon={<ImportOutlined />}>
                    นำเข้าข้อมูลสมาชิก
                  </Button>
                </Link>
              </>
            )}
          </Space>
        </Col>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Table
            scroll={{ x: 'max-content' }}
            columns={createColumnsType(refetch, thaiBuddhistDate)}
            rowKey={(record) => record.id}
            dataSource={dataSource}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <VruModal title="เพิ่มสมาชิก" open={isModalOpen} handleCancel={handleCancel}>
        <UserForm mutateData={refetch} handleCancel={handleCancel} />
      </VruModal>
    </LayoutPage>
  )
}

export default ManageUserPage

const createColumnsType = (mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    responsive: ['sm'],
    align: 'center'
  },
  {
    title: 'เลขบัตรประชาชน',
    dataIndex: 'idCardNumber',
    responsive: ['sm'],
    align: 'center',
    // ...getColumnSearchProps('idCardNumber'),
    render: (idCardNumber) => '*********' + idCardNumber?.slice(9)
  },
  {
    title: 'ชื่อ - นามสกุล',
    dataIndex: ['fname', 'lname'],
    responsive: ['sm'],
    align: 'center',
    // ...getColumnSearchProps('fname', 'lname'),
    render: (_, record) => `${record.fname}-${record.lname}`
  },
  {
    title: 'อีเมล',
    dataIndex: 'email',
    responsive: ['sm'],
    align: 'center',
    // ...getColumnSearchProps('email'),
    render: (email) => email
  },

  {
    title: 'เข้าใช้งานล่าสุด',
    dataIndex: 'lastLogin',
    responsive: ['sm'],
    align: 'center',
    // ...getColumnSearchProps('lastLogin'),
    render: (_, record) =>
      record.lastLogin ? dayjs(record.lastLogin).locale('th').format('DD MMMM BBBB LT น.') : '-'
  },
  {
    title: 'จัดการผู้ใช้งาน',
    dataIndex: 'manage',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) =>
      withHook(() => {
        return (
          <Row gutter={[10, 0]} justify="center">
            <Col>
              <ActiveSwitch record={record} mutateData={mutateData} />
            </Col>
          </Row>
        )
      })
  },
  {
    title: 'จัดการ',
    dataIndex: 'manage',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) =>
      withHook(() => {
        const [editModal, setEditModal] = useState(false)
        const [viewModal, setViewModal] = useState(false)
        const [deleteModal, setDeleteModal] = useState(false)
        const { currentUserCan } = useContext(AuthContext)
        return (
          <Row gutter={[10, 0]} justify="center">
            <Col>
              <VruModal title="ดูข้อมูล" open={viewModal} handleCancel={() => setViewModal(false)}>
                <UserForm
                  view={true}
                  record={record}
                  mutateData={() => {}}
                  handleCancel={() => setViewModal(false)}
                />
              </VruModal>
              <Button
                disabled={!currentUserCan(['get_users'])}
                className="btnview"
                icon={<EyeFilled />}
                onClick={() => setViewModal(true)}
              />
            </Col>
            <Col>
              <VruModal title="แก้ไข" open={editModal} handleCancel={() => setEditModal(false)}>
                <UserForm
                  edit={true}
                  record={record}
                  mutateData={mutateData}
                  handleCancel={() => setEditModal(false)}
                />
              </VruModal>
              <Button
                disabled={!currentUserCan(['update_users'])}
                className="btnedit"
                icon={<EditFilled />}
                onClick={() => setEditModal(true)}
              />
            </Col>
            <Col>
              <Button
                disabled={!currentUserCan(['delete_users'])}
                className="btndelete"
                icon={<DeleteFilled />}
                onClick={() => setDeleteModal(true)}
              />
              <ConfirmationDelete
                open={deleteModal}
                callback={async () => {
                  try {
                    const resp = await deleteUser(record.id)
                    if (/^can/i.test(resp.data))
                      alert('ไม่สามารถลบผู้ใช้ได้ เนื่องจากมีการใช้งานแล้ว')
                    else mutateData()
                  } catch (error) {
                    console.log(error.message)
                  }
                }}
                fallback={() => {
                  setDeleteModal(false)
                }}
              />
            </Col>
          </Row>
        )
      })
  }
]

import { Form, InputNumber, Space, Typography } from 'antd'
const { Text } = Typography

/**
 * @param {object} props
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {boolean} props.disabled
 */
export default function NumberOfExams({ utils, disabled }) {
  return (
    <Space style={{ display: 'flex', justifyContent: 'stretch' }}>
      <Text>จำนวนข้อสอบที่ต้องการ :</Text>

      <Form.Item
        name={utils.examUtils.pathOfNumberOfExamsRequired}
        style={{ marginBottom: 0 }}
        className="inline-form-item"
        rules={[{ pattern: /^[1-9]+/, message: 'กรอกจำนวนและไม่ติดลบค่ะ' }]}>
        <InputNumber
          disabled={disabled}
          style={{ width: '90px' }}
          onChange={(value) => {
            const totalNumberOfExams = utils.examUtils.getTotalNumberOfExams()

            if (value < 0) utils.examUtils.setNumberOfExamsRequired(0)
            else {
              if (value > totalNumberOfExams)
                utils.examUtils.setNumberOfExamsRequired(totalNumberOfExams)
              else utils.examUtils.setNumberOfExamsRequired(value)

              utils.form.setFieldValue(utils.examLevelUtils.fullPathOfExamLevel, [{}])
            }
          }}
        />
      </Form.Item>

      <Text>{`ไม่เกิน (${utils.examUtils.getTotalNumberOfExams() || 0}) ข้อ`}</Text>
    </Space>
  )
}

import withHook from '../withHook'
import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { CheckOutlined, DeleteFilled, EditFilled } from '@ant-design/icons'
import ConfirmDelete from '../../components/ConfirmDelete'
import qs from 'qs'
import { EXAM_CREATOR } from '../../constants/paths'
import { stripHTMLTags } from '../helpers'
import parser from 'html-react-parser'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { deleteExam } from '../../data/exam.data'

dayjs.extend(localizedFormat)

export const createExamViewColumnsType4Child = (record, mutateData, loading) => [
  {
    title: 'ข้อที่',
    dataIndex: 'no',
    align: 'center'
  },
  {
    title: 'คำตอบ',
    dataIndex: 'examChoice',
    align: 'center',
    render: (examChoice) => (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '30px 1fr',
          gap: 8
        }}>
        {examChoice.map((choice, index) => (
          <React.Fragment key={index}>
            {choice.isCorrect ? <CheckOutlined /> : <span />}
            <div style={{ gridColumnStart: 2, textAlign: 'left' }}>
              {stripHTMLTags(choice.choiceName)}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }
]

import { Alert, Form, Select } from 'antd'
import { useAuth } from '../../AuthorizationProvider'

export default function SectionSelectorComp({
  sections,
  view,
  disabled,
  style = {},
  handleSectionChange = () => {}
}) {
  const form = Form.useFormInstance()
  const { currentUserCan } = useAuth()

  return currentUserCan(['list_sub_exam_category_level2']) ? (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const subExamCategoryLevel1Id = form.getFieldValue('subExamCategoryLevel1Id')
        return (
          <Form.Item name="subExamCategoryLevel2Id" label="เลือก Sections :" style={style}>
            <Select
              onChange={(id) => {
                handleSectionChange(id)
              }}
              placeholder="เลือก Sections"
              options={sections?.map((section) => ({
                label: section.subExamCategoryLevel2Name,
                value: section.subExamCategoryLevel2Id
              }))}
              disabled={!subExamCategoryLevel1Id || view || disabled}
              allowClear={true}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  ) : (
    <Alert message="กรุณาติดต่อแอดมิน" type="warning" />
  )
}

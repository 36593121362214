import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const getResetpassword = (values) =>
  fetchJsonWithToken(env.resetpassApi, {
    method: 'GET',
    body: JSON.stringify(values)
  })

export const forgotpassword = (values) =>
  fetchJsonWithToken(env.forgotApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const resetpass = (values) =>
  fetchJsonWithToken(env.resetpassApi, {
    method: 'PATCH',
    body: JSON.stringify(values)
  })

export const ResetPasswordUser = (values) =>
  fetchJsonWithToken(`${env.resetpassApi}/update-password`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })

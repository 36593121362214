import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const listSubExam = () => fetchJsonWithToken(env.subExamApi)
export const createSubExam = (values) =>
  fetchJsonWithToken(env.subExamApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const updateSubExam = (id, values) =>
  fetchJsonWithToken(`${env.subExamApi}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })
export const deleteSubExam = (id) =>
  fetchJsonWithToken(`${env.subExamApi}/${id}/delete`, {
    method: 'DELETE'
  })
// export const listSubExam = () => fetchJsonWithToken(env.subExamApi);

import { Divider, Row, Typography, Button, Modal } from 'antd'
import { updateRole } from '../data/role.data'
import { useState, useEffect } from 'react'
import { Progress, Space } from 'antd'
import { fetchJsonWithToken } from '../lib/fethcer'
import env from '../lib/environments'

export default function Download({
  title = 'Exam files',
  id,
  data,
  handleCancel = () => {},
  handleOk = () => {
    console.log('Updated')
  }
}) {
  return (
    <Row style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography.Text style={{ color: '#0B6252', fontWeight: 'bold', fontSize: '22px' }}>
        Password updated Successfully
      </Typography.Text>
      <Divider />
      <Button
        shape="round"
        color="green"
        handleOk={handleOk}
        onClick={() => document.location.reload()}>
        Ok
      </Button>
    </Row>
  )
}

import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Space } from 'antd'
import { useState } from 'react'
import VruModal from '../../components/Modal'
import FunctionsList from './FunctionsList'
import { createPermissions } from '../../data/permission.data'

export default function Create({ fetchRoles }) {
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const onFinish = async (values) => {
    try {
      await createPermissions({ ...values, normalizedName: values.name, active: true })
      fetchRoles()
      closeModal()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Button className="btnGreen" shape="round" icon={<PlusCircleOutlined />} onClick={openModal}>
        เพิ่มกลุ่มผู้ใช้งาน
      </Button>
      <VruModal title="เพิ่มข้อมูล" open={isModalOpen} handleCancel={closeModal}>
        <Form form={form} onFinish={onFinish} style={{ padding: 20 }}>
          <Form.Item
            label="Group Name"
            name="name"
            rules={[{ required: true, message: 'กรุณากรอก Group Name!' }]}>
            <Input placeholder="กรอกชื่อกลุ่ม" />
          </Form.Item>

          <FunctionsList />

          <Space style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button shape="round" className="btnBGreen" onClick={closeModal}>
              ยกเลิก
            </Button>
            <Button className="btnGreen" shape="round" type="primary" htmlType="submit">
              บันทึกข้อมูล
            </Button>
          </Space>
        </Form>
      </VruModal>
    </>
  )
}

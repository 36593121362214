import { Form, Radio } from 'antd'
import { useState } from 'react'
export default function ApporveRadioForm() {
  const [value, setValue] = useState()
  const onChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <Form.Item>
      <Form.Item name="approve" label=" ">
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={true}>อนุมัติ</Radio>
          <Radio value={false}>ไม่อนุมัติ</Radio>
        </Radio.Group>
      </Form.Item>
    </Form.Item>
  )
}

import { Alert, Form, Select } from 'antd'
import { useAuth } from '../../AuthorizationProvider'

export default function PartSelectorComp({
  parts,
  noRules,
  disabled,
  style = {},
  handlePartChange = () => {}
}) {
  const form = Form.useFormInstance()
  const { currentUserCan } = useAuth()
  return currentUserCan(['list_sub_exam_category_level1']) ? (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const subExamValue = form.getFieldValue('subExamCategoryId')
        return (
          <Form.Item
            name="subExamCategoryLevel1Id"
            label="เลือก Parts :"
            style={style}
            {...(!noRules && {
              rules: [
                {
                  required: true,
                  message: 'กรุณาเลือก Part ด้วยค่ะ'
                }
              ]
            })}>
            <Select
              onChange={(id) => {
                handlePartChange(id)
                form.resetFields(['subExamCategoryLevel2Id'])
              }}
              placeholder="เลือก Parts"
              options={parts?.map((part) => ({
                label: part.subExamCategoryLevel1Name,
                value: part.subExamCategoryLevel1Id
              }))}
              disabled={!subExamValue || disabled}
              allowClear={true}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  ) : (
    <Alert message="กรุณาติดต่อแอดมิน" type="warning" />
  )
}

import { Content } from 'antd/es/layout/layout'
import LayoutPage from '../../components/Layout'
import { styles } from './styles'
import { Col, Row, Typography } from 'antd'
import Create from './Create'
import useUpdate from './useUpdate'
import { createContext, useEffect, useState } from 'react'
import { listPermissions } from '../../data/permission.data'
const { Text } = Typography

export default function Permission() {
  const { fetchRoles, context } = useUpdate()
  const [permissions, setPermissions] = useState()

  useEffect(() => {
    listPermissions()
      .then((resp) => {
        setPermissions(resp.data)
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <PermissionContext.Provider value={{ permissions }}>
      <LayoutPage>
        <Content style={styles.content}>
          <Row justify="space-between">
            <Col span={12}>
              <Text
                style={{
                  fontFamily: 'KanitM',
                  color: '#0B6252',
                  fontSize: '25px'
                }}>
                จัดการสิทธิ์การใช้งาน
              </Text>
            </Col>

            <Col>
              <Create fetchRoles={fetchRoles} />
            </Col>
          </Row>

          {context}
        </Content>
      </LayoutPage>
    </PermissionContext.Provider>
  )
}

export const PermissionContext = createContext()

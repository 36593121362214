import { React, useState } from 'react'
import { Button, Col, Form, Input, Row, Space, Switch } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import { createlistExamLevel2, updateLevel2 } from '../data/level2.data'
import useExam from '../lib/useExam'
import TextArea from 'antd/es/input/TextArea'

const layout = {
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 16
  }
}
export default function Level2_Form({
  view = false,
  record,
  handleCancel = () => {},
  mutateData = () => {}
}) {
  const [confirm, setConfirm] = useState(false)
  const [form] = useForm()

  const handleCreate = async (values = {}) => {
    try {
      await createlistExamLevel2(values)
      mutateData()
      handleCancel(false)
    } catch (error) {
      alert('Error : ชื่อ Section มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }

  const handleUpdate = async (values) => {
    try {
      if (
        values.active !== record.active ||
        values.description !== record.description ||
        values.subExamCategoryLevel1Id !== record.subExamCategoryLevel1Id ||
        values.subExamCategoryLevel2Name !== record.subExamCategoryLevel2Name
      ) {
        await updateLevel2(record.subExamCategoryLevel2Id, values)
        mutateData()
      }
      handleCancel()
    } catch (error) {
      alert('Error : ชื่อ Section มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }

  const { MainExamCategorySelector, SubExamCategorySelector, SubExamLevelSelector } = useExam({
    mainExamCateId: record?.mainExamCategoryId,
    subExamCateId: record?.subExamCategoryId,
    subExamCateLevel1: record?.subExamCategoryLevel1Id
  })

  const onFinish = () => {
    const values = form.getFieldsValue()
    if (!record) {
      handleCreate({
        subExamCategoryLevel1Id: values.subExamCategoryLevel1Id,
        subExamCategoryLevel2Name: values.subExamCategoryLevel2Name,
        description: values.description,
        active: Boolean(values.active)
      })
    } else {
      handleUpdate({
        subExamCategoryLevel1Id: values.subExamCategoryLevel1Id,
        subExamCategoryLevel2Name: values.subExamCategoryLevel2Name,
        description: values.description,
        active: Boolean(values.active)
      })
    }
  }

  return (
    <Form
      form={form}
      {...layout}
      onFinish={() => setConfirm(true)}
      initialValues={{
        mainExamCategoryId: record?.mainExamCategoryId,
        subExamCategoryId: record?.subExamCategoryId,
        subExamCategoryLevel1Id: record?.subExamCategoryLevel1Id,
        subExamCategoryLevel2Name: record?.subExamCategoryLevel2Name,
        description: record?.description,
        active: record?.active
      }}>
      <Row>
        <Col span={24}>
          <MainExamCategorySelector disabled={view} />
          <SubExamCategorySelector disabled={view} />
          <SubExamLevelSelector />
        </Col>
      </Row>

      <Form.Item
        name="subExamCategoryLevel2Name"
        label="Sections:"
        requiredMark="optional"
        rules={[
          {
            required: true,
            message: 'กรุณากรอก Sections'
          }
        ]}>
        <Input className="inputtxt2" placeholder="กรุณากรอก Sections" disabled={false} />
      </Form.Item>
      <Form.Item name="description" label="Description:">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="active" label="สถานะเปิด/ปิดใช้งาน" valuePropName="checked">
        <Switch onChange={handleChange} checkedChildren="on" unCheckedChildren="off"></Switch>
      </Form.Item>
      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        <Button shape="round" className="btnBGreen" onClick={handleCancel}>
          ยกเลิก
        </Button>
        <Button className="btnGreen" shape="round" type="primary" htmlType="submit">
          บันทึกข้อมูล
        </Button>
      </Space>
      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
        }}
        fallback={() => {
          setConfirm(false)
        }}
      />
    </Form>
  )
}

const handleChange = (value) => {
  console.log(`selected ${value}`)
}

import { useState, React } from 'react'
import { Col, Layout, Menu, Row, Typography } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import iconMenu from '../Image/iconMenu.png'
import { HiOutlineDocumentText } from 'react-icons/hi2'
import { LuFolderCog } from 'react-icons/lu'
import {
  MdMenuBook,
  MdFormatListBulleted,
  MdOutlineGroups2,
  MdOutlineLibraryBooks,
  MdOutlineQuiz,
  MdOutlineManageAccounts,
  MdSignalCellularAlt
} from 'react-icons/md'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import {
  EXAM_CREATOR,
  EXAM_PUBLISHER,
  QUESTION_STORAGE,
  APPROVE,
  MANAGELEVEL1,
  MANAGELEVEL2,
  MANAGELEVEL3,
  EXAM_REPORT
} from '../constants/paths'
import { useAuth } from '../AuthorizationProvider'

const { Text } = Typography
const { Sider } = Layout

const SiderLayout = () => {
  const { currentUserCan } = useAuth()
  const [isOpen] = useState(false)
  const navigate = useNavigate()
  const pathname = useLocation().pathname
  const parent = pathname.slice(1, -1).split('/')[0]
  const [collapsed, setCollapsed] = useState(false)

  const AllMenu = [
    currentUserCan(['list_users']) && {
      label: 'จัดการข้อมูลสมาชิก',
      key: '/ManageUserPage',
      icon: <MdOutlineGroups2 style={{ marginLeft: isOpen ? '0px' : '1px' }} />
    },

    currentUserCan(['list_roles']) && {
      label: 'จัดการสิทธิ์การใช้งาน',
      key: '/permission',
      icon: <MdOutlineManageAccounts style={{ marginLeft: isOpen ? '0px' : '1px' }} />
    },

    currentUserCan(['list_exam_level']) && {
      label: 'จัดการข้อมูลระดับข้อสอบ',
      key: '/ManageLevelExam',
      icon: <MdMenuBook style={{ marginLeft: isOpen ? '0px' : '1px' }} />
    },

    currentUserCan(['list_main_exam_category', 'list_sub_exam_category']) && {
      label: 'จัดหมวดหมู่ข้อสอบ',
      key: 'Exam',
      icon: <MdFormatListBulleted style={{ marginLeft: isOpen ? '0px' : '1px' }} />,
      children: [
        currentUserCan(['list_main_exam_category']) && {
          label: 'ข้อสอบหลัก',
          key: '/Exam/MainExamCategory'
        },
        currentUserCan(['list_sub_exam_category']) && {
          label: 'ข้อสอบย่อย',
          key: '/Exam/SubExamCategory',
          disabled: !currentUserCan(['list_sub_exam_category'])
        }
      ]
    },

    currentUserCan([
      'list_sub_exam_category_level1',
      'list_sub_exam_category_level2',
      'list_sub_exam_category_level3'
    ]) && {
      label: 'จัดการ Level ข้อสอบย่อย',
      key: 'manage',
      icon: <MdSignalCellularAlt style={{ marginLeft: isOpen ? '0px' : '1px' }} />,
      children: [
        currentUserCan(['list_sub_exam_category_level1']) && {
          label: 'Parts',
          key: `${MANAGELEVEL1}/list`
        },
        currentUserCan(['list_sub_exam_category_level2']) && {
          label: 'Sections',
          key: `${MANAGELEVEL2}/list`
        },
        currentUserCan(['list_sub_exam_category_level3']) && {
          label: 'Sub Sections',
          key: `${MANAGELEVEL3}/list`
        }
      ]
    },

    currentUserCan(['list_exam', 'list_exam_examiner']) && {
      label: 'ระบบคลังข้อสอบ',
      key: 'examination',
      icon: <MdOutlineLibraryBooks style={{ marginLeft: isOpen ? '0px' : '1px' }} />,
      children: [
        currentUserCan(['list_exam']) && {
          label: 'ผู้ออกข้อสอบ',
          key: `${EXAM_CREATOR}/list`
        },
        // currentUserCan(['list_exam']) && {
        //   label: 'จัดการข้อสอบ',
        //   key: portals.examManagement.index
        // },
        currentUserCan(['list_exam_examiner']) && {
          label: 'ผู้จัดทำชุดข้อสอบ',
          key: `${EXAM_PUBLISHER}/list`
        }
      ]
    },

    currentUserCan(['list_approve_exam']) && {
      label: 'จัดการอนุมัติคลังข้อสอบ',
      key: `${APPROVE}/list`,
      icon: <AiOutlineCheckCircle style={{ marginLeft: isOpen ? '0px' : '1px' }} />
    },

    currentUserCan(['list_exam_choice']) && {
      label: 'จัดเก็บชุดข้อสอบ',
      key: `${QUESTION_STORAGE}/list`,
      icon: <MdOutlineQuiz style={{ marginLeft: isOpen ? '0px' : '1px' }} />
    },

    currentUserCan(['list_log']) && {
      label: 'จัดการ Log การใช้งาน',
      key: '/LogPage',
      icon: <LuFolderCog style={{ marginLeft: isOpen ? '0px' : '1px' }} />
    },

    currentUserCan(['get_exam_report']) && {
      label: 'รายงาน',
      key: `${EXAM_REPORT}`,
      icon: <HiOutlineDocumentText style={{ marginLeft: isOpen ? '0px' : '1px' }} />
    }
  ]

  return (
    <Sider breakpoint="lg" collapsedWidth="100" width={250} collapsed={collapsed}>
      <div className="logo">
        <Row>
          {!collapsed && (
            <div>
              <Row>
                <Col span={19}>
                  <div style={{ padding: '15px 0px 10px 40px' }}>
                    <Text
                      style={{ fontFamily: 'kanit', fontSize: '20px' }}
                      className="txtMenu"></Text>
                  </div>
                </Col>
                <Col span={2}>
                  <div style={{ padding: '15px 0px 0px 70px' }}>
                    <img src={iconMenu} onClick={() => setCollapsed(!collapsed)}></img>
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {collapsed && (
            <Col span={2}>
              <div style={{ padding: '15px 0px 0px 34px' }}>
                <img src={iconMenu} onClick={() => setCollapsed(!collapsed)}></img>
              </div>
            </Col>
          )}
        </Row>
      </div>

      <Menu
        onClick={({ key }) => {
          navigate(key)
        }}
        style={{
          fontFamily: 'Kanit',
          color: '#fff',
          fontSize: '16px',
          flexDirection: 'column'
        }}
        mode="inline"
        xs={0}
        sm={0}
        md={20}
        items={AllMenu}
        defaultOpenKeys={[parent]}
        defaultSelectedKeys={[pathname]}
      />
    </Sider>
  )
}
export default SiderLayout

import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const listUser = () => fetchJsonWithToken(env.userApi)

export const createUser = (values) =>
  fetchJsonWithToken(env.userApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const updateUser = (id, values) =>
  fetchJsonWithToken(`${env.userApi}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })
export const getUser = () =>
  fetchJsonWithToken(`${env.userApi}/me`, {
    method: 'GET'
  })
export const deleteUser = (id) =>
  fetchJsonWithToken(`${env.userApi}/${id}/delete`, {
    method: 'DELETE'
  })
export const activeUser = (id, values) =>
  fetchJsonWithToken(`${env.userApi}/${id}/active`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })
// export const updatePassword = (id) =>
// fetchJsonWithToken(`${env.userApi}/${id}`, {
//   method: "PATCH",
//   body: JSON.stringify(id),
// });
export const MyRole = () => fetchJsonWithToken(`${env.userApi}/my-role`)
export const updatePassword = (id, values) =>
  fetchJsonWithToken(`${env.userApi}/${id}/update-password`, {
    method: 'PATCH',
    body: JSON.stringify(values)
  })
export const listRoles = () => fetchJsonWithToken(env.rolesApi)
export const listImportUser = () => fetchJsonWithToken(env.userApi)

export const checkEmailUniqueness = (email) =>
  fetchJsonWithToken(`${env.userApi}/email-available/${email}`, {
    method: 'GET'
  })
export const checkIdCardUniqueness = (idcard) =>
  fetchJsonWithToken(`${env.userApi}/id-card-available/${idcard}`, {
    method: 'GET'
  })

export const listPrefix = () =>
  fetchJsonWithToken(`${env.userApi}/prefix`, {
    method: 'GET'
  })

export const updatePrefix = (id, values) =>
  fetchJsonWithToken(`${env.userApi}/${id}/update-prefix`, {
    method: 'PATCH',
    body: JSON.stringify(values)
  })

export const updatePassUser = (id, values) =>
  fetchJsonWithToken(`${env.userApi}/${id}/update-password`, {
    method: 'PATCH',
    body: JSON.stringify(values)
  })

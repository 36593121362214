import _ from 'lodash'

export default class SectionUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfSection = 'subExamCategoryLevel2Id'
    this.pathOfSection = [this.parent.indexOfItem, this.nameOfSection]
    this.fullPathOfSection = [...this.parent.pathOfItem, this.nameOfSection]
  }

  getSectionValue = () => this.parent.form.getFieldValue(this.fullPathOfSection)

  getSectionsValue = () =>
    this.parent.form
      .getFieldValue(this.parent.nameOfList)
      .filter((item) => item?.subExamCategoryLevel2Id)
      .map((item) => item.subExamCategoryLevel2Id)

  getSectionOptions = () => {
    if (!this.parent.partUtils.getPartValue()) return []

    return _(this.parent.examinerUtils.filterExaminersByPart())
      .uniqBy(this.nameOfSection)
      .map((exam) => ({
        label: exam.subExamCategoryLevel2Name,
        value: exam.subExamCategoryLevel2Id,
        ...(this.getSectionsValue().indexOf(exam.subExamCategoryLevel2Id) + 1 && {
          disabled: true
        })
      }))
      .filter((exam) => exam.value)
      .value()
  }

  getAvailableSectionsValue = () => {
    return _(this.parent.examinerUtils.filterExaminersByPart())
      .map((exam) => exam.subExamCategoryLevel2Id)
      .compact()
      .uniq()
      .difference(this.getSectionsValue())
      .value()
  }
}

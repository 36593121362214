import _ from 'lodash'

export default class ExamLevelUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfExamLevel = 'examExaminerLevel'
    this.pathOfExamLevel = [this.parent.indexOfItem, this.nameOfExamLevel]
    this.fullPathOfExamLevel = [...this.parent.pathOfItem, this.nameOfExamLevel]
  }

  getTotalNumberOfExamsRequiredAtAllLevels = () =>
    this.parent.form
      .getFieldValue(this.fullPathOfExamLevel)
      ?.reduce((acc, lv) => (acc += lv.numberRequiredLevel || 0), 0) || 0

  getUniqExamLevels = () =>
    _(this.parent.examUtils.getExams())
      .map(({ examLevelId, examLevelName }) => ({ examLevelId, examLevelName }))
      .uniqBy('examLevelId')
      .value()

  getAvailableExamLevelsId = () => {
    const examLevelsId = this.getUniqExamLevels().map((lv) => lv.examLevelId)
    const examLevelsValue = _(this.parent.form.getFieldValue(this.fullPathOfExamLevel))
      .map((lv) => lv.examLevelId)
      .compact()
      .value()

    return _.difference(examLevelsId, examLevelsValue)
  }

  getMaxNumbersOfLevel = () =>
    _(this.parent.examUtils.getExams())
      .groupBy((exam) => exam.examLevelId)
      .map((level, key) => ({
        examLevelId: parseInt(key),
        max: level.reduce((acc, lv) => (acc += lv.weight), 0)
      }))
      .value()

  getExamLevelOptions = () => {
    return this.getUniqExamLevels().map((lv) => ({
      label: lv.examLevelName,
      value: lv.examLevelId,
      ...(this.isExamLevelAlreadyUsed(lv.examLevelId) && { disabled: true })
    }))
  }

  isExamLevelAlreadyUsed = (value) => {
    const examLevels = this.parent.form.getFieldValue(this.fullPathOfExamLevel)
    const examLevelsId = _(examLevels).mapValues('examLevelId').toArray().compact().value()
    return examLevelsId.indexOf(value) + 1
  }
}

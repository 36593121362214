import { configureStore } from '@reduxjs/toolkit'
import examinersReducer from '../features/examiners/examinersSlice'

export const store = configureStore({
  reducer: {
    examiner: examinersReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['examiner/setListApi']
      }
    })
})

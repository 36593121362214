import { Button, Col, Row, Table, Typography } from 'antd'
import LayoutPage from '../../components/Layout'
import { Link } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons'
import { createColumnsTypeInExaminerList } from '../../lib/columns-types/examiner-list.columns'
import { ExaminerView } from '../../data/examiner.data'
import useDataTable from '../../lib/useDataTable'
import portals from '../../lib/portals'
import { useAuth } from '../../AuthorizationProvider'
const { Text } = Typography
const { examPublisher } = portals

export default function ExaminerList() {
  const { usePageAuth, currentUserCan } = useAuth()
  usePageAuth(['list_exam_examiner', 'get_exam_examiner'])
  const { dataSource, loading, refetch, tableParams, handleTableChange } = useDataTable(
    ExaminerView,
    {
      pagination: {
        current: 1,
        pageSize: 5
      }
    }
  )

  return (
    <LayoutPage>
      <Row gutter={[0, 20]} justify="space-between">
        <Col span={12}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px'
            }}>
            จัดการชุดข้อสอบ (ผู้ออกข้อสอบ)
          </Text>
        </Col>

        <Col>
          {currentUserCan(['create_exam_examiner']) && (
            <Link to={examPublisher.create()}>
              <Button className="btnGreen" shape="round" icon={<PlusCircleOutlined />}>
                ออกข้อสอบ
              </Button>
            </Link>
          )}
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Table
            scroll={{ x: 'max-content' }}
            columns={createColumnsTypeInExaminerList(refetch)}
            rowKey={(record) => record.seq}
            dataSource={dataSource}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </LayoutPage>
  )
}

import { Alert, Form, Select } from 'antd'
import { useAuth } from '../../AuthorizationProvider'

export default function SubSectionSelectorComp({ subSections, disabled, style = {} }) {
  const form = Form.useFormInstance()
  const { currentUserCan } = useAuth()

  return currentUserCan(['list_sub_exam_category_level3']) ? (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const subExamCategoryLevel2Id = form.getFieldValue('subExamCategoryLevel2Id')
        return (
          <Form.Item name="subExamCategoryLevel3Id" label="เลือก Sub Sections:" style={style}>
            <Select
              placeholder="เลือก Sub Sections"
              options={subSections?.map((examl) => ({
                label: examl.subExamCategoryLevel3Name,
                value: examl.subExamCategoryLevel3Id
              }))}
              disabled={!subExamCategoryLevel2Id || disabled}
              allowClear={true}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  ) : (
    <Alert message="กรุณาติดต่อแอดมิน" type="warning" />
  )
}

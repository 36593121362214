import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'
import qs from 'qs'

export const getQuestionStorage = (query) =>
  fetchJsonWithToken(`${env.questionStorageApi}?${qs.stringify(query)}`, {
    method: 'GET'
  })

export const QuestionStorageID = ({ id }) => {
  return fetchJsonWithToken(`${env.questionStorageApi}/${id}`, {
    method: 'GET'
  }).then((d) => {
    const newData = {
      ...d,
      data: {
        items: d.data.sectionSeq[0].examSeq
      }
    }
    return newData
  })
}

import { React, useEffect } from 'react'
import { Layout, Row, Col } from 'antd'
import logo_2 from '../Image/logo_2.png'
import LayoutPageMain from '../components/LayoutMain'
import Book_BG from '../Image/Book_BG.png'

// import{token} from"../pages/LoginPage";
const { Content } = Layout

const MainPage = () => {
  const contentStyle = {
    backgroundColor: '#E8F8EC',
    height: '100vh',
    with: '100%'
  }

  return (
    <>
      <LayoutPageMain>
        <Content style={contentStyle}>
          <Row>
            <Col
              span={18}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <img
                style={{
                  width: '100%',
                  maxWidth: '900px',
                  padding: '0% 0% 10% 0%',
                  margin: '0% 0% 0% 30%'
                }}
                src={logo_2}
              />
            </Col>
            <Col
              span={6}
              style={{
                // display: "flex",
                justifyContent: 'end'
              }}>
              <img
                style={{ width: '100%', maxWidth: '300px', height: '130%', margin: '2% 0% 0% 30%' }}
                src={Book_BG}
              />
            </Col>
          </Row>
        </Content>
      </LayoutPageMain>
    </>
  )
}

export default MainPage

import { Button, Card, Col, Form, Row, Space } from 'antd'
import Fields from './Fields'
import img from '../../Image/img_select_data.png'
import { useEffect } from 'react'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExaminers, selectRecord } from '../../features/examiners/examinersSlice'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

/**
 * @param {object} props
 * @param {import('../../types/examiner.types').ExaminerByIdPropsType} props.record
 * @param {boolean} props.disabled
 */
export default function ExamPublisherForm({ disabled, onFinish }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const record = useSelector(selectRecord)
  const { MainExamCategorySelector, SubExamCategorySelector } = useExamSelectors({
    mainExamCategoryId: record?.mainExamCategory?.id,
    subExamCategoryId: record?.subExamCategory?.id,
    used: true
  })

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        isRandom: record.isRandom,
        numberOfSets: record.numberOfSets,
        mainExamCategoryId: record.mainExamCategory.id,
        subExamCategoryId: record.subExamCategory.id,
        section: record.section.map((st) => ({
          examType: st.examType,
          subExamCategoryLevel1Id: st.subExamCategoryLevel1.subExamCategoryLevel1Id,
          subExamCategoryLevel2Id: st.subExamCategoryLevel2?.subExamCategoryLevel2Id,
          subExamCategoryLevel3Id: st.subExamCategoryLevel3?.subExamCategoryLevel3Id,
          usersId: st.users.map((user) => user.id),
          numberOfExamsRequired: st.numberOfExamsRequired,
          examExaminerLevel: st.examExaminerLevel.map((lv) => ({
            examLevelId: lv.examLevel.id,
            numberRequiredLevel: lv.numberRequiredLevel
          })),
          exam: st.exam
        }))
      })
    } else form.setFieldValue('section', [{}])
  }, [record])

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[20]}>
        <Col span={8}>
          <MainExamCategorySelector disabled={disabled || record} />
        </Col>
        <Col span={8}>
          <SubExamCategorySelector
            disabled={disabled || record}
            handleChange={() => form.setFieldValue('section', [{}])}
          />
        </Col>
      </Row>

      <Card style={{ height: '100%', width: '100%' }}>
        <Form.Item shouldUpdate={(prev, cur) => prev.subExamCategoryId !== cur.subExamCategoryId}>
          {() => {
            const subExamCateId = form.getFieldValue('subExamCategoryId')

            return (
              <>
                {subExamCateId && (
                  <WithExaminers>
                    <Fields disabled={disabled} />
                  </WithExaminers>
                )}

                {!subExamCateId && <img src={img} style={{ display: 'block', margin: '0 auto' }} />}

                {subExamCateId && !disabled && (
                  <Row justify="center">
                    <Space>
                      <Col span={2}>
                        <Button shape="round" className="btnBGreen" onClick={() => navigate(-1)}>
                          ยกเลิก
                        </Button>
                      </Col>
                      <Col span={2}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btnGreen"
                          shape="round"
                          style={{ margin: '0% 0% 0% 2%' }}>
                          บันทึกข้อมูล
                        </Button>
                      </Col>
                    </Space>
                  </Row>
                )}
              </>
            )
          }}
        </Form.Item>
      </Card>
    </Form>
  )
}

/**
 * @param {{children: ChildNode, disabled: Boolean}} props
 */
const WithExaminers = (props) => {
  const form = useFormInstance()
  const dispatch = useDispatch()
  const catsId = form.getFieldsValue(['mainExamCategoryId', 'subExamCategoryId'])

  useEffect(() => {
    dispatch(fetchExaminers({ ...catsId, limit: 0 }))
  }, [JSON.stringify(catsId)])

  return props.children
}

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, InputNumber, Row, Select, Space, Typography } from 'antd'
const { Text, Title } = Typography

/**
 * @param {object} props
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {disabled} props.disabled
 */
export default function ExamLevels({ utils, disabled }) {
  const requiredNumberOfExams = utils.examUtils.getNumberOfExamsRequired()
  const totalNumberOfExamsRequiredAtAllLevels =
    utils.examLevelUtils.getTotalNumberOfExamsRequiredAtAllLevels()
  const remainingQuota = requiredNumberOfExams - totalNumberOfExamsRequiredAtAllLevels

  return (
    !!requiredNumberOfExams && (
      <>
        <Title level={5} style={{ marginTop: 20 }}>
          ระดับข้อสอบ (
          <Text type={remainingQuota ? 'danger' : 'success'}>ต้องเลือกอีก {remainingQuota}</Text>)
        </Title>

        <Form.List
          name={utils.examLevelUtils.pathOfExamLevel}
          initialValue={[{ examLevelId: utils.examLevelUtils.getAvailableExamLevelsId()[0] }]}>
          {(fields, { add, remove }) =>
            fields.map((field) => {
              const examLevelId = utils.form.getFieldValue([
                ...utils.examLevelUtils.fullPathOfExamLevel,
                field.name,
                'examLevelId'
              ])
              const requiredNumberOfExamLevel = utils.form.getFieldValue([
                ...utils.examLevelUtils.fullPathOfExamLevel,
                field.name,
                'numberRequiredLevel'
              ])
              const maxNumbersOfLevel = utils.examLevelUtils.getMaxNumbersOfLevel()
              const required = requiredNumberOfExams || 0
              const max =
                maxNumbersOfLevel.find((level) => level.examLevelId === examLevelId)?.max || 0
              const availableNumber = required >= max ? max : required

              return (
                <Row key={field.key} gutter={10} style={{ marginBottom: 10 }}>
                  <Col span={24} style={{ marginBottom: 10 }}>
                    <Space style={{ display: 'flex' }}>
                      <Text>เลือกระดับข้อสอบ :</Text>

                      <Form.Item
                        name={[field.name, 'examLevelId']}
                        style={{ marginBottom: 0, minWidth: 295 }}
                        rules={[{ required: true, message: 'เลือกระดับด้วยคะ' }]}>
                        <Select
                          disabled={disabled}
                          onChange={() => {
                            utils.form.resetFields([
                              [
                                ...utils.examLevelUtils.fullPathOfExamLevel,
                                field.name,
                                'numberRequiredLevel'
                              ]
                            ])
                          }}
                          options={utils.examLevelUtils.getExamLevelOptions()}
                        />
                      </Form.Item>

                      {!field.name &&
                        !!examLevelId &&
                        !!requiredNumberOfExamLevel &&
                        !!utils.examLevelUtils.getAvailableExamLevelsId().length &&
                        !!remainingQuota && (
                          <Button
                            disabled={disabled}
                            onClick={() => {
                              add({
                                examLevelId: utils.examLevelUtils.getAvailableExamLevelsId()[0],
                                numberRequiredLevel: 0
                              })
                            }}
                            className="btnGreen"
                            shape="circle"
                            icon={<PlusOutlined />}
                          />
                        )}

                      {!!field.name && (
                        <MinusCircleOutlined
                          disabled={disabled}
                          style={{ margin: '0px 0px 0px 5px' }}
                          shape="circle"
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      )}
                    </Space>
                  </Col>

                  <Col>
                    <Space>
                      <Text>จำนวนข้อที่ต้องการ : </Text>
                      <Form.Item
                        style={{ margin: 0 }}
                        name={[field.name, 'numberRequiredLevel']}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value <= 0) return Promise.reject('กรุณากรอกจำนวน')
                              else if (remainingQuota > 0)
                                return Promise.reject('กรุณากรอกให้ครบจำนวน')
                              else return Promise.resolve()
                            }
                          }
                        ]}>
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '90px' }}
                          onChange={(value) => {
                            if (value < 0 || !examLevelId) {
                              utils.form.setFieldValue(
                                [
                                  ...utils.examLevelUtils.fullPathOfExamLevel,
                                  field.name,
                                  'numberRequiredLevel'
                                ],
                                0
                              )
                            } else {
                              const maxValue = maxNumbersOfLevel.find(
                                (number) => number.examLevelId === examLevelId
                              )
                              let newValue = value > maxValue.max ? maxValue.max : value
                              newValue =
                                utils.getRemainingNumberOfExams(field.name) >= newValue
                                  ? newValue
                                  : utils.getRemainingNumberOfExams(field.name)
                              utils.form.setFieldValue(
                                [
                                  ...utils.examLevelUtils.fullPathOfExamLevel,
                                  field.name,
                                  'numberRequiredLevel'
                                ],
                                newValue || 0
                              )
                            }
                          }}
                        />
                      </Form.Item>

                      <Text style={{ textAlign: 'center' }}>(สูงสุด {availableNumber} ข้อ)</Text>
                    </Space>
                  </Col>
                </Row>
              )
            })
          }
        </Form.List>
      </>
    )
  )
}

import _ from 'lodash'

export default class ExaminerUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfUsersId = 'usersId'
    this.pathOfUsersId = [this.parent.indexOfItem, this.nameOfUsersId]
    this.fullPathOfUsersId = [...this.parent.pathOfItem, this.nameOfUsersId]
  }

  /**
   * @returns {import('../../../types/examiner.types').ExaminerPropsType[]}
   */
  filterExaminersBySubCatId = () =>
    this.parent.examiners.filter(
      (exam) => exam.subExamCategoryId === this.parent.categoryUtils.getSubCatValue()
    )

  /**
   * @returns {import('../../../types/examiner.types').ExaminerPropsType[]}
   */
  filterExaminersByPart = () =>
    this.filterExaminersBySubCatId().filter(
      (exam) => exam.subExamCategoryLevel1Id === this.parent.partUtils.getPartValue()
    )

  /**
   * @returns {import('../../../types/examiner.types').ExaminerPropsType[]}
   */
  filterExaminersBySection = () =>
    this.parent.examinerUtils
      .filterExaminersByPart()
      .filter((exam) => exam.subExamCategoryLevel2Id === this.parent.sectionUtils.getSectionValue())

  /**
   * @returns {import('../../../types/examiner.types').ExaminerPropsType[]}
   */
  filterExaminersByCatsId = () => {
    let catsId = _.merge(
      {
        subExamCategoryLevel1Id: null,
        subExamCategoryLevel2Id: null,
        subExamCategoryLevel3Id: null
      },
      _.pick(this.parent.form.getFieldValue(this.parent.pathOfItem), [
        'subExamCategoryLevel1Id',
        'subExamCategoryLevel2Id',
        'subExamCategoryLevel3Id'
      ])
    )

    catsId = Object.entries(catsId).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value || null }),
      {}
    )

    return _.filter(this.filterExaminersBySubCatId(), catsId)
  }

  /**
   * @returns {import('../../../types/examiner.types').ExaminerPropsType[]}
   */
  filterExaminersByExamTypeValue = () =>
    this.filterExaminersByCatsId().filter(
      (examiner) => examiner.examType === this.parent.examTypeUtils.getExamTypeValue()
    )

  /**
   * @returns {import('../../../types/examiner.types').ExaminerPropsType[]}
   */
  filterExaminersByUsersId = () =>
    this.filterExaminersByExamTypeValue().filter(
      (examiner) => this.getUsersValue()?.indexOf(examiner.createdById) + 1
    )

  getUniqUsers = () => _.uniqBy(this.filterExaminersByExamTypeValue(), 'FullName')

  getUsersValue = () => this.parent.form.getFieldValue(this.fullPathOfUsersId)
}

import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const listMainExam = () => fetchJsonWithToken(env.mainExamApi)
export const createMainExam = (values) =>
  fetchJsonWithToken(env.mainExamApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const updateMainExam = (id, values) =>
  fetchJsonWithToken(`${env.mainExamApi}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })
export const deleteMainExam = (id) =>
  fetchJsonWithToken(`${env.mainExamApi}/${id}/delete`, {
    method: 'DELETE'
  })

import { React, useState, useEffect, useRef } from 'react'
import { Row, Col, Typography, Input, Table, Space } from 'antd'
import { listExam } from '../../data/exam.data'
import useDataTable from '../../lib/useTable'
import LayoutPage from '../../components/Layout'
import { useSearchParams } from 'react-router-dom'
import { createExamViewColumnsType } from '../../lib/columns-types/exam-view.columns'

import { createExamViewColumnsType2 } from '../../lib/columns-types/exam-view.columns-Type2'
import { createExamViewColumnsType3 } from '../../lib/columns-types/exam-view.columns-Type3'
import { createExamViewColumnsType4 } from '../../lib/columns-types/exam-view.columns-Type4'
import { createExamViewColumnsType5 } from '../../lib/columns-types/exam-view.columns-Type5'
import { createExamViewColumnsType4Child } from '../../lib/columns-types/exam-view.columns-Type4-child'
import { createExamViewColumnsType5Child } from '../../lib/columns-types/exam-view.columns-Type5-child'

import { useAuth } from '../../AuthorizationProvider'

const { Text } = Typography

const ExaminationView = () => {
  const { usePageAuth } = useAuth()
  usePageAuth(['get_exam'])
  const [params] = useSearchParams()
  const [record, setRecord] = useState()
  const [count, setcount] = useState()
  const mainExamCategoryId = parseInt(params.get('mainExamCategoryId'))
  const subExamCategoryId = parseInt(params.get('subExamCategoryId'))
  const subExamCategoryLevel1Id = parseInt(params.get('subExamCategoryLevel1Id'))
  const subExamCategoryLevel2Id = parseInt(params.get('subExamCategoryLevel2Id'))
  const subExamCategoryLevel3Id = parseInt(params.get('subExamCategoryLevel3Id'))
  const examType = parseInt(params.get('examType'))
  const isLoading = useRef(true)
  const isValidQS = Boolean(
    mainExamCategoryId &&
      subExamCategoryId &&
      subExamCategoryLevel1Id && { ...(subExamCategoryLevel2Id && subExamCategoryLevel2Id) } && {
        ...(subExamCategoryLevel3Id && subExamCategoryLevel3Id)
      } &&
      examType
  )

  const fetchExam = () => {
    listExam({
      mainExamCategoryId,
      subExamCategoryId,
      subExamCategoryLevel1Id,
      subExamCategoryLevel2Id,
      subExamCategoryLevel3Id,
      examType
    })
      .then((res) => {
        setRecord(res.data.items.map((item, key) => ({ ...item, seq: key + 1 })))
        setcount(res.data.items.length)
      })
      .catch((error) => console.log(error))
      .finally(() => {
        isLoading.current = false
      })
  }

  useEffect(() => {
    if (isValidQS && isLoading.current) fetchExam()
  }, [isValidQS])

  if (!isValidQS) return

  const expandedRowRender = (record) => {
    return (
      <Table
        columns={
          record.examType === 4
            ? createExamViewColumnsType4Child(record)
            : createExamViewColumnsType5Child(record)
        }
        dataSource={record.childExam}
        rowKey={record.childExam}
        // pagination={false}
      />
    )
  }

  return (
    <LayoutPage>
      <Row justify="space-between" gutter={[0, 20]}>
        <Col span={12}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px'
            }}>
            {examType === 1 && <> ดูข้อสอบคำตอบเดียว</>}
            {examType === 2 && <> ดูข้อสอบบรรยาย</>}
            {examType === 3 && <> ดูข้อสอบจับคู่</>}
            {examType === 4 && <> ดูข้อสอบ Cloze Passsage</>}
          </Text>
        </Col>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Col>
            <Space>
              <Text>จำนวนข้อที่สร้าง</Text>
              <Text>{count}</Text>
              <Text>ข้อ</Text>
            </Space>
          </Col>
          {examType === 1 && (
            <Table
              scroll={{ x: 300 }}
              columns={createExamViewColumnsType(fetchExam)}
              rowKey={(record) => record.id}
              dataSource={record}
            />
          )}
          {examType === 2 && (
            <Table
              scroll={{ x: 300 }}
              columns={createExamViewColumnsType2(fetchExam)}
              rowKey={(record) => record.id}
              dataSource={record}
            />
          )}
          {examType === 3 && (
            <Table
              scroll={{ x: 300 }}
              columns={createExamViewColumnsType3(fetchExam)}
              rowKey={(record) => record.id}
              dataSource={record}
            />
          )}
          {examType === 4 && (
            <Table
              scroll={{ x: 300 }}
              columns={createExamViewColumnsType4(fetchExam)}
              expandable={{
                expandedRowRender
              }}
              rowKey={(record) => record.id}
              dataSource={record}
            />
          )}
          {examType === 5 && (
            <Table
              scroll={{ x: 300 }}
              columns={createExamViewColumnsType5(fetchExam)}
              expandable={{
                expandedRowRender
              }}
              rowKey={(record) => record.id}
              dataSource={record}
            />
          )}
        </Col>
      </Row>
    </LayoutPage>
  )
}

export default ExaminationView

import { Button, Checkbox, Col, Form, Row, Select, Space, Typography, Switch, Card } from 'antd'
import TextEditor from './TextEdit'
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons'
import ExamChoice from './ExamChoice'
import ExamChoicePreview from './ExamChoicePreview'
import React, { useState } from 'react'
const { Text, Title } = Typography

export default function SingleChoiceExamForm({ examLevels, updateOne, record = [] }) {
  const [numberOfExams, setNumberOfExams] = useState(record.length || 1)
  const [isFocus, setIsFocus] = useState(0)
  const form = Form.useFormInstance()

  return (
    <>
      <Form.List name="examCreator" className="questions-list">
        {(questions, { add, remove }) => {
          return questions.map((pro) =>
            pro.name === isFocus ? (
              <Card
                key={pro.name}
                style={{
                  height: 'auto',
                  width: '95%',
                  marginTop: '12px'
                }}>
                <Title underline level={4}>{`โจทย์ข้อที่ ${pro.name + 1}`}</Title>

                <Form.Item
                  name={[pro.name, 'active']}
                  label="สถานะเปิด/ปิดใช้งาน"
                  valuePropName="checked">
                  <Switch
                    onChange={handleChange}
                    checkedChildren="on"
                    unCheckedChildren="off"
                    defaultChecked={true}></Switch>
                </Form.Item>

                <Row justify="space-between">
                  <Col span={24} xl={12}>
                    <Space style={{ marginBottom: 20 }}>
                      <Form.Item
                        name={[pro.name, 'examLevelId']}
                        label="ระดับข้อสอบ"
                        rules={[{ required: true, message: 'เลือกระดับข้อสอบด้วยค่ะ' }]}
                        style={{ marginBottom: 0, minWidth: 300 }}
                        className="inline-form-item">
                        <Select
                          options={examLevels?.map((examl) => ({
                            label: examl.examLevelName,
                            value: examl.id
                          }))}
                        />
                      </Form.Item>

                      {!updateOne && (
                        <Button
                          onClick={() => {
                            add({ examChoice: [{ isCorrect: false }] })
                            setNumberOfExams(numberOfExams + 1)
                            form.getFieldsValue().examCreator
                              ? setIsFocus(form.getFieldsValue().examCreator.length - 1)
                              : setIsFocus(1)
                          }}
                          className="btnBorderG"
                          shape="round"
                          icon={<PlusCircleFilled />}>
                          เพิ่มข้อสอบ
                        </Button>
                      )}
                    </Space>
                  </Col>
                  <Col>
                    <Space style={{ display: 'flex', justifyContent: 'end' }}>
                      <Text>จำนวนข้อที่สร้าง</Text>
                      <Text style={{ color: '#40AB9A', fontSize: 20 }}>{numberOfExams}</Text>
                      <Text>ข้อ</Text>
                    </Space>
                  </Col>
                </Row>

                <Space>
                  <Form.Item>
                    <Text>โจทย์</Text>
                  </Form.Item>
                  <Form.Item
                    valuePropName="checked"
                    name={[pro.name, 'notRandomChoice']}
                    initialValue={false}>
                    <Checkbox> ไม่สุ่มคำตอบ</Checkbox>
                  </Form.Item>
                </Space>

                <Row align="middle" gutter={10}>
                  <Col span={24} lg={18}>
                    <TextEditor record={record} name={[pro.name, 'detailsExam']} />
                  </Col>

                  {!!pro.name && (
                    <Col>
                      <Button
                        type="primary"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          remove(pro.name)
                          setNumberOfExams(numberOfExams - 1)
                          setIsFocus(pro.name - 1)
                        }}
                        className="deleteBtn"
                      />
                    </Col>
                  )}
                </Row>

                <Form.Item>
                  <ExamChoice record={record} name={[pro.name, 'examChoice']} />
                </Form.Item>
              </Card>
            ) : (
              <Card
                key={pro.name}
                style={{
                  height: 'auto',
                  width: '95%',
                  marginTop: '12px'
                }}>
                <Col className="preview-hover" onClick={() => setIsFocus(pro.name)}>
                  <Form.Item
                    name={[pro.name, 'detailsExam']}
                    rules={[{ required: true, message: 'กรอกคำถามด้วยค่ะ' }]}
                    style={{ marginBottom: '0' }}>
                    {form.getFieldValue(['examCreator', pro.name, 'detailsExam']) ? (
                      <Row>
                        <p>{pro.name + 1}.&nbsp;</p>
                        <div
                          className="preview-editor-datail"
                          dangerouslySetInnerHTML={{
                            __html: `<p>${form.getFieldValue([
                              'examCreator',
                              pro.name,
                              'detailsExam'
                            ])}</p>`
                          }}
                        />
                      </Row>
                    ) : (
                      <p className="preview-editor-datail">โจทย์ข้อที่ {pro.name + 1}</p>
                    )}
                  </Form.Item>

                  <ExamChoicePreview name={[pro.name, 'examChoice']} />
                </Col>
              </Card>
            )
          )
        }}
      </Form.List>
    </>
  )
}

const handleChange = (value) => {
  console.log(`selected ${value}`)
}

import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Col, Form, Row, Select, Space, Typography } from 'antd'
const { Text } = Typography

/**
 * @param {object} props
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {import("./types").ActionPropsType} props.actions
 * @param {boolean} props.disabled
 */
export default function Sections({ actions, utils, disabled }) {
  const form = Form.useFormInstance()

  return (
    <>
      <Text>Sections : </Text>

      <Row>
        <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Space>
            <Form.Item
              name={utils.sectionUtils.pathOfSection}
              style={{ margin: '10px 0', minWidth: 300 }}>
              <Select
                onChange={() => {
                  form.resetFields([
                    utils.subSectionUtils.fullPathOfSubSection,
                    utils.examTypeUtils.fullPathOfExamType,
                    utils.examinerUtils.fullPathOfUsersId,
                    utils.examUtils.fullPathOfNumberOfExamsRequired,
                    utils.examLevelUtils.fullPathOfExamLevel
                  ])
                }}
                placeholder="Sections"
                options={utils.sectionUtils.getSectionOptions()}
                disabled={disabled || !utils.sectionUtils.getSectionOptions().length}
                allowClear={true}
              />
            </Form.Item>

            {!!utils.sectionUtils.getAvailableSectionsValue().length &&
              utils.sectionUtils.getSectionValue() && (
                <Button
                  className="btnBorderG"
                  disabled={disabled}
                  shape="round"
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    actions.add({
                      subExamCategoryLevel1Id: utils.partUtils.getPartValue(),
                      subExamCategoryLevel2Id: utils.sectionUtils.getAvailableSectionsValue()[0],
                      examExaminerLevel: [{}]
                    })
                  }}>
                  เพิ่ม Section
                </Button>
              )}
          </Space>
        </Col>
      </Row>
    </>
  )
}

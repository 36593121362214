import { Typography, Form, message, Row, Col, Button } from 'antd'
import LayoutPage from '../../components/Layout'
import SingleChoiceExamForm from '../../components/SingleChoiceExamForm'
import { useNavigate } from 'react-router-dom'
import { createExam, mediaExam } from '../../data/exam.data'
import { EXAM_CREATOR } from '../../constants/paths'
import { removePTag, removeClozeTag, setTheDefaultDimensionsForImage } from '../../lib/helpers'
import img from '../../Image/img_select_data.png'
import LectureChoiceExamForm from '../../components/LectureChoiceExamForm'
import ClozePassageExamForm from '../../components/ClozePassageExamForm'
import { useAuth } from '../../AuthorizationProvider'
import MatchingChoiceExamForm from '../../components/MatchingChoiceExamForm'
import SituationChoiceExamForm from '../../components/SituationChoiceExamForm'
import useRecord from './useRecord'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'

const { Text } = Typography

const initialValues = {
  examCreator: [
    {
      examChoice: [{ isCorrect: false }],
      examMatch: [{}],
      childExam: [{ examChoice: [{ isCorrect: false }] }]
    }
  ]
}

const AddExamination = () => {
  const { usePageAuth } = useAuth()

  usePageAuth(['create_exam'])
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const {
    mainExamCategoryId,
    subExamCategoryId,
    subExamCategoryLevel1Id,
    subExamCategoryLevel2Id,
    subExamCategoryLevel3Id,
    examType
  } = useRecord(form)
  const {
    examLevels,
    MainExamCategorySelector,
    SubExamCategorySelector,
    PartSelector,
    SectionSelector,
    SubSectionSelector,
    ExamTypeSelector
  } = useExamSelectors({
    mainExamCategoryId,
    subExamCategoryId,
    subExamCategoryLevel1Id,
    subExamCategoryLevel2Id,
    subExamCategoryLevel3Id
  })

  const [messageApi, contextHolder] = message.useMessage()

  const checkPreviewExamLevel = async (examCreator) => {
    for (let i = 0; i < examCreator.length; i++) {
      if (!examCreator[i].examLevelId) {
        messageApi.open({
          type: 'error',
          content: 'กรุณาเลือกระดับข้อสอบ'
        })
        return true
      }
    }
  }

  const onFinish = async (values) => {
    const { examCreator, ...others } = values
    const notHaveExamLevelId = await checkPreviewExamLevel(examCreator)

    if (!notHaveExamLevelId) {
      if (values.examType === 2) {
        examCreator.forEach((exam) => {
          const { detailsExam } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
        })
      } else if (values.examType === 3) {
        examCreator.forEach((exam) => {
          const { detailsExam, examMatch } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
          examMatch.forEach((match) => {
            const { questionText, answerText } = match
            match.questionText = setTheDefaultDimensionsForImage(questionText)
            match.answerText = setTheDefaultDimensionsForImage(answerText)
          })
        })
      } else if (values.examType === 4 || values.examType === 5) {
        examCreator.forEach((exam) => {
          const { detailsExam, childExam } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
          childExam.forEach((child) => {
            const { detailsExam, examChoice } = child
            child.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
            examChoice.forEach((choice) => {
              const { choiceName } = choice
              choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
            })
          })
        })
      } else {
        examCreator.forEach((exam) => {
          const { detailsExam, examChoice } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)

          examChoice.forEach((choice) => {
            const { choiceName } = choice
            choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
          })
        })
      }

      if (
        values.examType === 1 ||
        values.examType === 2 ||
        values.examType === 3 ||
        values.examType === 4
      ) {
        const body = examCreator.map((exam) => ({
          ...exam,
          ...others,
          detailsExam: exam.detailsExam
            ? values.examType === 4
              ? removeClozeTag(exam.detailsExam)
              : removePTag(exam.detailsExam)
            : '',
          examChoice: exam.examChoice?.map((choice) => ({
            ...choice,
            choiceName: choice.questionText ? removePTag(choice.choiceName) : ''
          })),
          examMatch: exam.examMatch?.map((match) => ({
            ...match,
            questionText: match.questionText ? removePTag(match.questionText) : '',
            answerText: match.answerText ? removePTag(match.answerText) : ''
          })),
          childExam:
            values.examType === 4
              ? exam.childExam?.map((child) => ({
                  ...child,
                  examChoice: child.examChoice?.map((choice) => ({
                    ...choice,
                    choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
                  }))
                }))
              : [],
          active: true
        }))

        Promise.all(body.map((b) => createExam(b)))
          .then(() => navigate(`${EXAM_CREATOR}/list`))
          .catch((error) => console.log(error))
      } else if (values.examType === 5) {
        if (values.examCreator[0].file !== undefined) {
          const bodyForm = new FormData()
          bodyForm.append('file', values.examCreator[0].file?.fileList[0].originFileObj)
          mediaExam(bodyForm).then((resp) => {
            console.log(resp)
            const bodyy = examCreator.map((exam) => ({
              ...exam,
              ...others,
              detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
              childExam: exam.childExam?.map((child) => ({
                ...child,
                detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
                examChoice: child.examChoice?.map((choice) => ({
                  ...choice,
                  choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
                }))
              })),
              mediaId: resp.data.id,
              active: exam.active
            }))
            Promise.all(
              bodyy.map((b) => {
                createExam(b)
              })
            )
              .then(() => navigate(`${EXAM_CREATOR}/list`))
              .catch((error) => console.log(error))
          })
        } else {
          const bodyy = examCreator.map((exam) => ({
            ...exam,
            ...others,
            detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
            childExam: exam.childExam?.map((child) => ({
              ...child,
              detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
              examChoice: child.examChoice?.map((choice) => ({
                ...choice,
                choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
              }))
            })),
            active: exam.active
          }))
          Promise.all(
            bodyy.map((b) => {
              createExam(b)
            })
          )
            .then(() => navigate(`${EXAM_CREATOR}/list`))
            .catch((error) => console.log(error))
        }
      }
    }
  }

  return (
    <>
      {contextHolder}

      <LayoutPage>
        <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
          <Row gutter={10}>
            <Col span={24}>
              <Text
                style={{
                  fontFamily: 'KanitM',
                  color: '#0B6252',
                  fontSize: '25px'
                }}>
                เพิ่มข้อสอบ
              </Text>
            </Col>

            <Col span={7}>
              <MainExamCategorySelector disabled={true} />
            </Col>

            <Col span={7}>
              <SubExamCategorySelector disabled={true} />
            </Col>

            <Col span={7}>
              <PartSelector disabled={true} />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={7}>
              <SectionSelector disabled={true} />
            </Col>

            <Col span={7}>
              <SubSectionSelector disabled={true} />
            </Col>

            <Col span={7}>
              <ExamTypeSelector disabled={true} />
            </Col>
          </Row>

          {examType === 1 && <SingleChoiceExamForm examLevels={examLevels} />}

          {examType === 2 && <LectureChoiceExamForm examLevels={examLevels} />}

          {examType === 3 && <MatchingChoiceExamForm examLevels={examLevels} />}

          {examType === 4 && <ClozePassageExamForm examLevels={examLevels} />}

          {examType === 5 && (
            <>
              <SituationChoiceExamForm examLevels={examLevels} />
            </>
          )}

          {!examType && <img src={img} style={{ display: 'block', margin: '0 auto' }} />}

          {(examType === 1 ||
            examType === 2 ||
            examType === 3 ||
            examType === 4 ||
            examType === 5) && (
            <Row justify="end">
              <Col style={{ padding: '25px 70px 0px 0px' }}>
                <Button className="btnGreen" shape="round" htmlType="sumit">
                  บันทึกข้อมูล
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </LayoutPage>
    </>
  )
}

export default AddExamination

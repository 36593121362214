import { Button, Collapse, Form, Popconfirm, Space, message } from 'antd'
import { styles } from './styles'
import { useEffect, useRef, useState } from 'react'
import { listRole as get, deleteRole as remove, updateRole } from '../../data/role.data'
import FunctionsList from './FunctionsList'
import { DeleteFilled, EditFilled, ExclamationCircleOutlined, SaveFilled } from '@ant-design/icons'

export default function useUpdate() {
  const [roles, setRoles] = useState()

  const fetchRoles = () => {
    get()
      .then((res) => {
        setRoles(res.data.items)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  return {
    fetchRoles,
    context: (
      <Collapse
        size="small"
        items={roles?.map((role) => ({
          key: role.id,
          label: role.name,
          children: <CollapsedItem role={role} fetchRoles={fetchRoles} />
        }))}
        style={styles.collapsedFunctionsList}
      />
    )
  }
}

const CollapsedItem = ({ role, fetchRoles }) => {
  const [form] = Form.useForm()
  const [editing, setEditing] = useState(false)
  const ignore = useRef(true)

  useEffect(() => {
    const { resources } = role
    form.setFieldsValue({ resources })
  }, [])

  useEffect(() => {
    if (!editing && !ignore.current) {
      updateRole(role.id, {
        name: role.name,
        normalizedName: role.name,
        resources: form.getFieldValue('resources')
      })
        .then(() => {
          message.success('บันทึกเรียบร้อยแล้ว')
        })
        .catch((error) => console.log(error))
    }
  }, [editing])

  const confirm = (id) => {
    remove(id)
      .then(() => {
        fetchRoles()
        message.success('ลบเรียบร้อยแล้ว')
      })
      .catch((error) => console.log(error))
  }

  return (
    <Form form={form}>
      <Space style={{ marginBottom: 15 }}>
        <Button
          className="btnedit"
          name={role.label}
          id={role.id}
          icon={editing ? <SaveFilled /> : <EditFilled />}
          onClick={() => {
            ignore.current = false
            setEditing(!editing)
          }}
        />
        <Popconfirm
          title="ลบข้อมูล"
          description="ยืนยันการลบข้อมูล"
          onConfirm={() => confirm(role.id)}
          okText="ตกลง"
          cancelText="ยกเลิก"
          icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}>
          <Button danger icon={<DeleteFilled />} />
        </Popconfirm>
      </Space>

      <FunctionsList disabled={!editing} resources={role.resources} />
    </Form>
  )
}

import { React, useEffect, useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Row, Col, Typography, Space, Button, Input, Table } from 'antd'
import LayoutPage from '../../components/Layout'
import useDataTable from '../../lib/useTable'
import VruModal from '../../components/Modal'
import Level3_Form from '../../components/Level3_Form'
import { listLevel3 } from '../../data/level3.data'
import { createColumnsLevel3 } from '../../lib/columns-types/exam-list-level3'
import { useAuth } from '../../AuthorizationProvider'
const { Text } = Typography

const Level_3 = () => {
  const { usePageAuth, currentUserCan } = useAuth()
  usePageAuth(['list_sub_exam_category_level3', 'get_sub_exam_category_level3'])
  const { data, tableParams, loading, mutateData, handleTableChange } = useDataTable(listLevel3)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <LayoutPage>
        <Row justify="space-between" gutter={[0, 20]}>
          <Col span={12}>
            <Text
              style={{
                fontFamily: 'KanitM',
                color: '#0B6252',
                fontSize: '25px'
              }}>
              จัดการ Sub Sections
            </Text>
          </Col>
          <Col span={12}>
            <Space style={{ display: 'flex', justifyContent: 'end' }}>
              {currentUserCan(['create_sub_exam_category_level2']) && (
                <Button
                  className="btnGreen"
                  shape="round"
                  icon={<PlusCircleOutlined />}
                  onClick={showModal}>
                  เพิ่มข้อมูล
                </Button>
              )}
            </Space>
          </Col>

          <Col span={24} style={{ textAlign: 'right' }}>
            <Table
              scroll={{ x: 'max-content' }}
              columns={createColumnsLevel3(mutateData)}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </LayoutPage>
      <VruModal title="เพิ่มข้อมูล" open={isModalOpen} handleCancel={handleCancel}>
        <Level3_Form mutateData={mutateData} handleCancel={handleCancel} />
      </VruModal>
    </>
  )
}

export default Level_3

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { examinerApi } from '../../lib/api'
import { client } from '../../lib/client'
import qs from 'qs'

const initialState = {
  items: [],
  remaining: [],
  record: null,
  status: 'idle',
  error: null
}

export const fetchExaminers = createAsyncThunk('examiners/fetchExaminers', async (query) => {
  const response = await client.get(`${examinerApi.preview()}?${qs.stringify(query)}`)
  return response.data.data.items
})

export const fetchRecord = createAsyncThunk('examiners/record', async (id) => {
  const { data } = await client.get(`${examinerApi.base}/${id}`)
  return data.data
})

const examinersSlice = createSlice({
  name: 'examiner',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    resetRecord: (state) => {
      state.record = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchExaminers.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchExaminers.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.items = action.payload
        state.remaining = action.payload
      })
      .addCase(fetchExaminers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

      .addCase(fetchRecord.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchRecord.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.record = action.payload
        state.remaining = action.payload
      })
      .addCase(fetchRecord.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export const { setStatus, resetRecord } = examinersSlice.actions

export const selectAllExaminers = (state) => state.examiner.items
export const selectRecord = (state) => state.examiner.record
export const selectItemKey = (state) => state.examiner.itemKey
export const selectActionsOfList = (state) => state.examiner.actionsOfList

export default examinersSlice.reducer

import { PlusOutlined, DeleteFilled } from '@ant-design/icons'
import { Button, Col, Form, Input, Radio, Row, Space, Typography } from 'antd'
import TextEditor2 from './TextEdit2'
import React from 'react'
import _ from 'lodash'
import TextEditor from './TextEdit'
const { Text } = Typography

export default function ClozeExamChoice({ name }) {
  const form = Form.useFormInstance()
  // const [parentIndex, childIndex] = name
  const nameChoice = name && name.slice(2, 4)

  return (
    <Form.Item shouldUpdate>
      {() => {
        const error = form.getFieldError(['examCreator'].concat(name))
        return (
          <Form.List
            name={nameChoice}
            rules={[
              {
                validator: async (field, values) => {
                  if (!(_(values).findIndex((value) => value.isCorrect) + 1))
                    return Promise.reject(new Error('กรุณาเลือกคำตอบด้วยคะ'))
                }
              }
            ]}>
            {(choices, { add, remove }) =>
              choices.map((choice) => (
                <React.Fragment key={choice.key}>
                  <Row gutter={8}>
                    <Col span={24} lg={16}>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 10
                        }}>
                        <Form.Item
                          name={[choice.name, 'isCorrect']}
                          valuePropName="checked"
                          style={{ marginBottom: 0 }}
                          {...(error.length && {
                            help: error[0],
                            validateStatus: 'error'
                          })}>
                          <Radio
                            onChange={(e) => {
                              let examChoice = form.getFieldValue(['examCreator'].concat(name))

                              form.setFieldValue(
                                ['examCreator'].concat(name),
                                examChoice.map((c, index) => ({
                                  ...c,
                                  isCorrect: index === choice.name
                                }))
                              )
                            }}>
                            ตัวเลือก
                          </Radio>
                        </Form.Item>
                        <div>
                          <Button
                            className="btnadd"
                            shape="circle"
                            size="small"
                            onClick={() => add({ isCorrect: false })}
                            icon={<PlusOutlined />}
                          />
                          <Text>เพิ่มตัวเลือก</Text>
                        </div>
                      </Space>
                    </Col>
                  </Row>
                  <Row align="middle" gutter={10}>
                    <Col span={24} lg={16}>
                      <TextEditor name={[choice.name, 'choiceName']} />
                    </Col>
                    {!!choice.name && (
                      <Col>
                        <Button
                          type="primary"
                          icon={<DeleteFilled />}
                          onClick={() => remove(choice.name)}
                          className="deleteBtn"
                        />
                      </Col>
                    )}
                  </Row>
                </React.Fragment>
              ))
            }
          </Form.List>
        )
      }}
    </Form.Item>
  )
}

/**
 * @param {import("../types/user").UserPropsType} user
 */
export const setTokens = (user) => {
  if (!user) return
  localStorage.setItem('token', user.token)
  localStorage.setItem('refresh', user.refreshToken)
}
export const setToken = (token) => {
  if (token) localStorage.setItem('token', token)
}
export const getToken = () => localStorage.getItem('token')
export const getRefreshToken = () => localStorage.getItem('refresh')
export const removeTokens = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
}
export const removeToken = () => localStorage.removeItem('token')

export const setUsersData = (user) => {
  if (!user) return
  localStorage.setItem('user', JSON.stringify(user))
}
export const getUsers = () => JSON.parse(localStorage.getItem('user'))

import { categoryApi } from '../lib/api'
import { fetchJsonWithToken } from '../lib/fethcer'

/**
 * @typedef {object} MainCat
 * @property {number} id
 * @property {string} name
 * @typedef {import("../lib/fethcer").IResponse<MainCat} IResponse<MainCat>
 */

/**
 * @returns {Promise<IResponse>}
 */
export const listMainExamCat = () => fetchJsonWithToken(categoryApi.base)

/**
 * @returns {Promise<import("../types/response.types").TResponse<any>>}
 */
export const listUsedMainExamCategory = () => fetchJsonWithToken(categoryApi.used())

export const permissions = [
  {
    label: 'ผู้ดูแลระบบ',
    tfunction: [
      'จัดการข้อมูลสมาชิก',
      'จัดการข้อมูลระดับข้อสอบ',
      'จัดหมวดหมู่ข้อสอบ',
      'จัดการสิทธิการใช้งาน',
      'จัดการ Log การใช้งาน',
      'ระบบคลังข้อสอบ',
      'จัดการอนุมัติคลังข้อสอบ',
      'จัดเก็บชุดข้อสอบ'
    ],

    actions: ['view', 'create', 'update', 'delete']
  },
  {
    label: 'ผู้จัดทำข้อสอบ',
    tfunction: [
      'จัดการข้อมูลสมาชิก',
      'จัดการข้อมูลระดับข้อสอบ',
      'จัดหมวดหมู่ข้อสอบ',
      'จัดการสิทธิการใช้งาน',
      'จัดการ Log การใช้งาน',
      'ระบบคลังข้อสอบ',
      'จัดการอนุมัติคลังข้อสอบ',
      'จัดเก็บชุดข้อสอบ'
    ],

    actions: ['view', 'create', 'update', 'delete']
  },
  {
    label: 'ผู้ออกข้อสอบ',
    tfunction: [
      'จัดการข้อมูลสมาชิก',
      'จัดการข้อมูลระดับข้อสอบ',
      'จัดหมวดหมู่ข้อสอบ',
      'จัดการสิทธิการใช้งาน',
      'จัดการ Log การใช้งาน',
      'ระบบคลังข้อสอบ',
      'จัดการอนุมัติคลังข้อสอบ',
      'จัดเก็บชุดข้อสอบ'
    ],

    actions: ['view', 'create', 'update', 'delete']
  }
]

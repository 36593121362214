import { React, useState } from 'react'
import { Form, Switch } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import { activeUser } from '../data/user.data'

const layout = {
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 16
  }
}
export default function ActiveSwitch({ record, mutateData = () => {} }) {
  const [confirm, setConfirm] = useState(false)

  const [form] = useForm()

  const onFinish = async () => {
    const values = form.getFieldsValue()
    await activeUser(record.id, values)
    mutateData()
    setConfirm(false)
  }

  return (
    <Form
      form={form}
      onFinish={() => setConfirm(true)}
      {...layout}
      initialValues={{
        active: record?.active
      }}>
      <Form.Item name="active" valuePropName="checked">
        <Switch
          onChange={() => setConfirm(true)}
          checkedChildren="on"
          unCheckedChildren="off"></Switch>
      </Form.Item>
      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
          // setConfirm(false)
          // handleCancel()
        }}
        fallback={() => {
          setConfirm(false)
          mutateData()
        }}></Confirmation>
    </Form>
  )
}

import { Button, Modal, Row, Col, Input, Form } from 'antd'
import errorRed from '../Image/errorRed.png'

export default function ConfirmationApprove({
  open = false,
  record,
  callback = () => {},
  fallback = () => {}
}) {
  return (
    <Modal
      destroyOnClose
      open={open}
      onCancel={fallback}
      footer={
        <Row justify={'center'}>
          <Button shape="round" className="btnBGreen" onClick={fallback}>
            ยกเลิก
          </Button>
          <Button className="btnGreen" shape="round" type="primary" onClick={callback}>
            ยืนยัน
          </Button>
        </Row>
      }>
      <Row justify={'center'}>
        <Col span={24}>
          <img style={{ margin: '10% 0% 0% 40%' }} src={errorRed} />
        </Col>
        <Col span={24}>
          <p
            style={{
              textAlign: 'center',
              fontFamily: 'KanitM',
              fontSize: '20px',
              margin: '3% 0% 0% 0%'
            }}>
            เหตุผลการไม่อนุมัติ
            <br />
            <Row>
              <Col span={24} style={{ padding: '10px' }}>
                <Form initialValues={{ remark: record?.remark }}>
                  <Form.Item name="remark">
                    <Input disabled />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </p>
        </Col>
      </Row>
    </Modal>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
import 'quill/dist/quill.snow.css'
import ReactQuill, { Quill } from 'react-quill'
// import ImageResize from 'quill-image-resize-module-react'
import BlotFormatter from 'quill-blot-formatter'
import katex from 'katex'
import 'katex/dist/katex.min.css'
window.katex = katex

import '../lib/jquery'
import '@edtr-io/mathquill/build/mathquill.js'
import '@edtr-io/mathquill/build/mathquill.css'

import mathquill4quill from '../lib/mathquill4quill'

// Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/blotFormatter', BlotFormatter)

const CUSTOM_OPERATORS = [
  ['\\pm', '\\pm'],
  ['\\sqrt{x}', '\\sqrt'],
  // ['\\sqrt[3]{x}', '\\sqrt[3]{}'],
  ['\\sqrt[n]{x}', '\\nthroot'],
  // ['\\frac{x}{y}', '\\frac'],
  // ['\\sum^{s}_{x}{d}', '\\sum'],
  // ['\\prod^{s}_{x}{d}', '\\prod'],
  // ['\\coprod^{s}_{x}{d}', '\\coprod'],
  // ['\\int^{s}_{x}{d}', '\\int'],
  // ['\\binom{n}{k}', '\\binom'],
  ['\\subset', '\\subset'],
  ['\\supset', '\\supset'],
  ['\\in', '\\in'],
  ['\\ge', '\\ge'],
  ['\\le', '\\le'],
  ['\\ne', '\\ne'],
  ['\\cup', '\\cup'],
  ['\\cap', '\\cap']
]

const TextEditor = ({ name }) => {
  const form = Form.useFormInstance()
  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef.current) {
      const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex })
      enableMathQuillFormulaAuthoring(quillRef.current?.getEditor(), {
        operators: CUSTOM_OPERATORS
      })
    }
  }, [])

  return (
    <Form.Item name={name} rules={[{ required: true, message: 'กรอกคำถามด้วยค่ะ' }]}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="write your content ...."
        className="question-editor"
      />
    </Form.Item>
  )
}

export default TextEditor

const modules = {
  formula: true,
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [({ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' })],
    ['link', 'image', 'video', 'formula'],
    [{ script: 'sub' }, { script: 'super' }],
    // [{ formula: 'katex' }],
    [
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          'custom-color'
        ]
      }
    ],
    [{ background: [] }],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  },
  // imageResize: {
  //   parchment: Quill.import('parchment'),
  //   modules: ['Resize', 'DisplaySize']
  // },
  blotFormatter: {}
}

const formats = [
  'header',
  'height',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'color',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
  'size',
  'script',
  'formula'
]

const base = '/'
const main = '/main'
const examination = '/examination'

const examManagement = {
  index: `${examination}/exam-management`
}

const examPublisher = {
  base: `${examination}/exam-publisher`,
  list: function () {
    return `${this.base}/list`
  },
  /**
   * @param {1 | 0} man
   */
  create: function (man) {
    return `${this.base}/create${man ? `?man=${man}` : ``}`
  },
  update: function (id, man) {
    return `${this.base}/${id ? id : ':id'}/update${man ? `?man=${man}` : ``}`
  },
  view: function (id) {
    return `${this.base}/${id ? id : ':id'}/view`
  }
}

const portals = {
  base,
  main,
  examManagement,
  examPublisher
}

export default portals

import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const listLevel3 = () => fetchJsonWithToken(env.level3Api)
export const deleteLevel3 = (id) =>
  fetchJsonWithToken(`${env.level3Api}/${id}/delete`, {
    method: 'DELETE'
  })
export const createlistExamLevel3 = (values) =>
  fetchJsonWithToken(env.level3Api, {
    method: 'POST',
    body: JSON.stringify(values)
  })

export const updateLevel3 = (id, values) =>
  fetchJsonWithToken(`${env.level3Api}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })

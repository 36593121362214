import { React } from 'react'
import { Row, Col, Typography, Table, DatePicker } from 'antd'
import '../style/main.css'
import LayoutPage from '../components/Layout'
import useDataTable from '../lib/useDataTable'
import { geteLog } from '../data/log.data'
import th from 'antd/es/date-picker/locale/th_TH'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import { useAuth } from '../AuthorizationProvider'
import buddhistEra from 'dayjs/plugin/buddhistEra'

dayjs.extend(buddhistEra)

const { Text } = Typography

const buddhistLocale = {
  ...th,
  lang: {
    ...th.lang,
    fieldDateFormat: 'BBBB-MM-DD',
    fieldDateTimeFormat: 'BBBB-MM-DD HH:mm:ss',
    yearFormat: 'BBBB',
    cellYearFormat: 'BBBB'
  }
}

const LogPage = () => {
  const { usePageAuth } = useAuth()
  usePageAuth(['list_log'])

  const { dataSource, tableParams, loading, setTableParams, handleTableChange } =
    useDataTable(geteLog)

  return (
    <>
      <LayoutPage>
        <Row gutter={[0, 10]} justify="space-between">
          <Col span={12}>
            <Text style={{ fontFamily: 'KanitM', color: '#0B6252', fontSize: '25px' }}>
              จัดการ Log การใช้งาน
            </Text>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <DatePicker
              onChange={(date, dateStr) => {
                if (dateStr) {
                  setTableParams((prev) => ({
                    ...prev,
                    pagination: { ...prev.pagination, current: 1 },
                    filters: {
                      date: date.format('YYYY-MM-DD')
                    }
                  }))
                } else {
                  setTableParams((prev) => ({
                    ...prev,
                    pagination: { ...prev.pagination, current: 1 },
                    filters: {}
                  }))
                }
              }}
              style={{ marginBottom: 10 }}
              locale={buddhistLocale}
            />

            <Table
              columns={createColumnsType()}
              rowKey={(record) => record.id}
              dataSource={dataSource}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </LayoutPage>
    </>
  )
}

export default LogPage
const createColumnsType = () => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    responsive: ['sm'],
    align: 'center'
  },
  {
    title: 'ชื่อ-นามสกุล',
    dataIndex: ['fname', 'lname'],
    responsive: ['sm'],
    align: 'center',
    render: (_, record) => `${record.users?.fname} - ${record.users?.lname}`
  },
  {
    title: 'ฟังก์ชั่นการใช้งาน',
    dataIndex: 'function',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) =>
      `${record.description?.Functionality}` ? `${record.description?.Functionality}` : '-'
  },
  {
    title: 'รายละเอียด',
    dataIndex: 'detail',
    align: 'center',
    render: (_, record) =>
      `${record.description?.details}` ? `${record.description?.details}` : '-'
  },
  {
    title: 'เมื่อวันที่',
    dataIndex: 'createdAt',
    align: 'center',
    render: (_, record) => dayjs(record.createdAt).locale('th').format('DD MMMM BBBB LT น.')
  }
]

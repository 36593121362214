import { Form, Select } from 'antd'

export default function SubCatSelector({
  subCat = [],
  handleSubCatChange = () => {},
  view = false,
  ...props
}) {
  const { style, other } = props
  const form = Form.useFormInstance()

  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        const mainExamValue = form.getFieldValue('mainExamCategoryId')

        return (
          <Form.Item
            name="subExamCategoryId"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบย่อย!'
              }
            ]}
            label="เลือกชื่อหมวดหมู่ข้อสอบย่อย :"
            style={style}>
            <Select
              onChange={(id) => {
                handleSubCatChange(id)
                form.resetFields(['subExamCategoryLevel1Id'])
              }}
              placeholder="เลือกหมวดหมู่ย่อย"
              options={subCat?.map((sexam) => ({
                label: sexam.subExamCategoryName,
                value: sexam.id
              }))}
              disabled={!mainExamValue || view}
              {...other}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

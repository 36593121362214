import { React, useState, useEffect } from 'react'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import { listRoles } from '../data/user.data'
import { ResetPasswordUser } from '../data/reset-password.data'

export default function ChangePassUser({
  view = false,
  record,
  handleCancel = () => {},
  mutateData = () => {}
}) {
  const [confirm, setConfirm] = useState(false)
  const [roles, setRoles] = useState([])
  const [form] = useForm()
  const [checkIdError, setCheckIdError] = useState()
  const [show, setShow] = useState()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleUpdate = async (values) => {
    try {
      await ResetPasswordUser(values)
      mutateData()
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const onFinish = () => {
    const values = form.getFieldsValue()
    handleUpdate(values)
    console.log(values)
  }

  useEffect(() => {
    listRoles()
      .then((res) => setRoles(res.data.items))
      .catch((error) => console.log(error))
  }, [])

  return (
    <Form form={form} layout="vertical" onFinish={() => setConfirm(true)}>
      {/* <Content style={{ padding: '0px 20px 10px 20px' }}> */}
      <Row gutter={[10, 0]}>
        <Col span={24}>
          <Form.Item
            label="รหัสผ่านปัจจุบัน"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกรหัสผ่านปัจจุบัน!'
              }
            ]}>
            <Input.Password
              className="inputtxt2"
              placeholder="กรุณากรอกรหัสผ่านปัจจุบัน"
              //disabled={view}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="รหัสผ่านใหม่"
            name="newPassword"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกรหัสผ่านใหม่!'
              }
            ]}>
            <Input.Password
              className="inputtxt2"
              placeholder="กรุณากรอกรหัสผ่านใหม่"
              //disabled={view}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="ยืนยันรหัสผ่านใหม่"
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: ' '
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('กรอกรหัสผ่านไม่ตรง'))
                }
              })
            ]}>
            <Input.Password
              className="inputtxt2"
              placeholder="กรุณากรอกยืนยันรหัสผ่านใหม่"
              //disabled={view}
            />
          </Form.Item>
        </Col>
      </Row>

      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        <Button shape="round" className="btnBGreen" onClick={handleCancel}>
          ยกเลิก
        </Button>
        <Button className="btnGreen" shape="round" type="primary" htmlType="sumit">
          บันทึกข้อมูล
        </Button>
      </Space>
      {/* </Content> */}

      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
          setConfirm(false)
          handleCancel()
        }}
        fallback={() => {
          setConfirm(false)
        }}></Confirmation>
    </Form>
  )
}

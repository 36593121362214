import { React, useEffect, useState } from 'react'
import { Form, Card, Table, Row, Col, Button, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import '../../style/main.css'
import LayoutPage from '../../components/Layout'
import { getExaminerApprove } from '../../data/examiner.data'
import Filters from './Filters'
import { createColumnsType } from './columnsType'
import { useAuth } from '../../AuthorizationProvider'
import { APPORVE_RECORD } from '../../constants/paths'
import useDataTable from '../../lib/useDataTable'
const { Text } = Typography
const ExamApprovalList = () => {
  const { usePageAuth, currentUserCan } = useAuth()
  usePageAuth(['list_approve_exam', 'get_approve_exam'])
  const [form] = Form.useForm()
  const onFinish = (value) => {
    setTableParams({
      where: {
        mainExamCategoryId: value.mainExamCategoryId,
        subExamCategoryId: value.subExamCategoryId,
        examType: value.examType
      }
    })
  }
  const { dataSource, tableParams, loading, refetch, handleTableChange, setTableParams } =
    useDataTable(getExaminerApprove)

  return (
    <LayoutPage>
      <Row gutter={[0, 24]} justify="space-between">
        <Col span={12}>
          <Text style={{ fontFamily: 'KanitM', color: '#0B6252', fontSize: '25px' }}>
            จัดการอนุมัติคลังข้อสอบ
          </Text>
        </Col>
        <Col span={12}>
          <Row justify="end">
            {currentUserCan(['list_approve_exam', 'get_approve_exam']) && (
              <Button
                href={`${APPORVE_RECORD}`}
                className="btnGreen"
                shape="round"
                icon={<CheckCircleOutlined />}>
                ประวัติการอนุมัติ
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Filters form={form} onFinish={onFinish} />
      <Card>
        <Table
          scroll={{ x: 'max-content' }}
          columns={createColumnsType(refetch, currentUserCan)}
          rowKey={(record) => record.id}
          dataSource={dataSource}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>
    </LayoutPage>
  )
}

export default ExamApprovalList

import React from 'react'
import { Col, Form, Row } from 'antd'
import 'react-quill/dist/quill.snow.css'
import { Utils } from './utils/Utils'
import NumberOfExams from './NumberOfExams'
import NumberOfSets from './NumberOfSets'
import ExamLevels from './ExamLevels'
import Parts from './Parts'
import Sections from './Sections'
import SubSections from './SubSections'
import Examiners from './Examiners'
import ExamTypeSelector from './ExamTypes'
import { useSelector } from 'react-redux'
import { selectAllExaminers, selectRecord } from '../../features/examiners/examinersSlice'
import ExamsList from './Exams'
import TotalNumberOfExams from './TotalNumberOfExams'
import Random from './Random'

/**
 * @param {object} props
 * @param {boolean} props.disabled
 */
export default function Fields({ disabled }) {
  const form = Form.useFormInstance()

  return (
    <>
      <Random disabled={disabled} />

      <Form.Item shouldUpdate={(prev, cur) => prev.isRandom !== cur.isRandom}>
        {() => {
          const isRandom = form.getFieldValue('isRandom')

          return (
            <>
              {isRandom && <NumberOfSets disabled={disabled} />}

              <Form.Item
                noStyle
                shouldUpdate={(prev, cur) =>
                  JSON.stringify(prev.section) !== JSON.stringify(cur.section)
                }>
                {() => (
                  <Form.List name="section">
                    {(fields, actions) =>
                      fields.map((field) => (
                        <Item
                          key={field.key}
                          field={field}
                          actions={actions}
                          isRandom={isRandom}
                          disabled={disabled}
                        />
                      ))
                    }
                  </Form.List>
                )}
              </Form.Item>
            </>
          )
        }}
      </Form.Item>
    </>
  )
}

/**
 * @param {object} props
 * @param {import("./types").FieldPropsType} props.field
 * @param {import('./types').ActionPropsType} props.actions
 * @param {boolean} props.isRandom
 */
const Item = (props) => {
  const examiners = useSelector(selectAllExaminers)
  const record = useSelector(selectRecord)
  const utils = new Utils(props.field, examiners, record)

  return (
    <div style={{ marginBottom: 20, paddingBottom: 20, borderBottom: 'solid 1px #0000001a' }}>
      <Parts {...props} utils={utils} />

      <Sections {...props} utils={utils} />

      <SubSections {...props} utils={utils} />

      <ExamTypeSelector {...props} utils={utils} />

      <Examiners {...props} utils={utils} />

      {props.isRandom ? (
        <>
          <Row gutter={15}>
            <Col>
              <TotalNumberOfExams {...props} utils={utils} />
            </Col>
            <Col>
              <NumberOfExams {...props} utils={utils} />
            </Col>
          </Row>

          <ExamLevels {...props} utils={utils} />
        </>
      ) : (
        <>
          <TotalNumberOfExams {...props} utils={utils} />
          <ExamsList {...props} utils={utils} />
        </>
      )}
    </div>
  )
}

import { permissionApi } from '../lib/api'
import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

/**
 * @returns {Promise<import('../types/response.types').TResponse<any>>}
 */
export const listPermissions = () => fetchJsonWithToken(permissionApi)
export const createPermissions = (values) =>
  fetchJsonWithToken(env.rolesApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const updatePermissions = (id, values) =>
  fetchJsonWithToken(`${env.rolesApi}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })
export const deletePermissions = (id) =>
  fetchJsonWithToken(`${env.rolesApi}/${id}/delete`, {
    method: 'DELETE'
  })

import { Form, Select } from 'antd'

export default function ExamTypeSelectorComp({ noRules, disabled, style = {} }) {
  return (
    <Form.Item
      name="examType"
      label="เลือกประเภทชุดข้อสอบ :"
      style={style}
      {...(!noRules && {
        rules: [
          {
            required: true,
            message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบย่อย!'
          }
        ]
      })}>
      <Select
        placeholder="เลือกประเภทชุดข้อสอบ "
        options={EXAM_TYPES.map((type) => ({ ...type, label: type.name }))}
        disabled={disabled}
        allowClear={true}
      />
    </Form.Item>
  )
}

export const EXAM_TYPES = [
  { value: 1, name: 'ประเภทชุดข้อสอบคำตอบเดียว (ปรนัย)' },
  { value: 2, name: 'ประเภทชุดข้อสอบบรรยาย (อัตนัย)' },
  { value: 3, name: 'ประเภทชุดข้อสอบจับคู่' },
  { value: 4, name: 'ประเภทชุดข้อสอบ Cloze passage' },
  { value: 5, name: 'ประเภทชุดข้อสอบคำตอบเดียว (มีข้อย่อย)' }
]

const apiBaseUrl = process.env.REACT_APP_BASE_URL
const authApi = apiBaseUrl + process.env.REACT_APP_AUTH_PATH
const outApi = apiBaseUrl + process.env.REACT_APP_OUT_PATH
const userApi = apiBaseUrl + process.env.REACT_APP_USERS_URL
const examLevelApi = apiBaseUrl + process.env.REACT_APP_EXAM_LEVEL_URL
const mainExamApi = apiBaseUrl + process.env.REACT_APP_MAIN_EXAM_CATEGORY_URL
const subExamApi = apiBaseUrl + process.env.REACT_APP_SUB_EXAM_CATEGORY_URL
const importusersApi = apiBaseUrl + process.env.REACT_APP_IMPORT_USERS_URL
const rolesApi = apiBaseUrl + process.env.REACT_APP_ROLE_URL
const logApi = apiBaseUrl + process.env.REACT_APP_LOG_URL
const examApi = apiBaseUrl + process.env.REACT_APP_EXAM_URL
const examChoiceApi = apiBaseUrl + process.env.REACT_APP_EXAM_CHOICE_URL
const logoutApi = apiBaseUrl + process.env.REACT_APP_LOGOUT_URL
const level1Api = apiBaseUrl + process.env.REACT_APP_SUBEXAM_LEVEL1_URL
const level2Api = apiBaseUrl + process.env.REACT_APP_SUBEXAM_LEVEL2_URL
const level3Api = apiBaseUrl + process.env.REACT_APP_SUBEXAM_LEVEL3_URL
const resetpassApi = apiBaseUrl + process.env.REACT_APP_RESET_PASSWORD_URL
const forgotApi = apiBaseUrl + process.env.REACT_APP_FORGOT_PASSWORD_URL
const storeQuestionApi = apiBaseUrl + process.env.REACT_APP_STORE_QUESTION_PATH
const resetPassapi = apiBaseUrl + process.env.REACT_APP_RESET_PASSWORD_URL
const questionStoreStatic = process.env.REACT_APP_STATIC_URL
const permissionsApi = apiBaseUrl + process.env.REACT_APP_PERMISSIONS_URL

// const storeApi = {
//   base: `${apiBaseUrl}/store-question`,
//   storeQuestionById: function (id) {
//     return `${this.base}/${id}/pdf`
//   }
// }

const env = {
  apiBaseUrl,
  authApi,
  outApi,
  userApi,
  examLevelApi,
  mainExamApi,
  subExamApi,
  importusersApi,
  rolesApi,
  permissionsApi,
  logApi,
  examApi,
  examChoiceApi,
  logoutApi,
  level1Api,
  level2Api,
  level3Api,
  mainExamCatApi: `${apiBaseUrl}/main-exam-category`,
  questionStorageApi: `${apiBaseUrl}/store-question`,
  questionStoreStatic,
  forgotApi,
  storeQuestionApi,
  resetPassapi
  // storeApi
}

export default env

import { React } from 'react'
import { Row, Col, Typography, Form, Button, Alert, message } from 'antd'
import LayoutPage from '../../components/Layout'
import { createExam, deleteExam, mediaExam, updateExam } from '../../data/exam.data'
import { useNavigate } from 'react-router-dom'
import SingleChoiceExamForm from '../../components/SingleChoiceExamForm'
import LectureChoiceExamForm from '../../components/LectureChoiceExamForm'
import MatchingChoiceExamForm from '../../components/MatchingChoiceExamForm'
import ClozePassageExamForm from '../../components/ClozePassageExamForm'
import { EXAM_CREATOR } from '../../constants/paths'
import { removeClozeTag, setTheDefaultDimensionsForImage } from '../../lib/helpers'
import img from '../../Image/img_select_data.png'
import SituationChoiceExamForm from '../../components/SituationChoiceExamForm'
import { useAuth } from '../../AuthorizationProvider'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'
import useRecord from './useRecord'

const { Text } = Typography

const UpdateExaminations = () => {
  const { usePageAuth } = useAuth()
  usePageAuth(['update_exam'])
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const {
    record,
    mainExamCategoryId,
    subExamCategoryId,
    subExamCategoryLevel1Id,
    subExamCategoryLevel2Id,
    subExamCategoryLevel3Id,
    examType
  } = useRecord(form, 1)
  const {
    examLevels,
    MainExamCategorySelector,
    SubExamCategorySelector,
    PartSelector,
    SectionSelector,
    SubSectionSelector,
    ExamTypeSelector
  } = useExamSelectors({
    mainExamCategoryId,
    subExamCategoryId,
    examType,
    subExamCategoryLevel1Id,
    subExamCategoryLevel2Id,
    subExamCategoryLevel3Id
  })

  const [messageApi, contextHolder] = message.useMessage()

  const compareDelete = (body, record) => {
    if (examType === 4 || examType === 5) {
      const bodyArr = []
      const recordArr = []
      body.map((values) => values.childExam.map((value) => bodyArr.push(value)))
      record.map((values) => values.childExam.map((value) => recordArr.push(value)))
      return recordArr.filter((value1) => !bodyArr.some((value2) => value1.id === value2.id))
    } else {
      return record.filter((value1) => !body.some((value2) => value1.id === value2.id))
    }
  }

  const checkPreviewExamLevel = async (examCreator) => {
    for (let i = 0; i < examCreator.length; i++) {
      if (!examCreator[i].examLevelId) {
        messageApi.open({
          type: 'error',
          content: 'กรุณาเลือกระดับข้อสอบ'
        })
        return true
      }
    }
  }

  const onFinish = async (values) => {
    const { examCreator, ...others } = values
    let deletedExam
    const notHaveExamLevelId = await checkPreviewExamLevel(examCreator)

    if (!notHaveExamLevelId) {
      if (values.examType === 2) {
        examCreator.forEach((exam) => {
          const { detailsExam } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
        })
      } else if (values.examType === 3) {
        examCreator.forEach((exam) => {
          const { detailsExam, examMatch } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
          examMatch.forEach((match) => {
            const { questionText, answerText } = match
            match.questionText = setTheDefaultDimensionsForImage(questionText)
            match.answerText = setTheDefaultDimensionsForImage(answerText)
          })
        })
      } else if (values.examType === 4 || values.examType === 5) {
        examCreator.forEach((exam) => {
          const { detailsExam, childExam } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
          childExam.forEach((child) => {
            const { detailsExam, examChoice } = child
            child.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
            examChoice.forEach((choice) => {
              const { choiceName } = choice
              choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
            })
          })
        })
      } else {
        examCreator.forEach((exam) => {
          const { detailsExam, examChoice } = exam
          exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)

          examChoice.forEach((choice) => {
            const { choiceName } = choice
            choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
          })
        })
      }

      if (examType === 1 || examType === 2 || examType === 3) {
        const body = examCreator.map((exam, i) => ({
          // ...(record[i]?.id && { id: record[i]?.id }),
          ...exam,
          ...others,
          // detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
          examChoice: exam.examChoice?.map((choice) => ({
            ...choice
            // choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
          })),
          examMatch: exam.examMatch?.map((match) => ({
            ...match
            // questionText: match.questionText ? removePTag(match.questionText) : '',
            // answerText: match.answerText ? removePTag(match.answerText) : ''
          })),
          childExam: [],
          active: exam.active
        }))
        deletedExam = await compareDelete(body, record)
        Promise.all(
          deletedExam && deletedExam.map((a) => deleteExam(a.id)),
          body.map((b) => {
            if (b?.id) {
              let { id, ...others } = b
              updateExam(id, others)
            } else createExam(b)
          })
        )
          .then(() => navigate(`${EXAM_CREATOR}/list`))
          .catch((error) => console.log(error))
      } else if (examType === 4) {
        const body = examCreator.map((exam, i) => ({
          ...(record[i]?.id && { id: record[i]?.id }),
          ...exam,
          ...others,
          detailsExam: exam.detailsExam ? removeClozeTag(exam.detailsExam) : '',
          childExam: exam.childExam?.map((child) => ({
            ...child,
            examChoice: child.examChoice?.map((choice) => ({
              ...choice
              // choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
            }))
          })),
          active: exam.active
        }))
        deletedExam = await compareDelete(body, record)
        Promise.all(
          deletedExam && deletedExam.map((a) => deleteExam(a.id)),
          body.map((b) => {
            if (b?.id) {
              let { id, ...others } = b
              updateExam(id, others)
            } else createExam(b)
          })
        )
          .then(() => navigate(`${EXAM_CREATOR}/list`))
          .catch((error) => console.log(error))
      } else if (examType === 5) {
        if (values.examCreator[0].file !== undefined) {
          const bodyForm = new FormData()
          bodyForm.append('file', values.examCreator[0].file?.fileList[0].originFileObj)
          mediaExam(bodyForm).then((resp) => {
            const body = examCreator.map((exam, i) => ({
              // ...(record[i]?.id && { id: record[i]?.id }),
              ...exam,
              ...others,
              // detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
              childExam: exam.childExam?.map((child) => ({
                ...child,
                // detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
                examChoice: child.examChoice?.map((choice) => ({
                  ...choice
                  // choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
                }))
              })),
              mediaId: resp.data?.id,
              active: exam.active
            }))
            deletedExam = compareDelete(body, record)
            Promise.all(
              deletedExam && deletedExam.map((a) => deleteExam(a.id)),
              body.map((b) => {
                if (b?.id) {
                  let { id, ...others } = b
                  updateExam(id, others)
                } else createExam(b)
              })
            )
              .then(() => navigate(`${EXAM_CREATOR}/list`))
              .catch((error) => console.log(error))
          })
        } else {
          const body = examCreator.map((exam, i) => ({
            // ...(record[i]?.id && { id: record[i]?.id }),
            ...exam,
            ...others,
            // detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
            childExam: exam.childExam?.map((child) => ({
              ...child,
              // detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
              examChoice: child.examChoice?.map((choice) => ({
                ...choice
                // choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
              }))
            })),
            mediaId: record.media?.id === null ? '' : record.media?.id,
            active: exam.active
          }))
          deletedExam = compareDelete(body, record)
          Promise.all(
            deletedExam && deletedExam.map((a) => deleteExam(a.id)),
            body.map((b) => {
              if (b?.id) {
                let { id, ...others } = b
                updateExam(id, others)
              } else createExam(b)
            })
          )
            .then((res) => {
              console.log(res)
            })
            .then(() => navigate(`${EXAM_CREATOR}/list`))
            .catch((error) => console.log(error))
        }
      }
    }
  }

  return (
    <>
      {contextHolder}

      <LayoutPage>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={10}>
            <Col span={24}>
              <Text
                style={{
                  fontFamily: 'KanitM',
                  color: '#0B6252',
                  fontSize: '25px'
                }}>
                แก้ไขชุดข้อสอบ
              </Text>
            </Col>

            <Col span={7}>
              <MainExamCategorySelector disabled={true} />
            </Col>

            <Col span={7}>
              <SubExamCategorySelector disabled={true} />
            </Col>

            <Col span={7}>
              <PartSelector disabled={true} />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={7}>
              <SectionSelector disabled={true} />
            </Col>

            <Col span={7}>
              <SubSectionSelector disabled={true} />
            </Col>

            <Col span={7}>
              <ExamTypeSelector disabled={true} />
            </Col>
          </Row>
          <>
            {record.length ? (
              <>
                {examType === 1 && <SingleChoiceExamForm record={record} examLevels={examLevels} />}
                {examType === 2 && (
                  <LectureChoiceExamForm record={record} examLevels={examLevels} />
                )}
                {examType === 3 && (
                  <MatchingChoiceExamForm record={record} examLevels={examLevels} />
                )}
                {examType === 4 && <ClozePassageExamForm record={record} examLevels={examLevels} />}
                {examType === 5 && (
                  <SituationChoiceExamForm record={record} examLevels={examLevels} />
                )}
                {!examType && <img src={img} style={{ display: 'block', margin: '0 auto' }} />}
                {(examType === 1 ||
                  examType === 2 ||
                  examType === 3 ||
                  examType === 4 ||
                  examType === 5) && (
                  <Row justify="end">
                    <Col style={{ padding: '25px 80px 0px 0px' }}>
                      <Button className="btnGreen" shape="round" htmlType="sumit">
                        บันทึกข้อมูล
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                <Alert message="Error" description="ไม่พบข้อมูลค่ะ" type="error" showIcon />
              </>
            )}
          </>
        </Form>
      </LayoutPage>
    </>
  )
}

export default UpdateExaminations

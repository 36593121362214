import { Col, Form, Row, Select, Space } from 'antd'

/**
 * @param {object} props
 * @param {import("./types").ActionPropsType} props.actions
 * @param {import("./types").FieldPropsType} props.field
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {boolean} props.disabled
 */
export default function ExamTypeSelector({ utils, disabled }) {
  const form = Form.useFormInstance()

  return (
    <Row>
      <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
        <Space>
          <Form.Item
            name={utils.examTypeUtils.pathOfExamType}
            rules={[
              {
                required: true,
                message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบย่อย!'
              }
            ]}
            label="ประเภทข้อสอบ"
            style={{ margin: '10px 5px', minWidth: 300 }}>
            <Select
              placeholder="เลือกประเภทชุดข้อสอบ "
              options={utils.examTypeUtils.getExamTypeOptions()}
              disabled={disabled || !utils.examTypeUtils.getExamTypeOptions().length}
              onChange={() => {
                form.resetFields([
                  utils.examinerUtils.fullPathOfUsersId,
                  utils.examUtils.fullPathOfNumberOfExamsRequired,
                  utils.examLevelUtils.fullPathOfExamLevel
                ])
              }}
              allowClear={true}
            />
          </Form.Item>
        </Space>
      </Col>
    </Row>
  )
}

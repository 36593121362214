import { React, useState } from 'react'
import { Layout, Row, Col, Typography, Button, Table } from 'antd'
import 'dayjs/locale/th'
import dayjs from 'dayjs'
import { PlusCircleOutlined, EditFilled, DeleteFilled } from '@ant-design/icons'
import '../../style/main.css'
import LayoutPage from '../../components/Layout'
import VruModal from '../../components/Modal'
import { deleteMainExam, listMainExam } from '../../data/mainexam.data'
import useDataTable from '../../lib/useTable'
import ConfirmationDelete from '../../components/ConfirmDelete'
import MainExamForm from '../../components/MainExamForm'
// import getColumnSearchProps from '../../lib/columns-types/columnSearch'
import { useAuth } from '../../AuthorizationProvider'
const { Text } = Typography

const MainExamCategory = () => {
  const { usePageAuth, currentUserCan } = useAuth()
  usePageAuth(['list_main_exam_category', 'get_main_exam_category'])
  const { data, tableParams, loading, mutateData, handleTableChange } = useDataTable(listMainExam)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <LayoutPage>
        <Row justify="space-between" gutter={[0, 20]}>
          <Col span={12}>
            <Text style={{ fontFamily: 'KanitM', color: '#0B6252', fontSize: '25px' }}>
              จัดการหมวดหมู่ข้อสอบหลัก
            </Text>
          </Col>
          <Col>
            {currentUserCan(['create_main_exam_category']) && (
              <Button
                className="btnGreen"
                shape="round"
                icon={<PlusCircleOutlined />}
                onClick={showModal}>
                เพิ่มข้อมูล
              </Button>
            )}
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Table
              scroll={{ x: 'max-content' }}
              columns={createColumnsType(mutateData, currentUserCan)}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </LayoutPage>
      <VruModal title="เพิ่มข้อมูล" open={isModalOpen} handleCancel={handleCancel}>
        <MainExamForm mutateData={mutateData} handleCancel={handleCancel} />
      </VruModal>
    </>
  )
}

export default MainExamCategory
const createColumnsType = (mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    responsive: ['sm'],
    align: 'center'
  },
  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'mainExamCategoryName',
    responsive: ['sm'],
    align: 'center',
    // ...getColumnSearchProps('mainExamCategoryName'),
    render: (mainExamCategoryName) => mainExamCategoryName
  },
  {
    title: 'สถานะ',
    dataIndex: 'active',
    align: 'center',
    render(active) {
      return {
        props: {
          style: {
            color: !active ? 'red' : 'green'
          }
        },
        children: <div>{active ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</div>
      }
    }
  },
  {
    title: 'แก้ไขล่าสุด',
    dataIndex: 'updatedAt',
    align: 'center',
    // ...getColumnSearchProps('updatedAt'),
    render: (_, record) =>
      record.updatedAt ? dayjs(record.updatedAt).locale('th').format('DD MMMM BBBB LT น.') : '-'
  },
  {
    title: 'จัดการ',
    dataIndex: 'manage',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) => <Actions record={record} mutateData={mutateData} />
  }
]

const Actions = ({ record, mutateData }) => {
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const { currentUserCan } = useAuth()
  console.log(record.subExamCategory.length)
  return (
    <Row gutter={[10, 0]} justify="center">
      <Col>
        <VruModal title="แก้ไข" open={editModal} handleCancel={() => setEditModal(false)}>
          <MainExamForm
            record={record}
            mutateData={mutateData}
            handleCancel={() => setEditModal(false)}
          />
        </VruModal>
        <Button
          disabled={!currentUserCan(['update_main_exam_category'])}
          className="btnedit"
          icon={<EditFilled />}
          onClick={() => setEditModal(true)}
        />
      </Col>{' '}
      <Col>
        {record?.subExamCategory.length === 0 ? (
          <Button
            disabled={!currentUserCan(['delete_main_exam_category'])}
            className="btndelete"
            icon={<DeleteFilled />}
            onClick={() => setDeleteModal(true)}
          />
        ) : (
          <Button disabled className="btndelete" icon={<DeleteFilled />} />
        )}
        <ConfirmationDelete
          open={deleteModal}
          callback={async () => {
            await deleteMainExam(record.id)
            mutateData()
          }}
          fallback={() => {
            setDeleteModal(false)
          }}
        />
      </Col>
    </Row>
  )
}

import { React, useEffect, useRef, useState, useMemo } from 'react'
import { Row, Col, Typography, Form, Button, Alert } from 'antd'
import LayoutPage from '../../components/Layout'
import SingleChoiceExamForm from '../../components/SingleChoiceExamForm'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createExam, getExamById, mediaExam, updateExam } from '../../data/exam.data'
import { removePTag, removeClozeTag, setTheDefaultDimensionsForImage } from '../../lib/helpers'
import { EXAM_CREATOR } from '../../constants/paths'
import MatchingChoiceExamForm from '../../components/MatchingChoiceExamForm'
import LectureChoiceExamForm from '../../components/LectureChoiceExamForm'
import ClozePassageExamForm from '../../components/ClozePassageExamForm'
import { useAuth } from '../../AuthorizationProvider'
import SituationChoiceExamForm from '../../components/SituationChoiceExamForm'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'

const { Text } = Typography

const UpdateExamination = () => {
  const { usePageAuth } = useAuth()
  usePageAuth(['update_exam'])
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { isLoading: isRecordLoading, record, initialValues } = useExamRecordById()
  const {
    examLevels,
    examLevel1,
    examLevel2,
    examLevel3,
    MainExamCategorySelector,
    SubExamCategorySelector,
    PartSelector,
    SectionSelector,
    SubSectionSelector,
    ExamTypeSelector
  } = useExamSelectors({
    mainExamCategoryId: initialValues.mainExamCategoryId,
    subExamCategoryId: initialValues.subExamCategoryId,
    subExamCategoryLevel1Id: initialValues.subExamCategoryLevel1Id,
    subExamCategoryLevel2Id: initialValues.subExamCategoryLevel2Id,
    subExamCategoryLevel3Id: initialValues.subExamCategoryLevel3Id
  })

  const [examTypes, setExamTypes] = useState()
  useEffect(() => {
    setExamTypes(initialValues.examType)
  }, [])

  const onFinish = async (values) => {
    const { examCreator, ...others } = values
    if (values.examType === 2) {
      examCreator.forEach((exam) => {
        const { detailsExam } = exam
        exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
      })
    } else if (values.examType === 3) {
      examCreator.forEach((exam) => {
        const { detailsExam, examMatch } = exam
        exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
        examMatch.forEach((match) => {
          const { questionText, answerText } = match
          match.questionText = setTheDefaultDimensionsForImage(questionText)
          match.answerText = setTheDefaultDimensionsForImage(answerText)
        })
      })
    } else if (values.examType === 4 || values.examType === 5) {
      examCreator.forEach((exam) => {
        const { detailsExam, childExam } = exam
        exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
        childExam.forEach((child) => {
          const { detailsExam, examChoice } = child
          child.detailsExam = setTheDefaultDimensionsForImage(detailsExam)
          examChoice.forEach((choice) => {
            const { choiceName } = choice
            choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
          })
        })
      })
    } else {
      examCreator.forEach((exam) => {
        const { detailsExam, examChoice } = exam
        exam.detailsExam = setTheDefaultDimensionsForImage(detailsExam)

        examChoice.forEach((choice) => {
          const { choiceName } = choice
          choice.choiceName = setTheDefaultDimensionsForImage(choiceName)
        })
      })
    }
    if (examTypes === 1 || examTypes === 2 || examTypes === 3) {
      const body = examCreator.map((exam, i) => ({
        // ...(record?.id && { id: record?.id }),
        ...exam,
        ...others,
        detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
        examChoice: exam.examChoice?.map((choice) => ({
          ...choice,
          choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
        })),
        examMatch: exam.examMatch?.map((match) => ({
          ...match,
          questionText: match.questionText ? removePTag(match.questionText) : '',
          answerText: match.answerText ? removePTag(match.answerText) : ''
        })),
        childExam: [],
        active: exam.active
      }))
      Promise.all(
        body.map((b) => {
          if (b?.id) {
            let { id, ...others } = b
            updateExam(id, others)
          } else createExam(b)
        })
      )
        .then(() => navigate(`${EXAM_CREATOR}/list`))
        .catch((error) => console.log(error))
    } else if (values.examType === 4) {
      const body = examCreator.map((exam, i) => ({
        // ...(record[i]?.id && { id: record[i]?.id }),
        ...exam,
        ...others,
        detailsExam: exam.detailsExam ? removeClozeTag(exam.detailsExam) : '',
        childExam: exam.childExam?.map((child) => ({
          ...child,
          examChoice: child.examChoice?.map((choice) => ({
            ...choice,
            choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
          }))
        })),
        active: exam.active
      }))
      Promise.all(
        body.map((b) => {
          let { id, ...others } = b
          updateExam(id, others)
        })
      )
        .then(() => navigate(`${EXAM_CREATOR}/list`))
        .catch((error) => console.log(error))
    } else if (values.examType === 5) {
      if (values.examCreator[0].file !== undefined) {
        const bodyForm = new FormData()
        bodyForm.append('file', values.examCreator[0].file?.fileList[0].originFileObj)
        mediaExam(bodyForm).then((resp) => {
          const body = examCreator.map((exam, i) => ({
            // ...(record[i]?.id && { id: record[i]?.id }),
            ...exam,
            ...others,
            detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
            childExam: exam.childExam?.map((child) => ({
              ...child,
              detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
              examChoice: child.examChoice?.map((choice) => ({
                ...choice,
                choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
              }))
            })),
            mediaId: resp.data?.id,
            active: exam.active
          }))
          Promise.all(
            body.map((b) => {
              let { id, ...others } = b
              updateExam(id, others)
            })
          )
            .then(() => navigate(-1))
            .catch((error) => console.log(error))
        })
      } else {
        const body = examCreator.map((exam, i) => ({
          // ...(record[i]?.id && { id: record[i]?.id }),
          ...exam,
          ...others,
          detailsExam: exam.detailsExam ? removePTag(exam.detailsExam) : '',
          childExam: exam.childExam?.map((child) => ({
            ...child,
            detailsExam: child.detailsExam ? removePTag(child.detailsExam) : '',
            examChoice: child.examChoice?.map((choice) => ({
              ...choice,
              choiceName: choice.choiceName ? removePTag(choice.choiceName) : ''
            }))
          })),
          mediaId: record.media?.id === null ? '' : record.media?.id,
          active: exam.active
        }))

        Promise.all(
          body.map((b) => {
            let { id, ...others } = b
            updateExam(id, others)
          })
        )
          .then(() => navigate(-1))
          .catch((error) => console.log(error))
      }
    }
  }

  if (isRecordLoading) return

  return (
    <LayoutPage>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          examCreator: [
            {
              examChoice: [{ isCorrect: false }],
              examMatch: [{}],
              childExam: [{ examChoice: [{ isCorrect: false }] }]
            }
          ],
          ...initialValues
        }}
        onFinish={onFinish}>
        <Row gutter={10}>
          <Col span={24}>
            <Text
              style={{
                fontFamily: 'KanitM',
                color: '#0B6252',
                fontSize: '25px'
              }}>
              แก้ไขข้อสอบ
            </Text>
          </Col>
          <Col span={7}>
            <MainExamCategorySelector disabled={true} />
          </Col>
          <Col span={7}>
            <SubExamCategorySelector disabled={true} />
          </Col>
          <Col span={7}>
            <PartSelector disabled={true} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={7}>
            <SectionSelector disabled={true} />
          </Col>
          <Col span={7}>
            <SubSectionSelector disabled={true} />
          </Col>
          <Col span={7}>
            <ExamTypeSelector disabled={true} />
          </Col>
        </Row>
        {record ? (
          <>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.examType !== curValues.examType}>
              {examTypes === 1 && (
                <SingleChoiceExamForm
                  record={record}
                  examLevels={examLevels}
                  examLevel1={examLevel1}
                  examLevel2={examLevel2}
                  examLevel3={examLevel3}
                  updateOne
                />
              )}
              {examTypes === 2 && (
                <LectureChoiceExamForm
                  record={record}
                  examLevels={examLevels}
                  examLevel2={examLevel2}
                  examLevel3={examLevel3}
                  updateOne
                />
              )}
              {examTypes === 3 && (
                <MatchingChoiceExamForm
                  record={record}
                  examLevels={examLevels}
                  examLevel2={examLevel2}
                  examLevel3={examLevel3}
                  updateOne
                />
              )}
              {examTypes === 4 && (
                <ClozePassageExamForm
                  record={record}
                  examLevels={examLevels}
                  examLevel2={examLevel2}
                  examLevel3={examLevel3}
                  updateOne
                />
              )}
              {examTypes === 5 && (
                <>
                  <SituationChoiceExamForm
                    record={record}
                    examLevels={examLevels}
                    examLevel1={examLevel1}
                    examLevel2={examLevel2}
                    examLevel3={examLevel3}
                    updateOne
                  />
                </>
              )}
            </Form.Item>

            <Row justify="end">
              <Col style={{ padding: '25px 80px 0px 0px' }}>
                <Button className="btnGreen" shape="round" htmlType="sumit">
                  บันทึกข้อมูล
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Alert message="Error" description="ไม่พบข้อมูลค่ะ" type="error" showIcon />
          </>
        )}
      </Form>
    </LayoutPage>
  )
}

export default UpdateExamination

const useExamRecordById = () => {
  const [params] = useSearchParams()
  const [record, setRecord] = useState()
  const [mediaId, setMediaId] = useState()
  const id = parseInt(params.get('id'))
  const mainExamCategoryId = parseInt(params.get('mainExamCategoryId'))
  const subExamCategoryId = parseInt(params.get('subExamCategoryId'))
  const subExamCategoryLevel1Id = parseInt(params.get('subExamCategoryLevel1Id'))
  const subExamCategoryLevel2Id = parseInt(params.get('subExamCategoryLevel2Id'))
  const subExamCategoryLevel3Id = parseInt(params.get('subExamCategoryLevel3Id'))
  const examType = parseInt(params.get('examType'))

  const isValidQS = Boolean(id && mainExamCategoryId && subExamCategoryId && examType)
  const isLoading = useRef(true)

  useEffect(() => {
    if (isValidQS && isLoading.current)
      getExamById(id)
        .then((resp) => setRecord([resp.data]))
        .catch((error) => console.log(error))
        .finally(() => {
          isLoading.current = false
        })
  }, [isValidQS])

  const initialValues = useMemo(
    () => ({
      ...(isValidQS && {
        mainExamCategoryId,
        subExamCategoryId,
        subExamCategoryLevel1Id,
        subExamCategoryLevel2Id,
        subExamCategoryLevel3Id,
        examType
      }),

      ...(record && {
        subExamCategoryLevel1Id: record[0].subExamCategoryLevel1?.subExamCategoryLevel1Id,
        subExamCategoryLevel2Id: record[0].subExamCategoryLevel2?.subExamCategoryLevel2Id,
        subExamCategoryLevel3Id: record[0].subExamCategoryLevel3?.subExamCategoryLevel3Id,
        examCreator: record.map((r) => ({
          id: r.id,
          examLevelId: r.examLevel.id,
          detailsExam: r.detailsExam,
          examChoice: r.examChoice.map((choice) => ({
            ...choice,
            choiceName: choice.choiceName
          })),
          active: r.active,
          mediaId: r.media?.id,
          examMatch: r.examMatch.map((match) => ({
            ...match,
            questionText: match.questionText,
            answerText: match.answerText
          })),
          childExam: r.childExam.map((child) => ({
            ...child,
            detailsExam: child.detailsExam,
            examChoice: child.examChoice.map((choice) => ({
              ...choice,
              choiceName: choice.choiceName
            }))
          }))
        }))
      })
    }),
    [record]
  )
  return {
    isLoading: isLoading.current,
    // isValidQS,
    record,
    initialValues
  }
}

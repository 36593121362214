import { Button, Modal, Row, Col, Text, Form, Input } from 'antd'
import errorRed from '../Image/errorRed.png'
import { useState } from 'react'

export default function ConfirmationApprove({
  open = false,
  useRemark = false,
  callback = (remark) => {},
  fallback = () => {}
}) {
  const [remark, setRemark] = useState('')
  return (
    <Modal
      open={open}
      onCancel={fallback}
      // width={1000}
      footer={
        <Row justify={'center'}>
          <Button shape="round" className="btnBGreen" onClick={fallback}>
            ยกเลิก
          </Button>
          <Button
            className="btnGreen"
            shape="round"
            type="primary"
            onClick={() => callback(remark)}>
            บันทึกข้อมูลใหม่
          </Button>
        </Row>
      }>
      <Row justify="center">
        <Col span={24}>
          <img style={{ margin: '10% 0% 0% 40%' }} src={errorRed} />
        </Col>
        {!useRemark && (
          <>
            <Col span={24}>
              <p
                style={{
                  textAlign: 'center',
                  fontFamily: 'KanitM',
                  fontSize: '20px',
                  margin: '3% 0% 0% 0%'
                }}>
                คุณต้องการบันทึกข้อมูลหรือไม่ ?
              </p>
            </Col>
          </>
        )}

        {useRemark ? (
          <>
            <Col span={24}>
              <p
                style={{
                  textAlign: 'center',
                  fontFamily: 'KanitM',
                  fontSize: '20px',
                  margin: '3% 0% 0% 0%'
                }}>
                เหตุผลการไม่อนุมัติ
              </p>
            </Col>

            <Col span={24}>
              <label
                style={{
                  textAlign: 'center',
                  fontFamily: 'KanitM',
                  fontSize: '14px',
                  margin: '1% 0% 0% 10%'
                }}>
                เหตุผล :{' '}
              </label>
              <Input
                style={{ width: '65%', margin: '3% 0% 0% 1%' }}
                onChange={(e) => {
                  setRemark(e.target.value)
                }}
                value={remark}></Input>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
    </Modal>
  )
}

import { Content } from 'antd/es/layout/layout'
import LayoutPage from '../../components/Layout'
import ExamPublisherForm from './Form'
import { Form, Col, Typography } from 'antd'
import { useAuth } from '../../AuthorizationProvider'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { fetchRecord, resetRecord } from '../../features/examiners/examinersSlice'
const { Text } = Typography
export default function ExamPublisherView() {
  const { usePageAuth } = useAuth()
  usePageAuth(['get_exam'])

  const { id } = useParams()
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRecord(id))

    return () => {
      dispatch(resetRecord())
    }
  }, [id])

  return (
    <LayoutPage>
      <Content>
        <Col span={24}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px',
              marginBottom: 20,
              display: 'block'
            }}>
            ดูชุดข้อสอบ (ผู้ออกข้อสอบ)
          </Text>
        </Col>
        <ExamPublisherForm form={form} disabled={true} />
      </Content>
    </LayoutPage>
  )
}

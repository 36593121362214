import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getExamById, listExam } from '../../data/exam.data'

/**
 * @param {import('antd').FormInstance} form
 * @param {1|0} fill
 * @return {{
 *  mainExamCategoryId: Number,
 *  subExamCategoryId: Number,
 *  subExamCategoryLevel1Id: Number,
 *  subExamCategoryLevel2Id: Number,
 *  subExamCategoryLevel3Id: Number,
 *  examType: Number
 * }}
 */
const useRecord = (form, fill) => {
  const [params] = useSearchParams()
  const [record, setRecord] = useState([])
  const id = parseInt(params.get('id'))
  const mainExamCategoryId = parseInt(params.get('mainExamCategoryId'))
  const subExamCategoryId = parseInt(params.get('subExamCategoryId'))
  const subExamCategoryLevel1Id = parseInt(params.get('subExamCategoryLevel1Id'))
  const subExamCategoryLevel2Id = parseInt(params.get('subExamCategoryLevel2Id')) || null
  const subExamCategoryLevel3Id = parseInt(params.get('subExamCategoryLevel3Id')) || null
  const examType = parseInt(params.get('examType'))
  const isValidQS = Boolean(
    (mainExamCategoryId && subExamCategoryId && subExamCategoryLevel1Id && examType) || id
  )

  if (!isValidQS) throw new Error('The query is invalid.')

  useEffect(() => {
    if (id) {
      getExamById(id)
        .then((resp) => {
          console.log(resp)
        })
        .catch((error) => console.log(error))
    } else {
      listExam({
        mainExamCategoryId,
        subExamCategoryId,
        subExamCategoryLevel1Id,
        subExamCategoryLevel2Id,
        subExamCategoryLevel3Id,
        examType,
        sort: 'createdAt',
        order: 'asc'
      })
        .then((res) => {
          setRecord(res.data.items)
        })
        .catch((error) => console.log(error))
    }
  }, [
    id,
    mainExamCategoryId,
    subExamCategoryId,
    subExamCategoryLevel1Id,
    subExamCategoryLevel2Id,
    subExamCategoryLevel3Id,
    examType
  ])

  useEffect(() => {
    if (record.length) {
      const values = [
        { name: 'mainExamCategoryId', value: mainExamCategoryId },
        { name: 'subExamCategoryId', value: subExamCategoryId },
        { name: 'subExamCategoryLevel1Id', value: subExamCategoryLevel1Id },
        { name: 'subExamCategoryLevel2Id', value: subExamCategoryLevel2Id },
        { name: 'subExamCategoryLevel3Id', value: subExamCategoryLevel3Id },
        { name: 'examType', value: examType }
      ]

      if (fill)
        values.push({
          name: 'examCreator',
          value: record.map((r) => ({
            id: r.id,
            subExamCategoryLevel2Id: r.subExamCategoryLevel2?.subExamCategoryLevel2Id,
            subExamCategoryLevel3Id: r.subExamCategoryLevel3?.subExamCategoryLevel3Id,
            examLevelId: r.examLevel.id,
            detailsExam: r.detailsExam,
            examChoice: r.examChoice.map((choice) => ({
              ...choice,
              choiceName: choice.choiceName
            })),
            active: r.active,
            mediaId: r.media?.id,
            examMatch: r.examMatch.map((match) => ({
              ...match,
              questionText: match.questionText,
              answerText: match.answerText
            })),
            childExam: r.childExam.map((child) => ({
              ...child,
              detailsExam: child.detailsExam,
              examChoice: child.examChoice.map((choice) => ({
                ...choice,
                choiceName: choice.choiceName
              }))
            }))
          }))
        })

      form.setFields(values)
    }
  }, [JSON.stringify(record)])

  return {
    mainExamCategoryId,
    subExamCategoryId,
    subExamCategoryLevel1Id,
    subExamCategoryLevel2Id,
    subExamCategoryLevel3Id,
    examType,
    record
  }
}

export default useRecord

import { Col, Row, Card, Typography, Form, Space } from 'antd'
import LayoutPage from '../../components/Layout'
import { QuestionStorageID } from '../../data/quest-storage.data'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '../../AuthorizationProvider'
import Title from 'antd/es/skeleton/Title'
import TextEditor from '../../components/TextEdit'
const { Text } = Typography

export default function View({ form, onFinish }) {
  const { isLoading: isRecordLoading, record, initialValues } = useData()
  const { usePageAuth, currentUserCan } = useAuth()
  usePageAuth(['list_approve_exam', 'get_approve_exam'])
  return (
    <LayoutPage>
      <Row gutter={[0, 24]} justify="space-between">
        <Col span={12}>
          <Text style={{ fontFamily: 'KanitM', color: '#0B6252', fontSize: '25px' }}>
            ตรวจชุดข้อสอบ
          </Text>
        </Col>
      </Row>

      <Card style={{ width: '100%', height: 'auto' }}>
        {/* <Form
          form={form}
          layout="vertical"
          initialValues={{
            examSeq: [
              {
                exam: { detailsExam: record.examSeq.exam.detailsExam },
                choiceSeq: [{}]
              }
            ]
          }}>
          <Form.List name="examSeq" className="questions-list">
            <Form.Item name="detailsExam">
              <TextEditor></TextEditor>
            </Form.Item>
          </Form.List>
        </Form> */}
      </Card>
    </LayoutPage>
  )
}

export const useData = () => {
  const [params] = useSearchParams()
  const [record, setRecord] = useState()
  const [count, setcount] = useState()
  const isLoading = useRef(true)
  const id = parseInt(params.get('id'))
  const isValidQS = Boolean(id)

  if (!isValidQS) return
  useEffect(() => {
    if (isValidQS && isLoading.current)
      QuestionStorageID({
        id
      })
        .then((res) => {
          setRecord(res.data.items)
          setcount(res.data.items.length)
        })
        .catch((error) => console.log(error))
        .finally(() => {
          isLoading.current = false
        })
  }, [isValidQS])

  // const initialValues = useMemo(
  //   () => ({
  //     ...(isValidQS && {
  //       id
  //     }),
  //     ...(record && {
  //       exam: [
  //         {
  //           examSeq: [
  //             {
  //               exam: {detailsExam:record.}
  //             }
  //           ]
  //         }
  //       ]
  //     })
  //   }),
  //   [record]
  // )

  return {
    isLoading: isLoading.current,
    record,
    count: record?.length || 1
    // initialValues
  }
}

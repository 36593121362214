import { Form, Input, Space, Typography } from 'antd'
import { useEffect } from 'react'
const { Text } = Typography

/**
 * @param {object} props
 */
export default function TotalNumberOfExams({ utils }) {
  useEffect(() => {
    utils.examUtils.setTotalNumberOfExams()
  }, [
    utils.form.getFieldValue(utils.examinerUtils.fullPathOfUsersId),
    JSON.stringify(utils.examiners)
  ])

  return (
    <Space style={{ display: 'flex', justifyContent: 'stretch' }}>
      <Text>จำนวนข้อสอบทั้งหมด :</Text>
      <Form.Item
        name={utils.examUtils.pathOfTotalNumberOfExams}
        style={{ marginBottom: 0 }}
        initialValue={0}>
        <Input style={{ width: '90px' }} disabled />
      </Form.Item>
      <Text>ข้อ</Text>
    </Space>
  )
}

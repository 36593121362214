import { Column } from '@ant-design/plots'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra)

export default function Chart({ data }) {
  const config = {
    data,
    group: true,
    xField: 'date',
    yField: 'total',
    colorField: 'name',
    legend: {
      position: 'top-right'
    },
    label: {
      textBaseline: 'bottom'
    },
    style: {
      radiusTopLeft: 10,
      radiusTopRight: 10,
      maxWidth: 40
    },
    axis: {
      x: {
        labelFormatter: (d) => dayjs(d, 'MM/DD/YYYY').locale('th').format('MMM BBBB')
      }
    }
  }
  return <Column {...config} />
}

import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const listRole = () => fetchJsonWithToken(env.rolesApi + '?limit=0')
export const createRole = (values) =>
  fetchJsonWithToken(env.rolesApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const getRole = (values) =>
  fetchJsonWithToken(env.rolesApi, {
    method: 'GET',
    body: JSON.stringify(values)
  })
export const updateRole = (id, values) =>
  fetchJsonWithToken(`${env.rolesApi}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })

export const deleteRole = (id) =>
  fetchJsonWithToken(`${env.rolesApi}/${id}/delete`, {
    method: 'DELETE'
  })

export const getPermissions = (values) =>
  fetchJsonWithToken(env.permissionsApi, {
    method: 'GET',
    body: JSON.stringify(values)
  })

import _ from 'lodash'

export default class SubSectionUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfSubSection = 'subExamCategoryLevel3Id'
    this.pathOfSubSection = [this.parent.indexOfItem, this.nameOfSubSection]
    this.fullPathOfSubSection = [...this.parent.pathOfItem, this.nameOfSubSection]
  }

  getSubSectionValue = () => this.parent.form.getFieldValue(this.fullPathOfSubSection)

  getSubSectionsValue = () =>
    this.parent.form
      .getFieldValue(this.parent.nameOfList)
      .filter((item) => item?.subExamCategoryLevel3Id)
      .map((item) => item.subExamCategoryLevel3Id)

  getSubSectionOptions = () => {
    if (!this.parent.sectionUtils.getSectionValue()) return []

    return _(this.parent.examinerUtils.filterExaminersBySection())
      .uniqBy(this.nameOfSubSection)
      .map((exam) => ({
        label: exam.subExamCategoryLevel3Name,
        value: exam.subExamCategoryLevel3Id,
        ...(this.getSubSectionsValue().indexOf(exam.subExamCategoryLevel3Id) + 1 && {
          disabled: true
        })
      }))
      .filter((exam) => exam.value)
      .value()
  }

  getAvailableSubSectionsValue = () => {
    return _(this.parent.examinerUtils.filterExaminersBySection())
      .map((exam) => exam.subExamCategoryLevel3Id)
      .compact()
      .uniq()
      .difference(this.getSubSectionsValue())
      .value()
  }
}

import { React, useState } from 'react'
import { UserOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import { PiLockKeyOpen } from 'react-icons/pi'
import { Row, Col, Typography, Space, Avatar, Dropdown, Button } from 'antd'
import { Header } from 'antd/es/layout/layout'
import '../components/Layout.css'
import logo from '../Image/logo.png'
import { Link } from 'react-router-dom'
import VruModal from './Modal'
import UserForm from './UserForm'
import { logout } from '../data/logout'
import ChangePassUser from './ChangePassUser'
import ModalChagePass from './ModalChagePass'
import { useAuth } from '../AuthorizationProvider'
import { removeTokens } from '../lib/token'

const { Text } = Typography

const HeaderLayout = () => {
  const { me } = useAuth()
  const [editModal, setEditModal] = useState(false)
  const [editModalPass, setEditModalPass] = useState(false)
  const handleCancel = () => {
    setEditModal(false)
    setEditModalPass(false)
  }

  const Logout = () => {
    logout()
      .then((res) => {
        removeTokens()
        window.location.href = '/'
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const items = [
    {
      label: <a onClick={() => setEditModal(true)}>ดูข้อมูลส่วนตัว</a>,
      line: '',
      icon: <EditOutlined />
    },
    {
      label: <a onClick={() => setEditModalPass(true)}>เปลี่ยนรหัสผ่าน</a>,
      line: '',
      icon: <PiLockKeyOpen />
    },
    {
      type: 'divider'
    },
    {
      label: (
        <Button
          onClick={Logout}
          style={{
            fontFamily: 'kanit',
            width: '100%',
            background:
              'linear-gradient(to right, rgba(50,157,156),rgba(86,197,150),rgba(123,228,150)',
            color: 'white',
            border: 'none',
            borderRadius: '50px'
          }}>
          ออกจากระบบ
        </Button>
      )
    }
  ]

  return (
    <Header
      style={{
        height: '80px',
        padding: '0px 0px 0px 0px',
        background: 'linear-gradient(to right, rgba(50,157,156),rgba(86,197,150),rgba(123,228,150))'
      }}>
      <Row justify="space-between" style={{ lineHeight: 0, padding: '0 20px' }}>
        <Col>
          <Space style={{ alignItems: 'center', lineHeight: 0 }}>
            <Link to="/main">
              <img src={logo} style={{ maxWidth: '45px', padding: '10px 0px 0px' }} />
            </Link>
            <div>
              <Text style={{ display: 'block', fontFamily: 'KanitM', color: '#fff' }}>
                ระบบคลังข้อสอบ
              </Text>
              <Text style={{ fontFamily: 'Kanit', color: '#fff' }}>
                มหาวิทยาลัยราชภัฏวไลยอลงกรณ์
              </Text>
            </div>
          </Space>
        </Col>

        <Col>
          <Space
            align="center"
            style={{ height: '100%', justifyContent: 'end', alignItems: 'center' }}>
            <Avatar
              style={{
                backgroundColor: '#DEFF59'
              }}
              icon={<UserOutlined style={{ color: 'black' }} />}
            />
            <Dropdown
              menu={{
                items
              }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Text
                    style={{
                      fontFamily: 'Kanit',
                      color: '#fff',
                      fontSize: '18px',
                      padding: '10px'
                    }}>
                    {me?.fname}
                    {/* User{nameUser.map((name)=>)} */}
                  </Text>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Space>
        </Col>
      </Row>

      <VruModal view={true} title="ดูข้อมูล" open={editModal} handleCancel={handleCancel}>
        <UserForm view={true} record={me} mutateData={() => {}} handleCancel={handleCancel} />
      </VruModal>

      <ModalChagePass
        view={true}
        title="เปลี่ยนรหัสผ่าน"
        open={editModalPass}
        handleCancel={handleCancel}>
        <ChangePassUser record={me} mutateData={() => {}} handleCancel={handleCancel} />
      </ModalChagePass>
    </Header>
  )
}
export default HeaderLayout

import { React, useEffect, useRef, useState } from 'react'
import { Form, DatePicker, Button, Card, Space } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import Chart from './Chart.js'
import { getNumberOfExams, listMonths } from '../../data/exam.data.js'
import useExamSelector from '../../hooks/selectors/useExamSelectors.js'
import th from 'antd/es/date-picker/locale/th_TH'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import exportPDF from './export.js'

dayjs().format('L LT')
dayjs.extend(LocalizedFormat)
dayjs.extend(buddhistEra)

const buddhistLocale = {
  ...th,
  lang: {
    ...th.lang,
    fieldDateFormat: 'BBBB-MM',
    fieldDateTimeFormat: 'BBBB-MM-DD HH:mm:ss',
    yearFormat: 'BBBB',
    cellYearFormat: 'BBBB'
  }
}

const ReportOnNumberOfExams = () => {
  const [form] = Form.useForm()
  const [months, setMonths] = useState()
  const [data, setData] = useState([])
  const {
    MainExamCategorySelector,
    SubExamCategorySelector,
    PartSelector,
    SectionSelector,
    SubSectionSelector,
    ExamTypeSelector,
    ExamLevelSelector
  } = useExamSelector()
  const wrapper = useRef(null)

  const fetchData = (query = {}) => {
    getNumberOfExams(query)
      .then((resp) => setData(resp.data))
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    listMonths()
      .then((resp) => setMonths(resp.data))
      .catch((error) => console.log(error))

    fetchData()
  }, [])

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ marginBottom: 50 }}
      onFinish={(values) => {
        const { startDate, endDate, ...others } = values
        fetchData({
          ...others,
          ...(startDate && {
            startDate: startDate.toDate()
          }),
          ...(endDate && {
            endDate: endDate.toDate()
          })
        })
      }}>
      <Space align="end" style={{ flexWrap: 'wrap', marginBottom: 15 }}>
        <MainExamCategorySelector noRules={true} style={{ marginBottom: 0 }} />
        <SubExamCategorySelector noRules={true} style={{ marginBottom: 0 }} />
        <PartSelector noRules={true} style={{ marginBottom: 0 }} />
        <SectionSelector style={{ marginBottom: 0 }} />
        <SubSectionSelector style={{ marginBottom: 0 }} />
        <ExamTypeSelector noRules={true} style={{ marginBottom: 0 }} />
        <ExamLevelSelector style={{ marginBottom: 0 }} />

        <Form.Item shouldUpdate={(prev, cur) => prev.endDate !== cur.endDate} noStyle>
          {() => {
            const endDate = form.getFieldValue('endDate')
            return (
              <Form.Item label="วันเริ่มต้น:" name="startDate" style={{ marginBottom: 0 }}>
                <DatePicker
                  locale={buddhistLocale}
                  picker="month"
                  disabledDate={(current) => {
                    const diff = months.find(
                      (month) =>
                        current.get('y') === dayjs(month).get('y') &&
                        current.get('M') === dayjs(month).get('M')
                    )
                    return current && (!diff || (endDate && current > endDate))
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item shouldUpdate={(prev, cur) => prev.startDate !== cur.startDate} noStyle>
          {() => {
            const startDate = form.getFieldValue('startDate')
            return (
              <Form.Item label="วันที่สิ้นสุด:" name="endDate" style={{ marginBottom: 0 }}>
                <DatePicker
                  picker="month"
                  locale={buddhistLocale}
                  disabledDate={(current) => {
                    const diff = months.find(
                      (month) =>
                        current.get('y') === dayjs(month).get('y') &&
                        current.get('M') === dayjs(month).get('M')
                    )

                    return current && (!diff || current < startDate)
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Button
          htmlType="sumit"
          style={{ width: '100px', height: '35px' }}
          className="btnGreen"
          shape="round">
          ค้นหา
        </Button>

        <Button
          style={{ width: '100px', height: '35px' }}
          shape="round"
          onClick={() => {
            form.resetFields()
            fetchData()
          }}>
          ล้าง
        </Button>
      </Space>

      <Card
        style={{
          height: '100%',
          width: '100%',
          marginBottom: 10
        }}
        ref={wrapper}>
        {data && (
          <>
            <Chart data={data.map((d) => ({ ...d, date: dayjs(d?.date).format('L') }))} />

            <Button
              className="btnBGreen"
              shape="round"
              icon={<ExportOutlined />}
              onClick={() => exportPDF(wrapper.current)}>
              Export PDF
            </Button>
          </>
        )}
      </Card>
    </Form>
  )
}
export default ReportOnNumberOfExams

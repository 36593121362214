import { React } from 'react'
import { Layout, Col, Typography } from 'antd'
import '../../style/main.css'
import LayoutPage from '../../components/Layout'
import { createExaminer } from '../../data/examiner.data'
import _ from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ExamPublisherForm from './Form'
import portals from '../../lib/portals'
import { useAuth } from '../../AuthorizationProvider'
const { Content } = Layout
const { Text } = Typography

const ExamPublisherCreate = () => {
  const { usePageAuth } = useAuth()
  usePageAuth(['create_exam_examiner'])

  const [params] = useSearchParams()
  const man = parseInt(params.get('man'))
  const navigate = useNavigate()

  const onFinish = async (values) => {
    try {
      await createExaminer({
        active: true,
        ...values,
        ...(values.isRandom && {
          section: values.section.map((st) => {
            const { exam, ...others } = st
            return others
          })
        }),
        ...(!values.isRandom && {
          numberOfSets: 1,
          section: values.section.map((st) => ({
            ...st,
            numberOfExamsRequired: st.exam.length,
            examExaminerLevel: _(st.exam)
              .groupBy((exam) => (exam.examLevelId ? exam.examLevelId : exam.examLevel.id))
              .reduce(
                (acc, val, key) => [
                  ...acc,
                  { examLevelId: parseInt(key), numberRequiredLevel: val.length }
                ],
                []
              )
          }))
        })
      })
      navigate(portals.examPublisher.list())
    } catch (error) {
      if (error.status === 409) {
        alert('ข้อสอบไม่เพียงพอต่อการสุ่ม กรุณากลับไปเพิ่มใหม่คะ')
      } else {
        alert(error.statusText)
      }
    }
  }

  return (
    <LayoutPage>
      <Content style={contentStyle}>
        <Col span={24}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px',
              marginBottom: 20,
              display: 'block'
            }}>
            {!man ? 'ออกข้อสอบแบบสุ่ม (ผู้ออกข้อสอบ)' : 'ออกข้อสอบแบบเลือกเอง (ผู้ออกข้อสอบ)'}
          </Text>
        </Col>

        <ExamPublisherForm onFinish={onFinish} />
      </Content>
    </LayoutPage>
  )
}

export default ExamPublisherCreate

const contentStyle = {
  backgroundColor: '#F4F4F4',
  with: '100%',
  alignItems: 'center'
}

import { PlusOutlined, DeleteFilled } from '@ant-design/icons'
import { Button, Col, Form, Input, Radio, Row, Space, Typography } from 'antd'
import TextEditor2 from './TextEdit2'
import React from 'react'
import _ from 'lodash'
const { Text } = Typography

export default function ExamMatchingChoiceAns({ name }) {
  const form = Form.useFormInstance()
  const [parentIndex] = name

  return (
    <Form.Item shouldUpdate>
      {() => {
        const error = form.getFieldError(['examCreator'].concat(name))
        return (
          <Form.List
            name={name}
            // rules={[
            //   {
            //     validator: async (field, values) => {
            //       if (!(_(values).findIndex((value) => value.isCorrect) + 1))
            //         return Promise.reject(new Error("กรุณาเลือกคำตอบด้วยคะ"));
            //     },
            //   },
            // ]}
          >
            {(Answer, { add, remove }) =>
              Answer.map((ans) => (
                <React.Fragment key={ans.key}>
                  <Row>
                    <Col span={24} push={2} lg={16}>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 10
                        }}>
                        <Text>คำตอบ</Text>
                        <div>
                          <Button
                            className="btnadd"
                            shape="circle"
                            size="small"
                            onClick={() => add({ isCorrect: false })}
                            icon={<PlusOutlined />}
                          />
                          {` `}
                          <Text>เพิ่มตัวเลือก</Text>
                        </div>
                      </Space>
                    </Col>
                  </Row>

                  <Row justify="start" span={23} push={2} lg={16}>
                    <Col span={16} push={2}>
                      <TextEditor2 name={[ans.name, 'choiceName']} />
                    </Col>
                    {!!ans.name && (
                      <Col span={1} push={2}>
                        <Button
                          style={{ margin: '75px 10px 0px 10px' }}
                          type="primary"
                          icon={<DeleteFilled />}
                          onClick={() => remove(ans.name)}
                          className="deleteBtn"
                        />
                      </Col>
                    )}
                  </Row>

                  {/* <Row justify='center'>
                   <Col span={24}>
                      <TextEditor2 name={[choice.name, "choiceName"]} />
                      </Col>
                      </Row>
                      <Button
                        type="primary"
                        icon={<DeleteFilled />}
                        onClick={() => remove(choice.name)}
                        className="deleteBtn"
                      />
                     */}
                </React.Fragment>
              ))
            }
          </Form.List>
        )
      }}
    </Form.Item>
  )
}

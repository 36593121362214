import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { EXAM_TYPES } from '../../components/ExamTypeSelector'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { EXAM_CREATOR } from '../../constants/paths'
import qs from 'qs'
dayjs.extend(localizedFormat)

export const createDraftExamColumnsType = (refetch, currentUserCan) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    align: 'center'
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'mainExamCategoryName',
    align: 'left'
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบย่อย',
    dataIndex: 'subExamCategoryName',
    align: 'left'
  },

  {
    title: 'ชื่อ Section',
    dataIndex: 'subExamCategoryLevel1Name',
    align: 'left'
  },

  {
    title: 'ชื่อ Part',
    dataIndex: 'subExamCategoryLevel2Name',
    align: 'left'
  },

  {
    title: 'ชื่อ Sub Part',
    dataIndex: 'subExamCategoryLevel3Name',
    align: 'left'
  },

  {
    title: 'แก้ไขเมื่อ',
    dataIndex: 'updatedAt',
    align: 'left',
    render: (value) => dayjs(value).locale('th').format('LLL')
  },

  {
    title: 'ประเภทชุดข้อสอบ',
    dataIndex: 'examType',
    render: (value) => EXAM_TYPES.find((type) => type.value === value)?.name
  },

  {
    title: 'จัดการ',
    dataIndex: 'draftId',
    render: (draftId) => (
      <Space>
        <Button
          disabled={!currentUserCan(['create_exam'])}
          className="btnedit"
          icon={<EditFilled />}
          href={`${EXAM_CREATOR}/create?${qs.stringify({
            draftId
          })}`}
        />
        <Button
          disabled={!currentUserCan(['create_exam'])}
          icon={<DeleteFilled />}
          danger
          onClick={() => {
            localStorage.removeItem(`draft-exam-${draftId}`)
            refetch()
          }}
        />
      </Space>
    )
  }
]

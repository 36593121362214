import { useEffect, useState } from 'react'
import { listMainExamCat } from '../data/category'
import ExamTypeSelectorComp from '../components/ExamTypeSelector'
import MainExamCategorySelectorComp from '../components/MainExamCategorySelector'
import SubExamCategorySelectorComp from '../components/SubExamCategorySelector'
import SubExamLevel2SelectorComp from '../components/SubExamLevel2Selector'
import SubExamLevel3SelectorComp from '../components/SubExamLevel3Selector'
import SubExamLevelSelectorComp from '../components/SubExamLevelSelector'
import ApporveRadioForm from '../components/ApproveRadioForm'
import { listExamlevel } from '../data/examlevel.data'

export default function useExam({
  mainExamCateId,
  subExamCateId,
  subExamCateLevel1,
  subExamCateLevel2,
  subExamCateLevel3
} = {}) {
  const [mainCategory, setMainCategory] = useState()
  const [subCategory, setSubCategory] = useState()
  const [examLevels, setExamLevels] = useState()
  const [examLevel1, setExamLevel1] = useState()
  const [examLevel2, setExamLevel2] = useState()
  const [examLevel3, setExamLevel3] = useState()

  /**
   *
   * @param {object} props
   * @param {boolean} props.noRules
   * @param {React.CSSProperties} props.style
   * @returns {React.JSX.Element}
   */
  const MainExamCategorySelector = (props) => (
    <MainExamCategorySelectorComp
      {...props}
      mainCategory={mainCategory}
      handleMainExamCatChange={handleMainExamCatChange}
    />
  )

  /**
   *
   * @param {object} props
   * @param {boolean} props.noRules
   * @param {React.CSSProperties} props.style
   * @returns {React.JSX.Element}
   */
  const SubExamCategorySelector = (props) => (
    <SubExamCategorySelectorComp
      {...props}
      subCategory={subCategory}
      handleSubExamCatChange={handleSubExamCatChange}
    />
  )

  /**
   *
   * @param {object} props
   * @param {boolean} props.noRules
   * @param {React.CSSProperties} props.style
   * @returns {React.JSX.Element}
   */
  const SubExamLevelSelector = (props) => (
    <SubExamLevelSelectorComp
      {...props}
      handleSubExamLevel1CatChange={handleSubExamLevel1CatChange}
      examLevel1={examLevel1}
    />
  )

  /**
   *
   * @param {object} props
   * @param {boolean} props.noRules
   * @param {React.CSSProperties} props.style
   * @returns {React.JSX.Element}
   */
  const ExamTypeSelector = (props) => <ExamTypeSelectorComp {...props} />

  const handleMainExamCatChange = (id) => {
    if (mainCategory) {
      var [cat] = mainCategory.filter((cat) => cat.id === id)
      setSubCategory(cat?.subExamCategory)
    }
  }

  const handleSubExamLevel1CatChange = (id) => {
    var cat = examLevel1.filter((cat) => cat.subExamCategoryLevel1Id === id)
    setExamLevel2(cat?.[0]?.subExamCategoryLevel2Id)
  }

  const handleSubExamCatChange = (id) => {
    var cat = subCategory?.filter((cat) => cat.id === id)
    setExamLevel1(cat?.[0]?.subExamCategoryLevel1Id)
  }

  const handleSubExamLevel2CatChange = (id) => {
    var cat = examLevel2.filter((cat) => cat.subExamCategoryLevel2Id === id)
    setExamLevel3(cat?.[0]?.subExamCategoryLevel3Id)
  }

  const SubExamLevel2Selector = (props) => (
    <SubExamLevel2SelectorComp
      {...props}
      handleSubExamLevel2CatChange={handleSubExamLevel2CatChange}
      examLevel2={examLevel2}
    />
  )

  const SubExamLevel3Selector = (props) => (
    <SubExamLevel3SelectorComp {...props} examLevel3={examLevel3} />
  )

  const ApporveSelector = (props) => <ApporveRadioForm {...props} />

  useEffect(() => {
    listMainExamCat()
      .then((res) => setMainCategory(res.data.items.filter((item) => item.active)))
      .catch((error) => console.log(error))

    listExamlevel()
      .then((res) => setExamLevels(res.data.items.filter((item) => item.active)))
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    if (mainCategory && mainExamCateId) handleMainExamCatChange(mainExamCateId)

    if (subCategory && subExamCateId) handleSubExamCatChange(subExamCateId)

    if (examLevel1 && subExamCateLevel1) handleSubExamLevel1CatChange(subExamCateLevel1)

    if (examLevel2 && subExamCateLevel2) handleSubExamLevel2CatChange(subExamCateLevel2)
  }, [
    mainExamCateId,
    subExamCateId,
    subExamCateLevel1,
    subExamCateLevel2,
    subExamCateLevel3,
    JSON.stringify(mainCategory),
    JSON.stringify(subCategory),
    JSON.stringify(examLevel1),
    JSON.stringify(examLevel2),
    JSON.stringify(examLevel3)
  ])

  return {
    isLoading: !mainCategory,
    examLevels,
    examLevel1,
    examLevel2,
    examLevel3,
    mainCategory,
    subCategory,
    MainExamCategorySelector,
    SubExamCategorySelector,
    SubExamLevelSelector,
    SubExamLevel2Selector,
    SubExamLevel3Selector,
    ExamTypeSelector,
    handleMainExamCatChange,
    ApporveSelector,
    handleSubExamCatChange,
    handleSubExamLevel1CatChange,
    handleSubExamLevel2CatChange
  }
}

import { Button, Divider, Modal, Row, Typography } from 'antd'

export default function ModalChagePass({
  title = 'Title',
  open = false,
  handleCancel = () => alert('close'),
  callback = () => alert('Wow'),
  children
}) {
  return (
    <Modal
      title={
        <div>
          <Row gutter={[0, 0]}>
            <Typography.Text className="txtTitle">{title}</Typography.Text>
            <Divider style={{ margin: '10px 0px 0px 0px' }} />
          </Row>
        </div>
      }
      onCancel={() => {
        handleCancel()
      }}
      open={open}
      width={900}
      footer={false}
      destroyOnClose>
      {children}
    </Modal>
  )
}

import { getToken } from './token'

export const fetchJson = async (url, config) => {
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      },
      ...(config || {})
    })

    // if (response.status >= 400) throw new Error(response.statusText)

    const json = await response.json()

    return json
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const fetchJsonWithToken = async (url, config = {}) => {
  return fetchWithToken(url, { headers: { 'Content-Type': 'application/json' }, ...config })
}
export const fetchWithToken = async (url, config = {}) => {
  try {
    const { headers, ...configExcludeHeader } = config
    let resp = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...headers
      },
      ...configExcludeHeader
    })

    if (resp.status >= 400) throw resp

    const json = await resp.json()

    return json
  } catch (error) {
    if (error instanceof Response) {
      throw { status: error.status, statusText: error.statusText }
    } else {
      throw { status: 500, statusText: error.message }
    }
  }
}

import { Column } from '@ant-design/plots'
import React, { useEffect, useRef, useState } from 'react'
import { getReuseOfExams } from '../../data/exam.data'
import { Button, Card, Form, Space } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'
import { ExportOutlined } from '@ant-design/icons'
import exportPDF from './export'

dayjs().format('L LT')
dayjs.extend(LocalizedFormat)
dayjs.extend(buddhistEra)

const ReportOnReuseOfExams = () => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const wrapper = useRef(null)
  const {
    MainExamCategorySelector,
    SubExamCategorySelector,
    PartSelector,
    SectionSelector,
    SubSectionSelector,
    ExamTypeSelector,
    ExamLevelSelector
  } = useExamSelectors()

  const fetchData = (query = {}) => {
    getReuseOfExams(query)
      .then((resp) => setData(resp.data))
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const config = {
    data,
    xField: 'useCount',
    yField: 'examCount',
    label: {
      text: 'examCount',
      position: 'inside'
    },
    style: {
      radiusTopLeft: 5,
      radiusTopRight: 5,
      maxWidth: 40
    },
    axis: {
      x: {
        title: 'จำนวนที่ถูกใช้งานไปแล้ว(ครั้ง)'
      },
      y: {
        title: 'จำนวนข้อสอบ'
      }
    }
  }
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => {
        fetchData(values)
      }}
      style={{ marginBottom: 30 }}>
      <Space align="end" style={{ marginBottom: 15, flexWrap: 'wrap' }}>
        <MainExamCategorySelector noRules={true} style={{ marginBottom: 0 }} />
        <SubExamCategorySelector noRules={true} style={{ marginBottom: 0 }} />
        <PartSelector noRules={true} style={{ marginBottom: 0 }} />
        <SectionSelector style={{ marginBottom: 0 }} />
        <SubSectionSelector style={{ marginBottom: 0 }} />
        <ExamTypeSelector noRules={true} style={{ marginBottom: 0 }} />
        <ExamLevelSelector style={{ marginBottom: 0 }} />

        <Button
          htmlType="sumit"
          style={{ width: '100px', height: '35px' }}
          className="btnGreen"
          shape="round">
          ค้นหา
        </Button>

        <Button
          style={{ width: '100px', height: '35px' }}
          shape="round"
          onClick={() => {
            form.resetFields()
            fetchData()
          }}>
          ล้าง
        </Button>
      </Space>

      <Card ref={wrapper}>
        <Column {...config} />

        <Button
          className="btnBGreen"
          shape="round"
          icon={<ExportOutlined />}
          onClick={() => exportPDF(wrapper.current)}>
          Export PDF
        </Button>
      </Card>
    </Form>
  )
}

export default ReportOnReuseOfExams

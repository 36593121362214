import { Form, Select } from 'antd'

export default function ExamTypeSelector({ view = false, ...props }) {
  const { style, other } = props
  const form = Form.useFormInstance()

  return (
    <Form.Item shouldUpdate noStyle>
      {() => {
        const subCatValue = form.getFieldValue('subExamCategoryId')
        return (
          <Form.Item
            name="examType"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบย่อย!'
              }
            ]}
            label="เลือกประเภทชุดข้อสอบ :"
            style={style}>
            <Select
              onChange={(id) => {
                // form.resetFields(['subExamCategoryLevel2Id'])
              }}
              placeholder="เลือกประเภทชุดข้อสอบ "
              options={[
                {
                  value: 1,
                  label: 'ข้อสอบคำตอบเดียว'
                },
                {
                  value: 2,
                  label: 'ข้อสอบบรรยาย'
                },
                {
                  value: 3,
                  label: 'ข้อสอบจับคู่'
                },
                {
                  value: 4,
                  label: 'Cloze Passage'
                },
                {
                  value: 5,
                  label: 'ข้อสอบคำตอบเดียว (ประเภทสถานการณ์)'
                }
              ]}
              disabled={!subCatValue || view}
              {...other}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Input, Table, Button, Space } from 'antd'
import useDataTable from '../../lib/useTable'
import LayoutPage from '../../components/Layout'
import { CheckOutlined, PrinterOutlined } from '@ant-design/icons'
import parser from 'html-react-parser'
import { QuestionStorageID } from '../../data/quest-storage.data'
import { useParams } from 'react-router-dom'
import env from '../../lib/environments'
import { getToken } from '../../lib/token'
import { fetchJsonWithToken } from '../../lib/fethcer'
import { useAuth } from '../../AuthorizationProvider'
import { ViewQuestionsColumnsType } from '../../lib/columns-types/questions-view.columns'
import { ViewQuestionsColumnsType2 } from '../../lib/columns-types/questions-view.columns-Type2'
import { stripHTMLTags } from '../../lib/helpers'
import { ViewQuestionsColumnsType3 } from '../../lib/columns-types/questions-view.columns-Type3'
import { ViewQuestionsColumnsType4 } from '../../lib/columns-types/questions-view.columns-Type4'
import { ViewQuestionsColumnsType5 } from '../../lib/columns-types/questions-view.columns-Type5'

const { Text } = Typography

const QuestionView = () => {
  const { usePageAuth } = useAuth()
  usePageAuth(['list_exam_choice', 'get_exam_choice'])
  const params = useParams()
  const { data, tableParams, loading, mutateData, handleTableChange } = useDataTable(
    QuestionStorageID,
    { id: params.id }
  )

  const expandedRowRender = (data) => {
    return (
      <Table
        columns={
          data.exam.examType === 4
            ? createExamViewColumnsType4Child(data)
            : createExamViewColumnsType5Child(data)
        }
        // pagination={false}
        dataSource={data.childExam}
        rowKey={data.childExam}
      />
    )
  }
  const [examType, setExamType] = useState()
  const [count, setCount] = useState()

  useEffect(() => {
    QuestionStorageID({ id: params.id }).then((res) => {
      setExamType(res.data.items[0].exam.examType)
      setCount(res.data.items.length)
    })
  }, [])

  return (
    <LayoutPage>
      <Row justify="space-between" gutter={[0, 20]}>
        <Col span={12}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px'
            }}>
            ดูรายละเอียดข้อสอบ
          </Text>
        </Col>
      </Row>
      <Row justify="space-between" style={{ padding: '20px 0px 0px 0px' }}>
        <Col>
          <Button
            href={`${env.questionStorageApi}/${params.id}/pdf`}
            className="btnBorderG"
            shape="round"
            icon={<PrinterOutlined />}>
            พิมพ์
          </Button>
        </Col>
        <Col>
          <Space>
            <Text>จำนวนข้อที่สร้าง</Text>
            <Text>{count}</Text>
            <Text>ข้อ</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right', padding: '20px 0px 0px 0px' }}>
          {examType === 1 && (
            <Table
              scroll={{ x: 300 }}
              columns={ViewQuestionsColumnsType(mutateData)}
              ViewQuestionsColumnsType
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={tableParams.pagination}
              // loading={loading}
              onChange={handleTableChange}
            />
          )}
          {examType === 2 && (
            <Table
              scroll={{ x: 300 }}
              columns={ViewQuestionsColumnsType2(mutateData)}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={tableParams.pagination}
              // loading={loading}
              onChange={handleTableChange}
            />
          )}
          {examType === 3 && (
            <Table
              scroll={{ x: 300 }}
              columns={ViewQuestionsColumnsType3(mutateData)}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={tableParams.pagination}
              // loading={loading}
              onChange={handleTableChange}
            />
          )}
          {examType === 4 && (
            <Table
              scroll={{ x: 300 }}
              columns={ViewQuestionsColumnsType4(mutateData)}
              expandable={{
                expandedRowRender
              }}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
            />
          )}
          {examType === 5 && (
            <Table
              scroll={{ x: 300 }}
              columns={ViewQuestionsColumnsType5(mutateData)}
              expandable={{
                expandedRowRender
              }}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </LayoutPage>
  )
}

export default QuestionView

export const createExamViewColumnsType5Child = () => [
  {
    title: 'ข้อที่',
    dataIndex: 'seq',
    align: 'center'
  },
  {
    title: 'โจทย์',
    dataIndex: 'exam',
    align: 'center',
    width: '40%',
    render: (exam) => <div style={{ textAlign: 'left' }}>{stripHTMLTags(exam.detailsExam)}</div>
  },
  {
    title: 'คำตอบ',
    dataIndex: 'choiceChildSeq',
    align: 'center',
    render: (choiceChildSeq) => (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '30px 1fr',
          gap: 8
        }}>
        {choiceChildSeq.map((choice, index) => (
          <React.Fragment key={index}>
            {choice.examChoice.isCorrect ? <CheckOutlined /> : <span />}
            <div style={{ gridColumnStart: 2, textAlign: 'left' }}>
              {stripHTMLTags(choice.examChoice.choiceName)}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }
]

export const createExamViewColumnsType4Child = () => [
  {
    title: 'ข้อที่',
    dataIndex: 'seq',
    align: 'center'
  },
  {
    title: 'คำตอบ',
    dataIndex: 'choiceChildSeq',
    align: 'center',
    render: (choiceChildSeq) => (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '30px 1fr',
          gap: 8
        }}>
        {choiceChildSeq.map((choice) => (
          <>
            {choice.examChoice.isCorrect ? <CheckOutlined /> : <span />}
            <div style={{ gridColumnStart: 2, textAlign: 'left' }}>
              {stripHTMLTags(choice.examChoice.choiceName)}
            </div>
          </>
        ))}
      </div>
    )
  }
]

import { Button, Modal, Row, Col, Result } from 'antd'
import errorRed from '../Image/errorRed.png'

export default function ConfirmationApprove({
  open = false,
  callback = () => {},
  fallback = () => {}
}) {
  return (
    <Modal
      onCancel={fallback}
      open={open}
      footer={
        <Row justify={'center'}>
          <Button shape="round" className="btnBGreen" onClick={fallback}>
            ยกเลิก
          </Button>
          <Button className="btnGreen" shape="round" type="primary" onClick={callback}>
            บันทึกข้อมูลใหม่
          </Button>
        </Row>
      }>
      <Row justify={'center'}>
        <Result status="success" title=" คุณต้องการบันทึกข้อมูลหรือไม่ ?"></Result>
      </Row>
    </Modal>
  )
}

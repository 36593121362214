import { React, useState } from 'react'
import { Row, Col, Typography, Button, Table } from 'antd'
import 'dayjs/locale/th'
import dayjs from 'dayjs'
import { PlusCircleOutlined, EditFilled, DeleteFilled } from '@ant-design/icons'
import '../../style/main.css'
import LayoutPage from '../../components/Layout'
import VruModal from '../../components/Modal'
import { deleteExamLevel, listExamlevel } from '../../data/examlevel.data'
import ConfirmationDelete from '../../components/ConfirmDelete'
import ExamLevelForm from '../../components/ExamLevelForm'
// import getColumnSearchProps from '../../lib/columns-types/columnSearch'
import { useAuth } from '../../AuthorizationProvider'
import useDataTable from '../../lib/useDataTable'
const { Text } = Typography
const ManageLevelExam = () => {
  const { usePageAuth, currentUserCan } = useAuth()
  usePageAuth(['list_exam_level', 'get_exam_level'])
  const { dataSource, tableParams, loading, refetch, handleTableChange } =
    useDataTable(listExamlevel)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchText] = useState('')

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <LayoutPage>
        <Row gutter={[0, 20]} justify="space-between">
          <Col span={12}>
            <Text
              style={{
                fontFamily: 'KanitM',
                color: '#0B6252',
                fontSize: '25px'
              }}>
              จัดการข้อมูลระดับข้อสอบ
            </Text>
          </Col>
          <Col>
            {currentUserCan(['create_exam_level']) && (
              <>
                <Button
                  className="btnGreen"
                  shape="round"
                  icon={<PlusCircleOutlined />}
                  onClick={showModal}>
                  เพิ่มข้อมูล
                </Button>
              </>
            )}
          </Col>

          <Col span={24} style={{ textAlign: 'right' }}>
            <Table
              scroll={{ x: 'max-content' }}
              columns={createColumnsType(searchText, refetch)}
              rowKey={(record) => record.id}
              dataSource={dataSource}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </Col>
        </Row>

        <VruModal title="เพิ่มข้อมูล" open={isModalOpen} handleCancel={handleCancel}>
          <ExamLevelForm mutateData={refetch} handleCancel={handleCancel} />
        </VruModal>
      </LayoutPage>
    </>
  )
}

export default ManageLevelExam
const createColumnsType = (searchText, mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    responsive: ['sm'],
    align: 'center'
  },
  {
    title: 'ชื่อระดับข้อสอบ',
    dataIndex: 'examLevelName',
    responsive: ['sm'],
    align: 'center',
    // ...getColumnSearchProps('examLevelName'),
    render: (examLevelName) => examLevelName
  },
  {
    title: 'สถานะ',
    dataIndex: 'active',
    align: 'center',
    render(active) {
      return {
        props: {
          style: {
            color: !active ? 'red' : 'green'
          }
        },
        children: <div>{active ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</div>
      }
    }
  },
  {
    title: 'แก้ไขล่าสุด',
    dataIndex: 'updatedAt',
    align: 'center',
    // ...getColumnSearchProps('updatedAt'),
    render: (_, record) =>
      record.updatedAt ? dayjs(record.updatedAt).locale('th').format('DD MMMM BBBB LT น.') : '-'
  },
  {
    title: 'จัดการ',
    dataIndex: 'manage',
    responsive: ['sm'],
    align: 'center',
    render: (_, record) => <Actions record={record} mutateData={mutateData} />
  }
]

const Actions = ({ record, mutateData }) => {
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const { currentUserCan } = useAuth()
  return (
    <Row gutter={[10, 0]} justify="center">
      <Col>
        <VruModal title="แก้ไข" open={editModal} handleCancel={() => setEditModal(false)}>
          <ExamLevelForm
            record={record}
            mutateData={mutateData}
            handleCancel={() => setEditModal(false)}
          />
        </VruModal>
        <Button
          disabled={!currentUserCan(['update_exam_level'])}
          className="btnedit"
          icon={<EditFilled />}
          onClick={() => setEditModal(true)}
        />
      </Col>
      <Col>
        {record?.exam?.length !== 0 ? (
          <Button disabled className="btndelete" icon={<DeleteFilled />} />
        ) : (
          <Button
            disabled={!currentUserCan(['delete_exam_level'])}
            className="btndelete"
            icon={<DeleteFilled />}
            onClick={() => setDeleteModal(true)}
          />
        )}
        <ConfirmationDelete
          open={deleteModal}
          callback={async () => {
            await deleteExamLevel(record.id)
            mutateData()
          }}
          fallback={() => {
            setDeleteModal(false)
          }}
        />
      </Col>
    </Row>
  )
}

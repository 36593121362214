import { React, useState } from 'react'
import { Button, Form, Input, Space, Switch } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Confirmation from './ConfirmationSuccess'
import { updateMainExam, createMainExam } from '../data/mainexam.data'

const layout = {
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 16
  }
}
export default function MainExamForm({
  view = false,
  record,
  handleCancel = () => {},
  mutateData = () => {}
}) {
  const [confirm, setConfirm] = useState(false)
  const [form] = useForm()
  const handleCreate = async (values = {}) => {
    try {
      await createMainExam(values)
      mutateData()
      handleCancel(false)
    } catch (error) {
      alert('Error : ชื่อหมวดหมู่มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }
  const handleUpdate = async (values) => {
    try {
      if (
        values.mainExamCategoryName !== record.mainExamCategoryName ||
        values.active !== record.active
      ) {
        await updateMainExam(record.id, values)
        mutateData()
      }

      handleCancel()
    } catch (error) {
      alert('Error : ชื่อหมวดหมู่มีการใช้แล้ว')
    } finally {
      setConfirm(false)
    }
  }

  const onFinish = () => {
    const values = form.getFieldsValue()

    if (!record) {
      handleCreate({ ...values, active: Boolean(values.active) })
    } else {
      handleUpdate({ ...values, active: Boolean(values.active) })
    }
  }

  return (
    <Form
      form={form}
      {...layout}
      onFinish={() => setConfirm(true)}
      initialValues={{
        mainExamCategoryName: record?.mainExamCategoryName,
        active: record?.active
      }}>
      <Form.Item
        name="mainExamCategoryName"
        label="ชื่อหมวดหมู่ข้อสอบหลัก:"
        requiredMark="optional"
        rules={[
          {
            required: true,
            message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบหลัก!'
          }
        ]}>
        <Input className="inputtxt2" placeholder="กรอกชื่อหมวดหมู่ข้อสอบหลัก" disabled={view} />
      </Form.Item>

      <Form.Item name="active" label="สถานะเปิด/ปิดใช้งาน" valuePropName="checked">
        <Switch onChange={handleChange} checkedChildren="on" unCheckedChildren="off" />
      </Form.Item>
      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        <Button shape="round" className="btnBGreen" onClick={handleCancel}>
          ยกเลิก
        </Button>
        <Button className="btnGreen" shape="round" type="primary" htmlType="submit">
          บันทึกข้อมูล
        </Button>
      </Space>

      <Confirmation
        open={confirm}
        callback={() => {
          onFinish()
        }}
        fallback={() => {
          setConfirm(false)
        }}
      />
    </Form>
  )
}

const options = []
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i
  })
}
const handleChange = (value) => {
  console.log(`selected ${value}`)
}

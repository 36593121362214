import { Form, Select } from 'antd'

export default function MainCatSelector({
  mainCat = [],
  handleMainCatChange = () => {},
  view = false,
  ...props
}) {
  const { style, other } = props
  const form = Form.useFormInstance()
  return (
    <Form.Item
      name="mainExamCategoryId"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกชื่อหมวดหมู่ข้อสอบหลัก!'
        }
      ]}
      label="เลือกชื่อหมวดหมู่ข้อสอบหลัก :"
      style={style}>
      <Select
        onChange={(id) => {
          handleMainCatChange(id)
          form.resetFields(['subExamCategoryId', 'examType'])
        }}
        placeholder="เลือกหมวดหมู่หลัก"
        disabled={view}
        options={mainCat?.map((exam) => ({
          label: exam.mainExamCategoryName,
          value: exam.id
        }))}
        {...other}
      />
    </Form.Item>
  )
}

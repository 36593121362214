import { Form } from 'antd'
import { useContext } from 'react'
import { ExamPublisherContext } from '../Context'
import PartUtils from './Part'
import CategoryUtils from './Category'
import ExaminerUtils from './Examiner'
import SectionUtils from './Section'
import SubSectionUtils from './SubSection'
import ExamTypeUtils from './ExamType'
import ExamUtils from './Exam'
import { RandomUtils } from './Random'
import ExamLevelUtils from './ExamLevel'

export class Utils {
  /**
   * @param {import("../types").FieldPropsType} field
   * @param {import("../../../types/examiner.types").ExaminerPropsType[]} examiners
   * @param {any | null} record
   */
  constructor(field, examiners, record) {
    this.form = Form.useFormInstance()
    this.context = useContext(ExamPublisherContext) || {}
    this.field = field
    this.nameOfList = 'section'
    this.indexOfItem = field.name
    this.pathOfItem = [this.nameOfList, this.indexOfItem]
    this.examiners = JSON.parse(JSON.stringify(examiners))

    if (record) {
      const examsId = record.section[this.field.name]?.exam.map((ex) => ex.id)
      this.examiners.forEach((examiner) => {
        if (examiner.exam)
          examiner.exam.forEach((ex) => {
            if (examsId?.indexOf(ex.id) + 1) {
              ex.active = true
            }
            return ex
          })
      })
    }
  }

  // category
  get categoryUtils() {
    return new CategoryUtils(this)
  }
  // part
  get partUtils() {
    return new PartUtils(this)
  }
  // examiner
  get examinerUtils() {
    return new ExaminerUtils(this)
  }
  // section
  get sectionUtils() {
    return new SectionUtils(this)
  }
  // sub section
  get subSectionUtils() {
    return new SubSectionUtils(this)
  }
  // exam type
  get examTypeUtils() {
    return new ExamTypeUtils(this)
  }
  // random
  get randomUtils() {
    return new RandomUtils(this)
  }
  // exam level
  get examLevelUtils() {
    return new ExamLevelUtils(this)
  }

  resetSection = () => this.form.resetFields([this.key])

  // examiner
  getNumberOfExamsRequiredValue = () =>
    this.form.getFieldValue(this.examUtils.fullPathOfNumberOfExamsRequired)
  getRemainingNumberOfExams = (key) => {
    const totalNumberOfExamLevels = this.form
      .getFieldValue(this.examLevelUtils.fullPathOfExamLevel)
      .filter((_, k) => k !== key)
      .reduce((acc, val) => acc + (parseInt(val.numberRequiredLevel) || 0), 0)
    const diff = this.examUtils.getNumberOfExamsRequired() - totalNumberOfExamLevels
    return diff > 0 ? diff : 0
  }

  // exam
  get examUtils() {
    return new ExamUtils(this)
  }

  getExaminersByUsersId = () => {
    const usersId = this.form.getFieldValue(this.usersIdName)
    return this.getExaminersByCatsId().filter(
      (examiner) => usersId?.indexOf(examiner.createdById) + 1
    )
  }
}

import _ from 'lodash'

export default class ExamUtils {
  /**
   * @param {import('./Utils').Utils} parent
   */
  constructor(parent) {
    this.parent = parent
    this.nameOfTotalNumberOfExams = 'numberOfExamsTotal'
    this.pathOfTotalNumberOfExams = [this.parent.indexOfItem, this.nameOfTotalNumberOfExams]
    this.fullPathOfTotalNumberOfExams = [...this.parent.pathOfItem, this.nameOfTotalNumberOfExams]

    this.nameOfNumberOfExamsRequired = 'numberOfExamsRequired'
    this.pathOfNumberOfExamsRequired = [this.parent.indexOfItem, this.nameOfNumberOfExamsRequired]
    this.fullPathOfNumberOfExamsRequired = [
      ...this.parent.pathOfItem,
      this.nameOfNumberOfExamsRequired
    ]

    this.nameOfExams = 'exam'
    this.pathOfExams = [this.parent.indexOfItem, this.nameOfExams]
    this.fullPathOfExams = [...this.parent.pathOfItem, this.nameOfExams]
  }

  getExams = () => {
    return _(this.parent.examinerUtils.filterExaminersByUsersId())
      .chain()
      .reduce((acc, examiner) => {
        const exam = examiner.exam.map((ex) => ({
          ...ex,
          key: ex.id,
          examLevelName: examiner.examLevelName,
          createdByUserName: examiner.FullName
        }))
        return [...acc, ...exam]
      }, [])
      .uniqBy('id')
      .filter((exam) => exam.active)
      .value()
  }

  getTotalNumberOfExams = () => {
    // const { record } = this.context
    // const exam = record?.section?.[this.field.name]?.exam
    // let totalWeightsOfRecord = 0

    // if (exam) {
    //   totalWeightsOfRecord = exam
    //     .filter((ex) => !ex.active)
    //     .reduce((acc, { weight }) => acc + weight, 0)
    // }

    const totalWeight = this.getExams().reduce((acc, exam) => acc + exam.weight, 0)

    // return totalWeightsOfRecord + totalWeight
    return totalWeight
  }

  getNumberOfExamsRequired = () =>
    this.parent.form.getFieldValue(this.fullPathOfNumberOfExamsRequired)

  setTotalNumberOfExams = () =>
    this.parent.form.setFieldValue(this.fullPathOfTotalNumberOfExams, this.getTotalNumberOfExams())

  setNumberOfExamsRequired = (number = 0) =>
    this.parent.form.setFieldValue(this.fullPathOfNumberOfExamsRequired, number)

  resetExamsValue = () => this.parent.form.resetFields([this.fullPathOfExams])
}

import { Col, Form, Radio, Row, Space } from 'antd'
import React from 'react'
import _ from 'lodash'

export default function ExamChoicetype5({ name }) {
  const form = Form.useFormInstance()
  const nameChoice = name && name.slice(2, 4)

  return (
    <Form.Item shouldUpdate>
      {() => {
        const error = form.getFieldError(['examCreator'].concat(name))
        return (
          <Form.List
            name={nameChoice}
            rules={[
              {
                validator: async (field, values) => {
                  if (!(_(values).findIndex((value) => value.isCorrect) + 1))
                    return Promise.reject(new Error('กรุณาเลือกคำตอบด้วยคะ'))
                }
              }
            ]}>
            {(choices, { add, remove }) =>
              choices.map((choice) => (
                <React.Fragment key={choice.key}>
                  <Row gutter={8}>
                    <Col span={24} lg={16}>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 10
                        }}>
                        <Form.Item
                          name={[choice.name, 'isCorrect']}
                          valuePropName="checked"
                          style={{ marginBottom: 0 }}
                          {...(error.length && {
                            help: error[0],
                            validateStatus: 'error'
                          })}>
                          <Radio className="preview-hover" disabled>
                            {form.getFieldValue(['examCreator'].concat(name).concat(choice.name))
                              .choiceName ? (
                              <Form.Item style={{ marginBottom: '0' }}>
                                <div
                                  className="preview-editor-choice-radio"
                                  dangerouslySetInnerHTML={{
                                    __html: `<p>${
                                      form.getFieldValue(
                                        ['examCreator'].concat(name).concat(choice.name)
                                      ).choiceName
                                    }</p>`
                                  }}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                name="choiceName"
                                rules={[{ required: true, message: 'กรอกตัวเลือกด้วยค่ะ' }]}
                                style={{ marginBottom: '0' }}>
                                <p className="preview-editor-choice-radio">
                                  ตัวเลือก {choice.name + 1}
                                </p>
                              </Form.Item>
                            )}
                          </Radio>
                        </Form.Item>
                      </Space>
                    </Col>
                  </Row>
                </React.Fragment>
              ))
            }
          </Form.List>
        )
      }}
    </Form.Item>
  )
}

import { React, useState, useEffect } from 'react'
import { Row, Col, Typography, message, Table as AntdTable, Input, Upload, Button } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import LayoutPage from '../../components/Layout'
import env from '../../lib/environments'
import { getToken } from '../../lib/token'
import MyPDF from '../../file/ImportUser.xlsx'
import * as XLSX from 'xlsx'
import _ from 'lodash'
import { createFileImportColumnsType } from './columnsTypes'
import styled from 'styled-components'
import { useAuth } from '../../AuthorizationProvider'

const { Text } = Typography

export default function UsersImport() {
  const { usePageAuth } = useAuth()
  usePageAuth(['create_users'])
  const [file, setFile] = useState([])
  const [fileName, setFileName] = useState()
  const [data, setData] = useState()

  const handleUpload = () => {
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)

    fetch(env.importusersApi, {
      name: 'file',
      method: 'POST',
      body: formData,
      headers: {
        authorization: `Bearer ${getToken()}`
      }
    })
      .then((res) => res.json())
      .then((rs) => {
        setData(
          _(rs.data.items)
            .filter((item) => item.idCardNumber.length)
            .value()
        )
        setFileName()
        setFile()
      })
      .catch(() => {
        message.error('upload failed.')
      })
  }

  return (
    <LayoutPage>
      <Row gutter={[0, 20]} justify="space-between">
        <Col span={4}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px'
            }}>
            นำเข้าผู้ใช้งาน
          </Text>
        </Col>

        <Col span={18}>
          <Row justify="center">
            <Col style={{ margin: '0px 0px 0px 410px' }}>
              <Text style={{ color: 'red' }}>1. แบบฟอร์มรูปแบบนำเข้าสมาชิก </Text>
            </Col>
            <Col style={{ margin: '0px 0px 0px 5px' }}>
              <a href={MyPDF} download style={{ color: 'green' }}>
                TemplateUsers.xlsx{' '}
              </a>
            </Col>
            <Col>
              <Text style={{ color: 'red', margin: '0px 0px 0px 5px' }}>2. ไฟล์ Excel Import*</Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[10, 0]} justify="end" style={{ marginBottom: 20 }}>
        <Col span={6}>
          <Input
            value={fileName}
            id="search_txt"
            placeholder="สามารถอัพโหลดไฟล์ .xlsx เท่านั้น*"
            disabled
          />
        </Col>

        <Col>
          <Upload
            accept=".xlsx"
            showUploadList={false}
            beforeUpload={async (file) => {
              const workbook = XLSX.read(await file.arrayBuffer())
              const worksheet = workbook.Sheets[workbook.SheetNames[0]]
              const raw_data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

              setData(
                raw_data
                  .filter((d) => d.length)
                  .slice(1)
                  .map((d) =>
                    _.zipObject(
                      [
                        'idCardNumber',
                        'prefix',
                        'fname',
                        'lname',
                        'phoneNumber',
                        'mobile',
                        'email',
                        'rolesName'
                      ],
                      d
                    )
                  )
              )
              setFile(file)
              setFileName(file.name)

              return false
            }}
            {...uploadProps}>
            <Button type="file" className="btnBGreen" shape="round" icon={<CloudUploadOutlined />}>
              เลือกไฟล์
            </Button>
          </Upload>

          <Button
            shape="round"
            style={{ width: '100px', height: '33px', marginLeft: '9px' }}
            className="btnGreen"
            onClick={handleUpload}>
            นำเข้า
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={data}
        rowKey={(d) => d.idCardNumber}
        scroll={{ x: 'max-content' }}
        columns={createFileImportColumnsType()}
      />
    </LayoutPage>
  )
}

const uploadProps = {
  name: 'file',
  action: env.importusersApi,
  headers: {
    authorization: `Bearer ${getToken()}`
  },
  onChange(info) {
    if (info.file.status === 'done') {
      message.success(<>{info.file.name} file uploaded successfully</>)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }
}

const Table = styled(AntdTable)`
  & th {
    text-align: center !important;
  }
`

const contentStyle = {
  backgroundColor: '#F4F4F4',
  height: '100vh',
  alignItems: 'center',
  padding: '50px 50px 0px 50px'
}

import { createBrowserRouter } from 'react-router-dom'
import Login from './pages/LoginPage'
import MainPage from './pages/MainPage'
import portals from './lib/portals'
import ForgotPassPage from './pages/ForgotPassPage'
import ManageUserPage from './pages/ManageUserPage/ManageUserPage'
import UsersImport from './pages/ManageUserPage/UsersImport'
import ManageLevelExam from './pages/ManageLevelExamPage/ManageLevelExam'
import MainExamCategory from './pages/MainExamCategoryPage/MainExamCategoryPage'
import SubExamCategory from './pages/MainExamCategoryPage/SubExamCategoryPage'
import LogPage from './pages/LogPage'
import {
  APPORVE_RECORD,
  APPROVE,
  EXAM_CREATOR,
  EXAM_REPORT,
  MANAGELEVEL1,
  MANAGELEVEL2,
  MANAGELEVEL3,
  QUESTION_STORAGE
} from './constants/paths'
import Level_1 from './pages/ManageLevelPage/Level_1'
import Level_2 from './pages/ManageLevelPage/Level_2'
import Level_3 from './pages/ManageLevelPage/Level_3'
// exam creator
import ExaminationsList from './pages/ExamCreator/List'
import CreateExaminations from './pages/ExamCreator/Create'
import UpdateExaminations from './pages/ExamCreator/Update'
import AddExamination from './pages/ExamCreator/Add'
import ExaminationView from './pages/ExamCreator/View'
import UpdateExamination from './pages/ExamCreator/UpdateOne'
// exam publisher
import ExaminerList from './pages/exam-publisher/List'
import ExamPublisherCreate from './pages/exam-publisher/Create'
import ExamPublisherUpdate from './pages/exam-publisher/Update'
import ExamPublisherView from './pages/exam-publisher/View'
import ExamApprovalList from './pages/exam-approval/List'
import ListHistory from './pages/exam-approval/ListHistory'
import QuestionStorageList from './pages/QuestionStorage/List'
import QuestionStorageView from './pages/QuestionStorage/view'
import ResetPassWordPage from './pages/ResetPasswordPage'
import Permission from './pages/permission/Index'
import ExamApprovalView from './pages/exam-approval/View'
import ExamsManagement from './pages/exam-management/Index'
import ReportPage from './pages/exam-report/Page'

export const router = createBrowserRouter([
  {
    path: portals.base,
    element: <Login />
  },
  {
    path: 'ForgotPassword',
    element: <ForgotPassPage />
  },
  {
    path: 'ResetPassword',
    element: <ResetPassWordPage />
  },
  {
    path: portals.main,
    element: <MainPage />
  },
  // User
  {
    path: 'ManageUserPage',
    element: <ManageUserPage />
  },
  {
    path: 'ManageUserPage/import',
    element: <UsersImport />
  },
  // Permission
  {
    path: 'permission',
    element: <Permission />
  },
  // Level
  {
    path: 'ManageLevelExam',
    element: <ManageLevelExam />
  },
  // Category
  {
    path: 'Exam/MainExamCategory',
    element: <MainExamCategory />
  },
  {
    path: 'Exam/SubExamCategory',
    element: <SubExamCategory />
  },
  // Indent
  {
    path: `${MANAGELEVEL1}/list`,
    element: <Level_1 />
  },
  {
    path: `${MANAGELEVEL2}/list`,
    element: <Level_2 />
  },
  {
    path: `${MANAGELEVEL3}/list`,
    element: <Level_3 />
  },
  // Log
  {
    path: 'LogPage',
    element: <LogPage />
  },
  // Exam creator
  {
    path: `${EXAM_CREATOR}/list`,
    element: <ExaminationsList />
  },
  {
    path: `${EXAM_CREATOR}/create`,
    element: <CreateExaminations />
  },
  {
    path: `${EXAM_CREATOR}/update`,
    element: <UpdateExaminations />
  },
  {
    path: `${EXAM_CREATOR}/updateOne`,
    element: <UpdateExamination />
  },
  {
    path: `${EXAM_CREATOR}/add`,
    element: <AddExamination />
  },
  {
    path: `${EXAM_CREATOR}/view`,
    element: <ExaminationView />
  },
  // Exam Management
  {
    path: portals.examManagement.index,
    element: <ExamsManagement />
  },
  // Exam publisher
  {
    path: portals.examPublisher.list(),
    element: <ExaminerList />
  },
  {
    path: portals.examPublisher.create(),
    element: <ExamPublisherCreate />
  },
  {
    path: portals.examPublisher.update(),
    element: <ExamPublisherUpdate />
  },
  {
    path: portals.examPublisher.view(),
    element: <ExamPublisherView />
  },
  // Exam approval
  {
    path: `${APPROVE}/list`,
    element: <ExamApprovalList />
  },
  {
    path: `${APPROVE}/view`,
    element: <ExamApprovalView />
  },
  {
    path: APPORVE_RECORD,
    element: <ListHistory />
  },
  // Question Storage
  {
    path: `${QUESTION_STORAGE}/list`,
    element: <QuestionStorageList />
  },
  {
    path: `${QUESTION_STORAGE}/view/:id`,
    element: <QuestionStorageView />
  },
  // Report
  {
    path: EXAM_REPORT,
    element: <ReportPage />
  }
])

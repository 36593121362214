import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'
import qs from 'qs'

export const listLevel1 = () => fetchJsonWithToken(env.level1Api)

export const createlistExamLevel1 = (values) =>
  fetchJsonWithToken(env.level1Api, {
    method: 'POST',
    body: JSON.stringify(values)
  })

export const getListLevel1 = () =>
  fetchJsonWithToken(env.level1Api, {
    method: 'GET'
  })

export const deleteLevel1 = (id) =>
  fetchJsonWithToken(`${env.level1Api}/${id}/delete`, {
    method: 'DELETE'
  })

export const updateLevel1 = (id, values) =>
  fetchJsonWithToken(`${env.level1Api}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })
export const getlistLevel1 = (query) =>
  fetchJsonWithToken(`${env.level1Api}?${qs.stringify(query)}`, {
    method: 'GET'
  })

import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const listLevel2 = () => fetchJsonWithToken(env.level2Api)
export const deleteLevel2 = (id) =>
  fetchJsonWithToken(`${env.level2Api}/${id}/delete`, {
    method: 'DELETE'
  })
export const createlistExamLevel2 = (values) =>
  fetchJsonWithToken(env.level2Api, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const updateLevel2 = (id, values) =>
  fetchJsonWithToken(`${env.level2Api}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })

import { Button, Switch, Col, Form, Row, Select, Space, Typography, Card } from 'antd'
import TextEditor from './TextEdit'
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons'
import ExamMatchingChoiceQuestion from './ExamMatchingChoice'
import ExamMatchingChoiceAns from './ExamMatchingChoiceAns'

import React, { useState } from 'react'
const { Text, Title } = Typography

export default function MatchingChoiceExamForm({
  examLevels,
  record,
  examQuan = record?.length || 1,
  updateOne,
  view = false
}) {
  const [quan, setQuan] = useState(examQuan)
  return (
    <>
      <Form.List name="examCreator" className="questions-list">
        {(questions, { add, remove }) => {
          return questions.map((pro) => (
            <Card
              key={pro.key}
              style={{
                height: 'auto',
                width: '95%',
                marginTop: '12px'
              }}>
              <Title underline level={4}>{`โจทย์ข้อที่ ${pro.name + 1}`}</Title>

              <Form.Item
                name={[pro.name, 'active']}
                label="สถานะเปิด/ปิดใช้งาน"
                valuePropName="checked">
                <Switch
                  // onChange={handleChange}
                  checkedChildren="on"
                  unCheckedChildren="off"
                  defaultChecked={true}></Switch>
              </Form.Item>

              <Row justify="space-between">
                <Col span={24} xl={12} style={{ padding: '20px 0px 0px 0px' }}>
                  <Space style={{ marginBottom: 20 }}>
                    <Form.Item
                      name={[pro.name, 'examLevelId']}
                      label="ระดับข้อสอบ"
                      rules={[{ required: true, message: 'เลือกระดับข้อสอบด้วยค่ะ' }]}
                      style={{ marginBottom: 0, minWidth: 300 }}
                      className="inline-form-item">
                      <Select
                        disabled={view}
                        options={examLevels?.map((examl) => ({
                          label: examl.examLevelName,
                          value: examl.id
                        }))}
                      />
                    </Form.Item>
                    {!updateOne && (
                      <Button
                        disabled={view}
                        onClick={() => {
                          add({ examMatch: [{ examChoice: [{ isCorrect: false }] }] })
                          setQuan(quan + 1)
                        }}
                        className="btnBorderG"
                        shape="round"
                        icon={<PlusCircleFilled />}>
                        เพิ่มข้อสอบ
                      </Button>
                    )}
                  </Space>
                </Col>
                <Col>
                  <Space style={{ display: 'flex', justifyContent: 'end' }}>
                    <Text>จำนวนข้อที่สร้าง</Text>
                    <Text style={{ color: '#40AB9A', fontSize: 20 }}>{quan}</Text>
                    <Text>ข้อ</Text>
                  </Space>
                </Col>
              </Row>
              <Space>
                <Form.Item>
                  <Text>คำชี้แจง</Text>
                </Form.Item>
              </Space>
              <Row align="middle" gutter={10}>
                <Col span={24} lg={18}>
                  <TextEditor name={[pro.name, 'detailsExam']} />
                </Col>
                {!!pro.name && (
                  <Col>
                    <Button
                      type="primary"
                      icon={<DeleteFilled />}
                      onClick={() => {
                        remove(pro.name)
                        setQuan(quan - 1)
                      }}
                      className="deleteBtn"
                    />
                  </Col>
                )}
              </Row>
              <Form.Item>
                <ExamMatchingChoiceQuestion record={record} name={[pro.name, 'examMatch']} />
              </Form.Item>
            </Card>
          ))
        }}
      </Form.List>
    </>
  )
}

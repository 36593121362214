import { Button, Col, Form, Radio, Row, Typography } from 'antd'
import useExamSelectors from '../../hooks/selectors/useExamSelectors'
// import useExamSelectors from './useSelectors'
const { Text } = Typography

export default function FiltersHistory({ form, onFinish }) {
  // const { createMainCatSelector, createSubCatSelector, ApporveSelector } = useExamSelectors()
  const { MainExamCategorySelector, SubExamCategorySelector } = useExamSelectors()

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={[0, 24]}>
        <Col span={12}>
          <Text
            style={{
              fontFamily: 'KanitM',
              color: '#0B6252',
              fontSize: '25px'
            }}>
            ประวัติการอนุมัติ
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ padding: '30px 0px 0px 0px' }}>
        <Col span={7}>
          <MainExamCategorySelector noRules />
        </Col>
        <Col span={7}>
          <SubExamCategorySelector noRules />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={11}>
          {/* {ApporveSelector()} */}

          <Form.Item>
            <Form.Item name="approve" label=" ">
              <Radio.Group>
                <Radio value={true}>อนุมัติ</Radio>
                <Radio value={false}>ไม่อนุมัติ</Radio>
              </Radio.Group>
            </Form.Item>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label=" ">
            <Button
              htmlType="sumit"
              style={{ width: '100px', height: '35px' }}
              className="btnGreen"
              shape="round">
              ค้นหา
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

import withHook from '../withHook'
import React, { useState } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { CheckOutlined, DeleteFilled, EditFilled } from '@ant-design/icons'
import qs from 'qs'
import { stripHTMLTags } from '../helpers'
import { Collapse } from 'antd'
import parser from 'html-react-parser'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
dayjs.extend(localizedFormat)
const { Text } = Typography
export const ViewQuestionsColumnsType2 = (searchText, mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    align: 'center',
    width: '5%'
  },
  {
    title: 'โจทย์ข้อสอบ',
    dataIndex: 'exam',
    align: 'center',
    width: '30%',
    render: (exam, record) => (
      <div>
        <Row justify="start">
          <Col>
            <Text>{stripHTMLTags(exam?.detailsExam)}</Text>
          </Col>
        </Row>
      </div>
    )
  }
]

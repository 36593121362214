import { Button, Checkbox, Col, Form, Row, Select, Space, Typography, Switch, Card } from 'antd'
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons'
import ClozeExamChoice from './ClozeExamChoice'
import React, { useState, useEffect } from 'react'
const { Text, Title } = Typography
import ClozeEditor from './ClozeEditor'

export default function ClozePassageExamForm({
  examLevels,
  record,
  examQuan = record?.childExam?.length || 0
}) {
  const [quan, setQuan] = useState(examQuan)
  const [childQuan, setChildQuan] = useState([0])
  const form = Form.useFormInstance()
  const [editorIndex, setEditorIndex] = useState(0)
  const [isRecord, setIsRecord] = useState(false)
  const [isAddCloze, setIsAddCloze] = useState(false)
  const [removeExamIndex, setRemoveExamIndex] = useState(0)
  const [isRemove, setIsRemove] = useState(false)

  useEffect(() => {
    let recordQuan = [0]

    if (record) {
      if (record.length) {
        for (let i = 0; i < record.length; i++) {
          for (let j = 0; j < record[i].childExam.length; j++) {
            recordQuan[i] = recordQuan[i] + 1
          }
          if (recordQuan.length < record.length) {
            recordQuan.push(0)
          }
        }
      } else {
        for (let j = 0; j < record?.childExam?.length || 0; j++) {
          recordQuan[0] = recordQuan[0] + 1
        }
      }
      setIsRecord(true)
      setChildQuan(recordQuan)
    }
  }, [JSON.stringify(record)])

  const handleAddChildQuan = (index) => {
    setChildQuan(
      childQuan.map((el, i) => {
        if (i === index) {
          return el + 1
        } else {
          return el
        }
      })
    )
  }

  const handleRemoveChildQuan = (index) => {
    setChildQuan(
      childQuan.map((el, i) => {
        if (i === index) {
          return el - 1
        } else {
          return el
        }
      })
    )
  }

  return (
    <>
      <Form.List name="examCreator" className="questions-list">
        {(questions) => {
          return questions.map((pro) => (
            <Card
              key={pro.key}
              style={{
                height: 'auto',
                width: '95%',
                marginTop: '12px'
              }}>
              <Form.Item
                name={[pro.name, 'active']}
                label="สถานะเปิด/ปิดใช้งาน"
                valuePropName="checked">
                <Switch checkedChildren="on" unCheckedChildren="off" defaultChecked={true}></Switch>
              </Form.Item>
              <Row justify="space-between" style={{ marginTop: '32px' }}>
                <Col span={24} xl={12}>
                  <Space style={{ marginBottom: 20 }}>
                    <Form.Item
                      name={[pro.name, 'examLevelId']}
                      label="ระดับข้อสอบ"
                      rules={[{ required: true, message: 'เลือกระดับข้อสอบด้วยค่ะ' }]}
                      style={{ marginBottom: 0, minWidth: 300 }}
                      className="inline-form-item">
                      <Select
                        options={examLevels?.map((examl) => ({
                          label: examl.examLevelName,
                          value: examl.id
                        }))}
                      />
                    </Form.Item>
                  </Space>
                </Col>
                <Col>
                  <Space style={{ display: 'flex', justifyContent: 'end' }}>
                    <Text>จำนวนข้อที่สร้าง</Text>
                    <Text style={{ color: '#40AB9A', fontSize: 20 }}>
                      {form.getFieldsValue().examCreator
                        ? form.getFieldsValue().examCreator[pro.name].childExam.length
                        : ''}
                    </Text>
                    <Text>ข้อ</Text>
                  </Space>
                </Col>
              </Row>

              <Space>
                <Form.Item>
                  <Text>โจทย์</Text>
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name={[pro.name, 'notRandomChoice']}
                  initialValue={false}>
                  <Checkbox> ไม่สุ่มคำตอบ</Checkbox>
                </Form.Item>
              </Space>

              <Row align="middle" gutter={10}>
                <Col span={24} lg={18}>
                  <ClozeEditor
                    name={[pro.name, 'detailsExam']}
                    quan={quan}
                    isRemove={isRemove}
                    isRecord={isRecord}
                    isAddCloze={isAddCloze}
                    index={editorIndex}
                    removeExamIndex={removeExamIndex}
                    record={record}
                  />
                </Col>
              </Row>

              <Form.List name={[pro.name, 'childExam']}>
                {(subChildExams, { add, remove }) => {
                  return subChildExams.map((subChildExam) => (
                    <Space key={subChildExam.key}>
                      <Form.Item>
                        <Row align="bottom">
                          <Title level={4}>{`โจทย์ข้อที่ (${subChildExam.name + 1})`}</Title>
                          <Button
                            onClick={() => {
                              childQuan[pro.name] && add({ examChoice: [{ isCorrect: false }] }),
                                setQuan(
                                  form.getFieldsValue().examCreator[pro.name].childExam.length
                                ),
                                handleAddChildQuan(pro.name),
                                setEditorIndex(pro.name),
                                setIsRecord(false),
                                setIsAddCloze(true)
                            }}
                            className="btnBorderG"
                            shape="round"
                            icon={<PlusCircleFilled />}
                            style={{ marginBottom: 10, marginInlineStart: 10 }}>
                            เพิ่มข้อ
                          </Button>
                          {!!subChildExam.name && (
                            <Col style={{ marginBottom: 10, marginLeft: 10 }}>
                              <Button
                                type="primary"
                                icon={<DeleteFilled />}
                                onClick={() => {
                                  remove(subChildExam.name),
                                    setQuan(quan - 1),
                                    setIsRemove(true),
                                    setEditorIndex(pro.name),
                                    handleRemoveChildQuan(pro.name),
                                    setRemoveExamIndex(subChildExam.name + 1)
                                  setIsAddCloze(false)
                                }}
                                className="deleteBtn"
                              />
                            </Col>
                          )}
                        </Row>
                        <Col>
                          <ClozeExamChoice
                            record={record?.childExam}
                            name={[pro.name, 'childExam', subChildExam.name, 'examChoice']}
                          />
                        </Col>
                      </Form.Item>
                    </Space>
                  ))
                }}
              </Form.List>
            </Card>
          ))
        }}
      </Form.List>
    </>
  )
}

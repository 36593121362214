const apiBaseUrl = process.env.REACT_APP_BASE_URL

const authApi = {
  base: `${apiBaseUrl}/auth`,
  login: function () {
    return `${this.base}/login`
  },
  refresh: function () {
    return `${this.base}/refresh`
  }
}

const meApi = `${apiBaseUrl}/users/me`

const examApi = {
  base: `${apiBaseUrl}/exam`,
  activate: function (id) {
    return `${this.base}/${id}/activate`
  },
  deactivate: function (id) {
    return `${this.base}/${id}/deactivate`
  },
  listMonths: function () {
    return `${this.base}/listMonths`
  },
  count: function () {
    return `${this.base}/stat/count`
  },
  reuse: function () {
    return `${this.base}/stat/reuse`
  }
}

const examinerApi = {
  base: `${apiBaseUrl}/exam-examiner`,
  list: function () {
    return `${this.base}/list`
  },
  view: function () {
    return `${this.base}/view`
  },
  preview: function () {
    return `${this.base}/preview`
  },
  approveById: function (id) {
    return `${this.base}/${id}/approve`
  },
  approve: function () {
    return `${this.base}/approve/history`
  },
  approveView: function () {
    return `${this.base}/approve-view`
  },
  storeExamById: function (id) {
    return `${this.base}/${id}/store-exam`
  },
  delete: function (id) {
    return `${this.base}/${id}/delete`
  }
}

const categoryApi = {
  base: `${apiBaseUrl}/main-exam-category`,
  used: function () {
    return `${this.base}/used`
  }
}

const permissionApi = `${apiBaseUrl}/permissions`

const questionsStorageApi = {
  base: `${apiBaseUrl}/store-question`,
  answer: function (id) {
    return `${this.base}/${id}/pdf/answer-key/view/`
  }
}

export {
  apiBaseUrl,
  authApi,
  meApi,
  categoryApi,
  examApi,
  examinerApi,
  permissionApi,
  questionsStorageApi
}

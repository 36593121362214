import env from '../lib/environments'
import { fetchJsonWithToken } from '../lib/fethcer'

export const listExamlevel = () => fetchJsonWithToken(env.examLevelApi)

export const createExamLevel = (values) =>
  fetchJsonWithToken(env.examLevelApi, {
    method: 'POST',
    body: JSON.stringify(values)
  })
export const updateExamLevel = (id, values) =>
  fetchJsonWithToken(`${env.examLevelApi}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  })
export const deleteExamLevel = (id) =>
  fetchJsonWithToken(`${env.examLevelApi}/${id}/delete`, {
    method: 'DELETE'
  })

import React from 'react'
import { Layout } from 'antd'
import HeaderLayout from './Header'
import SiderLayout from './Sider'
import { Content } from 'antd/es/layout/layout'

const LayoutPageMain = ({ children }) => {
  return (
    <div>
      <HeaderLayout />
      <Layout hasSider>
        <SiderLayout />
        <Content style={{ minHeight: '100vh' }}>{children}</Content>
      </Layout>
    </div>
  )
}
export default LayoutPageMain

import withHook from '../withHook'
import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { CheckOutlined, DeleteFilled, EditFilled } from '@ant-design/icons'
import ConfirmDelete from '../../components/ConfirmDelete'
import qs from 'qs'
import { EXAM_CREATOR } from '../../constants/paths'
import { stripHTMLTags } from '../helpers'
import parser from 'html-react-parser'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { deleteExam } from '../../data/exam.data'
dayjs.extend(localizedFormat)

export const createExamViewColumnsType3 = (mutateData) => [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    align: 'center'
  },
  {
    title: 'คำชี้แจง',
    dataIndex: 'detailsExam',
    align: 'center',
    width: '40%',
    render: (question) => <div style={{ textAlign: 'left' }}>{parser(question)}</div>
  },
  {
    title: 'คำถาม',
    dataIndex: 'examMatch',
    align: 'center',
    render: (examMatch) => (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '30px 1fr',
          gap: 8
        }}>
        {examMatch.map((match, index) => (
          <React.Fragment key={index}>
            <div style={{ gridColumnStart: 2, textAlign: 'left' }}>
              {stripHTMLTags(match.questionText)}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  },
  {
    title: 'คำตอบ',
    dataIndex: 'examMatch',
    align: 'center',
    render: (examMatch) => (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '30px 1fr',
          gap: 8
        }}>
        {examMatch.map((match, index) => (
          <React.Fragment key={index}>
            <div style={{ gridColumnStart: 2, textAlign: 'left' }}>
              {stripHTMLTags(match.answerText)}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  },
  {
    title: 'จัดการ',
    dataIndex: 'manage',
    align: 'center',
    render: (_, record) =>
      withHook(() => {
        const [deleteModal, setDeleteModal] = useState(false)
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Row gutter={[10, 0]} justify="center" align="center" style={{ height: '100%' }}>
              <Col>
                <Button
                  className="btnedit"
                  icon={<EditFilled />}
                  href={`${EXAM_CREATOR}/updateOne?${qs.stringify({
                    mainExamCategoryId: record.mainExamCategory.id,
                    subExamCategoryId: record.subExamCategory.id,
                    examType: record.examType,
                    id: record.id
                  })}`}
                />
              </Col>
              <Col>
                <Button
                  className="btndelete"
                  icon={<DeleteFilled />}
                  onClick={() => setDeleteModal(true)}
                />
                <ConfirmDelete
                  open={deleteModal}
                  callback={async () => {
                    await deleteExam(record.id)
                    mutateData()
                  }}
                  fallback={() => {
                    setDeleteModal(false)
                  }}
                />
              </Col>
            </Row>
          </div>
        )
      })
  }
]

import { CheckOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, Col, Row, Space } from 'antd'
import withHook from '../withHook'
import env from '../environments'
import React from 'react'
import { QUESTION_STORAGE } from '../../constants/paths'
import { Link } from 'react-router-dom'
import { questionsStorageApi } from '../api'
export const createQuestionStorageColumnsType = () => [
  {
    title: 'ลำดับ',
    align: 'center',
    dataIndex: 'seq'
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบหลัก',
    dataIndex: 'mainExamCategoryName'
  },

  {
    title: 'ชื่อหมวดหมู่ข้อสอบย่อย',
    dataIndex: 'subExamCategoryName'
  },

  { title: 'ชื่อชุด', dataIndex: ['examExaminer', 'label'] },

  {
    title: 'ดูรายละเอียดชุดข้อสอบ',
    dataIndex: 'id',
    align: 'center',
    render: (id) =>
      withHook(() => {
        return (
          <Space>
            <Button
              className="btnview"
              href={`${QUESTION_STORAGE}/view/${id}`}
              target="_blank"
              icon={<EyeOutlined />}
            />

            <Button
              target="_blank"
              href={questionsStorageApi.answer(id)}
              className="btnview"
              icon={<CheckOutlined />}
            />
          </Space>
        )
      })
  },

  {
    title: 'ไฟล์ชุดข้อสอบ',
    dataIndex: 'id',
    align: 'center',
    render: (id) => (
      <Button
        href={`${env.questionStorageApi}/${id}/pdf`}
        className="btnBorderG"
        shape="round"
        icon={<PrinterOutlined />}>
        พิมพ์
      </Button>
    )
  }
]

import { Col, Form, Row, Select, Typography } from 'antd'
const { Text } = Typography

/**
 * @param {object} props
 * @param {import('./utils/Utils').Utils} props.utils
 * @param {boolean} props.disabled
 */
export default function Examiners({ utils, disabled }) {
  return (
    <Row>
      <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
        <Text>เลือกข้อสอบของผู้จัดทำข้อสอบ : </Text>
        <Form.Item shouldUpdate noStyle>
          {() => (
            <Form.Item
              name={utils.examinerUtils.pathOfUsersId}
              style={{ margin: '20px 0', minWidth: 300 }}
              rules={[{ required: true, message: 'เลือกผู้จัดทำด้วยค่ะ' }]}>
              <Select
                disabled={disabled || !utils.examinerUtils.getUniqUsers().length}
                mode="multiple"
                placeholder="เลือกข้อสอบของผู้จัดทำข้อสอบ"
                options={utils.examinerUtils.getUniqUsers()?.map(
                  (pre) =>
                    ({
                      label: pre.FullName,
                      value: pre.createdById
                    }) || []
                )}
                onChange={() => {
                  utils.form.resetFields([utils.examUtils.fullPathOfNumberOfExamsRequired])
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}
